import React, {useEffect, useState} from 'react';
import {Slider, Typography} from '@material-ui/core';
import {FadingDiv} from '../../FadingComponents';

interface BrightnessSliderProps {
  mouseOn?: boolean;
  divPositionStyle: React.CSSProperties;
  brightness: number;
  onChange: (val: number) => void;
  max?: number;
}

function BrightnessSlider({mouseOn, divPositionStyle, onChange, brightness, max = 4}: BrightnessSliderProps) {
  const [internalBrightness, setInternalBrightness] = useState<number>(brightness);

  useEffect(() => {
    setInternalBrightness(brightness);
  }, [brightness]);

  return (
    <FadingDiv className={(mouseOn === false && 'fade') || undefined} style={divPositionStyle}>
      <Typography id="range-slider" gutterBottom style={{color: '#fff', marginBottom: '0'}}>
        Image Brightness
      </Typography>
      <Slider
        value={internalBrightness * 100}
        min={-max * 100}
        max={max * 100}
        onChange={(_e: any, val: any) => {
          setInternalBrightness(val / 100);
          onChange(val / 100);
        }}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={(val: number) => `${val}`}
        step={10}
        valueLabelFormat={(val: number) =>
          `${(val >= 0 ? val + 100 : ((val + max * 100) / (max * 100)) * 100).toFixed(0)}%`
        }
      />
    </FadingDiv>
  );
}

export default BrightnessSlider;
