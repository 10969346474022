import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';
import {RotationMap} from '@common/api/models/builds/data/ISimilarity';

import Base3DViewport, {initialParams} from './Base3DViewport';
import {usePartPointClouds} from './pointCloudHooks/usePartPointClouds';
import {usePointCloudEffects} from './pointCloudHooks/usePointCloudEffects';
import {initialPointCloudParams} from './pointCloudLoader';
import {PartPreviewViewportSidebar} from './Sidebar/View3DViewportSidebar';
import {initialView3DViewportParams, View3DViewportParams} from './View3DViewport';
import {ViewportMessage, showOverlayMessage} from './ViewportMessage';
import {AnalysisTypeMap} from './types';
import {RootState} from '../../../../store/reducers/index';
import {usePartStoreActions} from '../../../../store/actions';
import PartRotator from './PartRotator';

export interface PartPreview3DViewportProps {
  currentPartUuid?: string;
  partUuids?: string[];
  partRotations?: RotationMap;
  height?: number;
  onRotatePart?: (partUuid: string, rotation: number) => void;
}

const initialPartPreviewParams: View3DViewportParams = {
  ...initialParams,
  ...initialPointCloudParams,
  clippingPlaneEnabled: false,
  availableParts: [],
  selectedParts: [],
  availableAnalysisTypes: {} as AnalysisTypeMap<boolean>,
  selectedAnalysisTypes: {} as AnalysisTypeMap<boolean>,
  rotation: undefined,
};

export default function PartPreview3DViewport({
  currentPartUuid,
  partUuids,
  partRotations,
  height,
  onRotatePart,
}: PartPreview3DViewportProps) {
  const partStoreActions = usePartStoreActions();
  const [activePartUuid, setActivePartUuid] = useState<string>();
  const [params, setParams] = useState<View3DViewportParams>({
    ...initialPartPreviewParams,
    centerAllParts: true,
  });

  const rerenderRef = React.useRef(() => {});
  // Function to force a rerender of the 3D viewport
  const renderScene = rerenderRef.current;

  const {viewportState, pointClouds, sceneBounds} = usePartPointClouds(
    partUuids ?? [],
    initialView3DViewportParams.selectedAnalysisTypes,
    params,
    renderScene
  );

  const allParts = useSelector((state: RootState) =>
    partUuids?.map((uuid) => state.partStore.byId[uuid]).filter((part) => !!part)
  );

  useEffect(() => {
    // Rapid changes of the selected part causes loading issues / race conditions.
    // Instead of debouncing, we only set the comparison if we're not in a loading state. (Check again after loaded)
    // More or less the same effect as debouncing, except without the delay.
    // Only difference being that it may load twice (first and last selected) instead of only last.
    // Used instead of debouncing as the loading time could exceed the debounce delay.
    if (viewportState !== 'loading' && currentPartUuid !== activePartUuid) {
      setActivePartUuid(currentPartUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartUuid, viewportState]);

  useEffect(() => {
    if (activePartUuid) {
      const filter = {uuid: partUuids};
      partStoreActions.ensureConsistent(
        // @ts-ignore
        {...filter, includeFirstLayer: true},
        filter
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePartUuid, JSON.stringify(partUuids)]);

  useEffect(() => {
    const allPartUuids = allParts?.map((part) => part.uuid) || [];
    if (
      allParts &&
      activePartUuid &&
      partUuids?.length &&
      allPartUuids.includes(activePartUuid) &&
      partUuids?.every((uuid) => allPartUuids.includes(uuid))
    ) {
      setParams((params) => ({
        ...params,
        selectedParts: cloneDeep(allParts),
        availableParts: cloneDeep(allParts),
        rotation: partRotations,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePartUuid, JSON.stringify(allParts)]);

  usePointCloudEffects(pointClouds, params, renderScene, viewportState);

  return (
    <Base3DViewport
      sceneGroup={pointClouds}
      sceneBounds={sceneBounds}
      params={params}
      sidebar={
        <PartPreviewViewportSidebar
          params={params}
          sceneBounds={sceneBounds}
          setParams={setParams}
          resetParams={() =>
            setParams({
              ...initialPartPreviewParams,
              availableParts: params.availableParts,
              selectedParts: params.selectedParts,
              availableAnalysisTypes: params.availableAnalysisTypes,
            })
          }
          viewportState={viewportState}
        />
      }
      sidebarInitOpen={false}
      overlayMessage={
        <ViewportMessage
          viewportState={viewportState}
          noSelectionTitle="No part selected"
          noSelectionMessage="Select a part in the tree to preview."
        />
      }
      showOverlayMessage={showOverlayMessage(viewportState)}
      showHelpers={['viewing', 'loading'].includes(viewportState)}
      rerenderRef={rerenderRef}
      height={height}
      rightButtons={
        !!onRotatePart && viewportState === 'viewing' ? (
          <PartRotator
            rotation={(params.rotation ?? {})[activePartUuid!]}
            setRotation={(rotationValue) => {
              setParams({
                ...params,
                rotation: {
                  [activePartUuid!]: rotationValue,
                },
              });
              onRotatePart(activePartUuid!, rotationValue);
            }}
          />
        ) : undefined
      }
    />
  );
}
