import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Grid, AppBar, Tabs as MuiTabs, Tab} from '@material-ui/core';
import {RootState} from '../../store/reducers/index';
import {LocationBasedDefectsStatus} from '@common/api/models/builds/data/defects/ILocationBasedDefectsReport';
import {useLocationBasedDefectsReportActions} from '../../store/actions/index';
import HeatmapViewport from './HeatmapViewport';
import {colourMapOptionsWithRaw} from '../../components/molecules/Viewport/2D/controls/CmSelector';

const Tabs = styled(MuiTabs)`
  background-color: ${(props) => props.theme.palette.background.paper};
  color: #000000;
`;

export enum LocationBasedDefectTypes {
  OBJECT = 'object',
  SEVERE = 'severe',
  HOTCOLD = 'hotcold',
  SPATTER = 'spatter',
}

const LOCATION_BASED_METADATA_MAPPINGS = {
  [LocationBasedDefectTypes.OBJECT]: 'layerMask',
  [LocationBasedDefectTypes.SEVERE]: 'lsdd',
  [LocationBasedDefectTypes.HOTCOLD]: 'hotcold',
  [LocationBasedDefectTypes.SPATTER]: 'spatter',
};

const LOCATION_BASED_DEFECT_TYPE_LABELS = {
  [LocationBasedDefectTypes.OBJECT]: 'Object Density',
  [LocationBasedDefectTypes.SEVERE]: 'Severe Defect',
  [LocationBasedDefectTypes.HOTCOLD]: 'Hot/Cold Spots',
  [LocationBasedDefectTypes.SPATTER]: 'Serious/Moderate Spatter',
};

function ViewportTabs() {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const images = useSelector((state: RootState) => state.locationBasedDefectsReportStore.images[selectedUuid]);
  const metadata = useSelector((state: RootState) => state.locationBasedDefectsReportStore.metadata[selectedUuid]);
  const report = useSelector((state: RootState) => state.locationBasedDefectsReportStore.byId[selectedUuid]);
  const [currentColourMap, setColourMap] = useState(colourMapOptionsWithRaw[1]);
  const [crosshairsEnabled, setCrosshairsEnabled] = useState(false);
  const [hoveredPosition, setHoveredPosition] = useState<{x?: number; y?: number; z?: number} | null>(null);
  const [activeHeatmap, setActiveHeatmap] = useState<LocationBasedDefectTypes>(LocationBasedDefectTypes.OBJECT);
  const locationBasedDefectsReportActions = useLocationBasedDefectsReportActions();

  useEffect(() => {
    if (!images && report.status === LocationBasedDefectsStatus.Success) {
      locationBasedDefectsReportActions.fetchImages(selectedUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUuid]);

  if (!images) return <></>;

  const availableHeatmaps = Object.values(LocationBasedDefectTypes).filter((type) =>
    images.find((img) => img.filename!.includes(type))
  );

  const normalization = activeHeatmap === LocationBasedDefectTypes.OBJECT ? 'non-normalised' : 'normalised';

  const heatmapZ = images.find((img) => img.filename!.includes(`z_heatmap_${normalization}_${activeHeatmap}.png`));
  const heatmapX = images.find((img) => img.filename!.includes(`x_heatmap_${normalization}_${activeHeatmap}.png`));
  const heatmapY = images.find((img) => img.filename!.includes(`y_heatmap_${normalization}_${activeHeatmap}.png`));

  const commonHeatmapProps = {
    currentColourMap,
    setColourMap,
    crosshairsEnabled,
    setCrosshairsEnabled,
    setHoveredPosition,
    locationBasedDefectType: activeHeatmap,
  };

  function getMaxValue(axis: 'x' | 'y' | 'z') {
    return metadata?.[LOCATION_BASED_METADATA_MAPPINGS[activeHeatmap]]?.[axis]?.[
      activeHeatmap === LocationBasedDefectTypes.OBJECT ? 'non-normalised' : 'normalised'
    ];
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{marginBottom: '12px'}}>
        <AppBar position="static" color="secondary">
          <Tabs
            value={availableHeatmaps.findIndex((type) => type === activeHeatmap)}
            onChange={(_event, index) => setActiveHeatmap(availableHeatmaps[index])}
            variant="scrollable"
            indicatorColor="primary"
            aria-label="full width tabs example"
            scrollButtons="auto"
          >
            {availableHeatmaps.map((type) => {
              return <Tab key={`lbd-tab-${type}`} id={`tab-${type}`} label={LOCATION_BASED_DEFECT_TYPE_LABELS[type]} />;
            })}
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12}>
        <HeatmapViewport
          signedUrl={heatmapZ?.signedUrl}
          height={550}
          axis="z"
          hoveredPosition={{x: hoveredPosition?.x, y: hoveredPosition?.y}}
          maxValue={getMaxValue('z')}
          {...commonHeatmapProps}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <HeatmapViewport
          signedUrl={heatmapY?.signedUrl}
          height={400}
          axis="y"
          hoveredPosition={{x: hoveredPosition?.x, y: hoveredPosition?.z}}
          maxValue={getMaxValue('y')}
          {...commonHeatmapProps}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <HeatmapViewport
          signedUrl={heatmapX?.signedUrl}
          height={400}
          axis="x"
          hoveredPosition={{x: hoveredPosition?.y, y: hoveredPosition?.z}}
          maxValue={getMaxValue('x')}
          {...commonHeatmapProps}
        />
      </Grid>
    </Grid>
  );
}

export default ViewportTabs;
