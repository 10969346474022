import React, {useState} from 'react';
import {Stage} from 'react-konva';
import {AnalysisType2D} from '@common/api/models/builds/data/defects/IDefect';
import SingleImageViewport, {HoveredMmAxisTransforms} from './SingleImageViewport';
import {onPositionChange} from './utils';

export interface SelfManagedSingleImageViewportProps {
  image: HTMLImageElement | undefined;
  imageWidthPixels: number;
  imageHeightPixels: number;
  mmPerPixel: number;
  height: number;
  showHoveredMmPosition?: boolean;
  hoveredMmAxisTransforms?: HoveredMmAxisTransforms;
  hoveredPosition?: {x?: number; y?: number} | null;
  setHoveredPosition?: (hoveredPosition: {x?: number; y?: number; z?: number} | null) => void;
  dataPointValue?: string;
  isLocationBasedDefectsViewport?: boolean;
  brightness?: number;
}

export const SelfManagedSingleImageViewport: React.FunctionComponent<SelfManagedSingleImageViewportProps> = (props) => {
  // @ts-ignore
  const [stage] = useState<Stage | null>(null);

  return (
    <SingleImageViewport
      overlays={[
        {
          image: props.image,
          id: AnalysisType2D.Layer,
          filters: [],
        },
      ]}
      imageWidthPixels={props.imageWidthPixels}
      imageHeightPixels={props.imageHeightPixels}
      mmPerPixel={props.mmPerPixel}
      onPositionChange={onPositionChange(stage)}
      height={props.height}
      showHoveredMmPosition={props.showHoveredMmPosition}
      hoveredMmAxisTransforms={props.hoveredMmAxisTransforms}
      hoveredPosition={props.hoveredPosition}
      setHoveredPosition={props.setHoveredPosition}
      dataPointValue={props.dataPointValue}
      isLocationBasedDefectsViewport={props.isLocationBasedDefectsViewport}
      brightness={props.brightness}
      fitToScreenOnStart
    >
      {props.children}
    </SingleImageViewport>
  );
};
