import React, {useState} from 'react';
import {CloudUpload, Info} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import {
  IMAGE_FILE_EXTENSIONS,
  SLICE_FILE_EXTENSIONS,
  ScanStrategy,
} from '@common/api/models/attachments/ISliceAttachment';
import {grayColor} from '../../../assets/jss/material-dashboard-react';
import {Button, CircularProgress, MenuItem, TextField, Tooltip, Typography} from '@material-ui/core';
import {useMultiPartUpload} from '../../../utils/contexts/MultiPartUploadContext';
import {MultiPartUploadResourceType} from '../../../utils/contexts/IMultiPartUploadContext';

type MultipartUploadProps = {
  buildUuid: string;
  orgUuid: string;
  setNumFilesUploading: React.Dispatch<React.SetStateAction<number>>;
  numFilesUploading?: number;
  acceptImage?: boolean;
  postUploadCallback?: () => void;
  isBuildPhoto?: boolean;
};

export type uploadParamType = {
  signedUrls: string[];
  filename: string;
  key: string;
  uploadId: string;
};

function SliceAttachmentMultipartUpload({
  buildUuid,
  orgUuid,
  setNumFilesUploading,
  numFilesUploading,
  acceptImage = false,
  isBuildPhoto = false,
  postUploadCallback,
}: MultipartUploadProps) {
  const [scanStrategy, setScanStrategy] = useState<ScanStrategy>(ScanStrategy.BORDER);

  const {onDrop} = useMultiPartUpload();

  function dropZoneOnDrop(droppedFiles: File[]) {
    onDrop(droppedFiles, MultiPartUploadResourceType.sliceAttachment, {
      resourceUuid: buildUuid,
      orgUuid: orgUuid,
      setNumFilesUploading,
      scanStrategy,
      isBuildPhoto,
      postUploadCallback,
    });
  }

  const sliceFileExtensionText = SLICE_FILE_EXTENSIONS.map((item) => `.${item}`).reduce(
    (outputString, item) => `${outputString}, ${item}`
  );

  const imageFileExtensionText = IMAGE_FILE_EXTENSIONS.map((item) => `.${item}`).reduce(
    (outputString, item) => `${outputString}, ${item}`
  );

  return (
    <Dropzone onDrop={dropZoneOnDrop} noClick accept={acceptImage ? imageFileExtensionText : undefined}>
      {({getRootProps, getInputProps, open, isDragActive}) => (
        <CustomDiv isDragActive={isDragActive}>
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <Row>
                <CloudUpload color="primary" style={{fontSize: 48}} />
              </Row>
              <Row>
                <Typography variant="h4">Drag & drop to upload</Typography>
              </Row>
              <Row>
                <Typography variant="subtitle1">or</Typography>
              </Row>
              <Row>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={open}
                  size="large"
                  fullWidth={false}
                  endIcon={numFilesUploading ? <CircularProgress size={20} /> : undefined}
                >
                  {acceptImage ? 'Browse photos...' : 'Browse files...'}
                </Button>
              </Row>

              {!acceptImage && (
                <Row style={{marginTop: '1rem', display: 'flex', alignItems: 'center'}}>
                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    label="Scan Strategy"
                    value={scanStrategy}
                    onChange={(event) => setScanStrategy(event.target.value as ScanStrategy)}
                    SelectProps={{
                      labelId: 'scan-strategy-label',
                      id: 'scan-strategy-select',
                    }}
                    style={{textAlign: 'start', width: '140px', marginLeft: '36px', marginRight: '12px'}}
                  >
                    <MenuItem value={ScanStrategy.BORDER}>Border</MenuItem>
                    <MenuItem value={ScanStrategy.FILL}>Fill</MenuItem>
                  </TextField>
                  <Tooltip
                    title={
                      scanStrategy === ScanStrategy.BORDER
                        ? 'Reads border contours from the slice file - where the laser passes around the outer skin of the part. This is the recommended strategy for most parts.'
                        : 'Reads the hatch vectors from the slice file - where the laser passes over the internal fill of the part. Use for parts without defined border contours or if the default strategy failed.'
                    }
                  >
                    <Info color="action" />
                  </Tooltip>
                </Row>
              )}

              {!acceptImage && scanStrategy === ScanStrategy.FILL && (
                <Row style={{marginTop: '1rem', color: 'orange', fontSize: '14px'}}>
                  <Alert severity="warning">Fill scan strategy is experimental, results may not be optimal.</Alert>
                </Row>
              )}
              <Row>
                <Typography style={{fontStyle: 'italic', marginTop: '16px'}}>
                  {acceptImage
                    ? `Accepted Files Include Image Files ( ${imageFileExtensionText} )`
                    : `Accepted files include Slice Files (${sliceFileExtensionText}), Scene Data, and General Documents (.txt)`}
                </Typography>
              </Row>
            </div>
          </section>
        </CustomDiv>
      )}
    </Dropzone>
  );
}

export default SliceAttachmentMultipartUpload;

const CustomDiv = styled.div<{isDragActive: boolean}>`
  border: dashed 2px ${({isDragActive}) => (isDragActive ? grayColor[6] : grayColor[4])};
  ${({isDragActive}) => (isDragActive ? `background-color: ${grayColor[5]};` : '')};
  transition: all 0.3s;
  border-radius: 5px;
  text-align: center;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 64px;

    @media (max-width: 900px) {
      padding: 12px;
    }
  }

  .container {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 0px;
`;
