import {ISliceAttachment} from './ISliceAttachment';

export type MaterialAttachmentSortOptions = 'filename' | 'filesize' | 'createdAt';

export enum BuildAttachmentType {
  BuildAttachment = 'BuildAttachment',
  BuildPhoto = 'BuildPhoto',
  BuildPhotoMain = 'BuildPhotoMain',
}

export interface IAttachmentBase {
  uuid: string;
  url: string;
  filename: string;
  filesize: number;
  createdAt: Date;
  deletedAt?: Date;
}

export interface IBuildAttachment extends IAttachmentBase {
  buildUuid: string;
  organizationUuid: string;
  type: BuildAttachmentType;
}

export interface IMaterialAttachment extends IAttachmentBase {
  materialUuid: string;
  organizationUuid: string;
}

export interface IBatchAttachment extends IAttachmentBase {
  batchUuid: string;
  organizationUuid: string;
}

export type IAttachmentConcrete = IBuildAttachment | ISliceAttachment | IMaterialAttachment | IBatchAttachment;
