import React, {useEffect} from 'react';
import {Button, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableRow} from '@material-ui/core';

import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {useForm} from 'react-hook-form';

import {IDevice} from '@common/api/models/devices/IDevice';
import {IMachine} from '@common/api/models/devices/machines/IMachine';

import {RootState} from '../../store/reducers';
import {useDeviceStoreActions} from '../../store/actions';
import {deviceCreatePOST} from '../../api/ajax/devices';
import CustomInput from '../../components/atoms/CustomInput';
import CustomButton from '../../components/atoms/CustomButton';
import {MachineSelectorButton} from '../../components/molecules/Selector/MachineSelectorButton';
import RequiredAsterisk from '../../components/atoms/Texts/RequiredAsterisk';
import Header from '../../components/organisms/Header';

export default function CreateDevicePage() {
  const {
    register,
    errors,
    clearErrors,
    setError,
    handleSubmit: onSubmit,
    setValue,
    watch,
  } = useForm<IDevice>({
    mode: 'all',
  });
  const machineStore = useSelector((state: RootState) => state.machineStore);

  const deviceActions = useDeviceStoreActions();
  const deviceStore = useSelector((state: RootState) => state.deviceStore);

  useEffect(() => {
    register({name: 'machineUuid'}, {required: 'Please choose a machine'});
    deviceActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Display error if user forgets to choose a machine
    toast(errors.machineUuid?.message);
  }, [errors.machineUuid]);

  const history = useHistory();

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = async (data: IDevice) => {
    const res = await deviceCreatePOST(data);
    if (res.success) {
      history.replace('/machines/uuid/' + res.data.machineUuid + '/');
    }
  };

  const handleMachineSelected = (machine: IMachine | null) => {
    if (machine) {
      const device = deviceStore.byMachineUuid[machine.uuid];
      if (device) {
        setError('machineUuid', {message: 'Machine already has a device'});
        return;
      }
    }
    clearErrors('machineUuid');
    setValue('machineUuid', machine?.uuid);
  };

  const selectedMachineId = machineStore.byId[watch('machineUuid')!] || null;

  return (
    <React.Fragment>
      <Header
        helmet="Create Device"
        title="Create Device"
        breadcrumbs={[{title: 'Devices', path: '/machines/devices'}, 'Create Device (Superuser only)']}
      />

      <Grid container spacing={6} justifyContent={'center'}>
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardHeader title={'Device'}></CardHeader>
            <CardContent>
              <form id="createDeviceForm">
                <Table style={{borderBottom: 'none'}} size={'small'}>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" variant="head">
                        Device ID <RequiredAsterisk />
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          helperText={errors.deviceId?.message}
                          error={!!errors.deviceId}
                          inputProps={{
                            name: 'deviceId',
                            inputRef: register({
                              required: 'Please input device ID',
                            }),
                            'aria-required': 'true',
                            autoComplete: 'off',
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" variant="head">
                        Serial <RequiredAsterisk />
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          helperText={errors.serial?.message}
                          error={!!errors.serial}
                          inputProps={{
                            name: 'serial',
                            inputRef: register({
                              required: 'Please input serial',
                            }),
                            'aria-required': 'true',
                            autoComplete: 'off',
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" variant="head">
                        Model <RequiredAsterisk />
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          helperText={errors.model?.message}
                          error={!!errors.model}
                          inputProps={{
                            name: 'model',
                            inputRef: register({
                              required: 'Please input model',
                            }),
                            'aria-required': 'true',
                            autoComplete: 'off',
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" variant="head">
                        Machine <RequiredAsterisk />
                      </TableCell>
                      <TableCell>
                        <MachineSelectorButton
                          machine={selectedMachineId}
                          onMachineSelected={handleMachineSelected}
                          error={!!errors.machineUuid}
                          machineFilter={(machine) => !deviceStore.byMachineUuid[machine.uuid]}
                          excludeColumns={['availability']}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardContent
              style={{
                paddingBottom: '16px',
              }}
            >
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button variant={'outlined'} onClick={handleCancel}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <CustomButton
                    variant={'contained'}
                    type="submit"
                    form="createDeviceForm"
                    color={'primary'}
                    onClick={onSubmit(handleSubmit)}
                  >
                    Create Device
                  </CustomButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
