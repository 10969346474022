import {useEffect, useReducer, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {DoubleSide, Mesh, MeshPhongMaterial, PlaneGeometry, TextureLoader} from 'three';
import {LayerImages, LazyImageWithSize} from '../../../../../pages/builds/liveBuild/activeBuildPages/ViewportsPage';
import {RootState} from '../../../../../store/reducers';
import {LayerImageState, PartPointClouds} from '../types';
import {BoundingBox} from '../Base3DViewport';
import {removeBlacksWithMask} from '../../2D/utils';
import {LayerImageResolutionSize} from '@common/api/models/builds/data/ILayerImage';

const transparentMaterial = new MeshPhongMaterial({
  color: 0x80c7e4,
  shininess: 100,
  opacity: 0,
  transparent: true,
  reflectivity: 1,
});

/**
 * Creates a material for a layer image with the given image url.
 *
 * @param image - The HTMLImageElement for the layer image.
 * @returns The material for the layer image.
 */
async function getLayerImageMaterial(
  layerNum: number,
  image: HTMLImageElement,
  mplmImage?: HTMLImageElement
): Promise<MeshPhongMaterial> {
  let imageDataUrl: string;
  if (mplmImage) {
    imageDataUrl = removeBlacksWithMask(image, mplmImage);
  } else {
    imageDataUrl = image.src;
  }

  return new Promise((resolve, reject) => {
    new TextureLoader().load(
      imageDataUrl,
      (layerImageTexture) => {
        resolve(
          new MeshPhongMaterial({
            name: `Layer-${layerNum}`,
            map: layerImageTexture,
            side: DoubleSide,
            transparent: true,
            reflectivity: 0,
            emissiveMap: layerImageTexture,
            emissive: 'white',
            emissiveIntensity: 0,
            shininess: 0,
            // depthWrite: false,
          })
        );
      },
      undefined,
      (err) => reject(err)
    );
  });
}

/**
 * Custom hook for managing layer image plane in a 3D viewport.
 *
 * @param layerNum - The layer number.
 * @param showLayerImage - Flag indicating whether to show the layer image.
 * @param pointClouds - The part point clouds.
 * @param renderScene - Function to render the scene.
 * @param loadedLayers - The loaded layer images.
 * @param fetchLayerData - Function to fetch layer data.
 * @param sceneBounds - The bounding box of the scene.
 * @returns The loading state of the layer image.
 */
export const useLayerImagePlane = (
  layerNum: number,
  showLayerImage: boolean,
  clipLayerImagePlane: boolean,
  layerImageOpacity: number,
  pointClouds: PartPointClouds,
  renderScene: () => void,
  numLayers: number,
  minLayerNum: number,
  calibrationUuid?: string,
  loadedLayers?: LayerImages,
  fetchLayerData?: (layerNum: number) => void,
  sceneBounds?: BoundingBox | null
) => {
  const topPlaneRef = useRef<Mesh | undefined>(undefined);
  const bottomPlaneRef = useRef<Mesh | undefined>(undefined);
  const [loadingState, setLoadingState] = useState<LayerImageState>('viewing');
  const [forcedUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const currentCalibration = useSelector((state: RootState) => state.calibrationStore.byId[calibrationUuid!]);

  function getTransparentLayerImage() {
    if (!loadedLayers?.[layerNum]?.birdsEye?.transparentLayerImage?.images) return;

    return loadedLayers[layerNum].birdsEye.transparentLayerImage.images.find(
      (image) => image.resolutionSize === LayerImageResolutionSize.HALF_RES
    );
  }

  function getLayerImage() {
    if (!loadedLayers?.[layerNum]?.birdsEye?.layer?.images) return null;

    return loadedLayers[layerNum].birdsEye.layer.images.find(
      (image) => image.resolutionSize === LayerImageResolutionSize.HALF_RES
    );
  }

  function getModelPredictedLayerMask() {
    if (!loadedLayers?.[layerNum]?.birdsEye?.modelPredictedLayerMask?.images) return null;

    return loadedLayers?.[layerNum]?.birdsEye?.modelPredictedLayerMask?.images[0];
  }

  async function loadLayerImageMaterial(
    topPlane: Mesh,
    bottomPlane: Mesh,
    lazyImage: LazyImageWithSize,
    modelPredictedLayerMask?: LazyImageWithSize,
    loaded = false
  ) {
    setLoadingState('loading');

    if (lazyImage) {
      const image = await lazyImage.image.image;
      const {plateBoundingBox, scale} = currentCalibration;

      function loadImages() {
        image.addEventListener('load', async () => {
          if (modelPredictedLayerMask) {
            const mplmImage = await modelPredictedLayerMask.image.image;
            mplmImage.addEventListener('load', () => {
              loadLayerImageMaterial(topPlane, bottomPlane, lazyImage, modelPredictedLayerMask, true);
            });
          } else {
            // Fully transparent image
            loadLayerImageMaterial(topPlane, bottomPlane, lazyImage, undefined, true);
          }
        });
      }

      // Image has completely loaded, so we can set the material.
      if (loaded || image.complete) {
        let layerImageMaterial: MeshPhongMaterial;

        if (modelPredictedLayerMask) {
          const mplmImage = await modelPredictedLayerMask.image.image;
          if (!mplmImage.complete) {
            loadImages();
            return;
          }

          layerImageMaterial = await getLayerImageMaterial(layerNum, image, mplmImage);
        } else {
          layerImageMaterial = await getLayerImageMaterial(layerNum, image, undefined);
        }

        topPlane.material = layerImageMaterial!;
        bottomPlane.material = layerImageMaterial!;
      }
      // Either the first load or layer has changed, trigger loading the image and then call again with loaded = true.
      else if (!topPlane.material || (topPlane.material as MeshPhongMaterial).name !== `Layer-${layerNum}`) {
        if (image) {
          loadImages();
        }
        return;
      }

      [topPlane, bottomPlane].forEach((plane) => {
        plane.material = plane.material as MeshPhongMaterial;
        plane.material.opacity = layerImageOpacity;
        plane.visible = true;

        // The image is initially loaded in the center of the 3D viewport.
        // The 3D viewports grid is based off the build plate.
        // The center of the image != the center of the build plate, there is a bit of an offset.
        // We need to calculate the difference between the center of the image and the center of
        // the build plate adjust the position of the image correctly.
        const plateOrigin = [
          plateBoundingBox[0] + (plateBoundingBox[2] - plateBoundingBox[0]) / 2,
          plateBoundingBox[1] + (plateBoundingBox[3] - plateBoundingBox[1]) / 2,
        ];

        const trueWidthPx = lazyImage?.image.widthPx! * 2;
        const trueHeightPx = lazyImage?.image.heightPx! * 2;

        const imageOrigin = [trueWidthPx / 2, trueHeightPx / 2];

        // I know we just times by 2 and dividing by two, but we're using the half size image so it feels right
        const diffX = (imageOrigin[0] - plateOrigin[0]) * scale;
        const diffY = (imageOrigin[1] - plateOrigin[1]) * scale;

        plane.position.x = diffX;
        plane.position.z = diffY;
        plane.position.y = sceneBounds!.min.y + sceneBounds!.dimensions.y * ((layerNum - minLayerNum) / numLayers);
        plane.material.needsUpdate = true;
      });
      setLoadingState('viewing');
      renderScene();
    }
  }

  useEffect(() => {
    fetchLayerData && fetchLayerData(layerNum);
    renderScene();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerNum, fetchLayerData, showLayerImage, renderScene]);

  useEffect(() => {
    const lazyTransparentLayerImage = getTransparentLayerImage();
    const lazyImage = getLayerImage();
    const lazyModelPredictedLayerMask = getModelPredictedLayerMask();

    const hasSuitableImage = lazyTransparentLayerImage || (lazyImage && lazyModelPredictedLayerMask);

    if (showLayerImage && pointClouds.numParts && hasSuitableImage) {
      if (topPlaneRef.current && bottomPlaneRef.current) {
        if (lazyTransparentLayerImage) {
          loadLayerImageMaterial(topPlaneRef.current, bottomPlaneRef.current, lazyTransparentLayerImage);
        } else if (lazyImage && lazyModelPredictedLayerMask) {
          loadLayerImageMaterial(topPlaneRef.current, bottomPlaneRef.current, lazyImage, lazyModelPredictedLayerMask);
        }
      } else {
        const {scale, shape} = currentCalibration;
        [topPlaneRef, bottomPlaneRef].forEach((planeRef, index) => {
          const planeGeometry = new PlaneGeometry(shape[1] * scale, shape[0] * scale);
          const newPlane = new Mesh(planeGeometry, transparentMaterial);
          newPlane.name = 'layerImagePlane';
          newPlane.rotation.x = Math.PI / 2;
          newPlane.rotation.y = -Math.PI;
          newPlane.rotation.z = index === 1 ? -Math.PI : Math.PI;
          newPlane.renderOrder = 3;

          pointClouds.group.add(newPlane);

          planeRef.current = newPlane;
        });
        // We've set the plane geometry for the first time, now time to add the layer image.
        forceUpdate();
      }
    } else if (topPlaneRef.current && bottomPlaneRef.current) {
      // Show layer image is false, so hide the layer image plane and set it to a transparent material.
      [topPlaneRef, bottomPlaneRef].forEach((planeRef) => {
        planeRef.current!.material = transparentMaterial;
        planeRef.current!.visible = false;
        planeRef.current!.material.needsUpdate = true;
      });
    }

    renderScene();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showLayerImage,
    layerNum,
    clipLayerImagePlane,
    layerImageOpacity,
    forcedUpdate,
    pointClouds.numParts,
    renderScene,
    loadedLayers,
    pointClouds.group,
  ]);

  return loadingState;
};
