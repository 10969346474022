import React from 'react';
import {Typography, FormControlLabel, FormGroup, Checkbox, Grid, Link, Tooltip, IconButton} from '@material-ui/core';

import {AnalysisType3D, getPrettyAnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';

import {ViewportSidebarProps} from './View3DViewportSidebar';
import {View3DViewportParams} from '../View3DViewport';
import {Search} from '@material-ui/icons';

export default function PointCloudPicker(props: ViewportSidebarProps<View3DViewportParams>) {
  const availableAnalysisTypes = Object.values(AnalysisType3D).filter(
    (type) => props.params.availableAnalysisTypes[type]
  );
  const selectedPartUuids = props.params.selectedParts.map((part) => part.uuid);

  // Sub-sort by uuid to handle conflicting names and preserve correct sorting
  const comparePartNames = (a: IPartGETResponse, b: IPartGETResponse) =>
    `${a.name}-${a.uuid}` > `${b.name}-${b.uuid}` ? 1 : -1;

  return (
    <>
      <Grid item style={{width: 'inherit'}}>
        <Typography>Part selection</Typography>
        <div
          style={{
            maxHeight: 400,
            overflowX: 'scroll',
            padding: 10,
            margin: '10px 0 10px 0',
            border: '2px solid gray',
            whiteSpace: 'nowrap',
          }}
        >
          <div style={{paddingBottom: 5}}>
            {props.params.selectedParts.length}/{props.params.availableParts.length} parts selected.{' '}
            <Link href="#" onClick={() => props.setParams({...props.params, selectedParts: []})}>
              Deselect all
            </Link>
          </div>
          {props.params.availableParts.length > 0 ? (
            <FormGroup>
              {props.params.availableParts.sort(comparePartNames).map((part) => (
                <Grid
                  key={`${part.name}-${part.uuid}`}
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{
                    flexWrap: 'nowrap',
                    borderRadius: 5,
                    backgroundColor: props.params.hoveredPartUuid === part.uuid ? '#222' : undefined,
                  }}
                  onMouseEnter={() => {
                    // Only hover selected parts
                    if (!selectedPartUuids.includes(part.uuid)) return;
                    props.setParams({
                      ...props.params,
                      hoveredPartUuid: part.uuid,
                    });
                  }}
                  onMouseLeave={() => {
                    // Make sure we don't remove the hover if another part is already hovered
                    if (props.params.hoveredPartUuid !== part.uuid) return;
                    props.setParams({
                      ...props.params,
                      hoveredPartUuid: undefined,
                    });
                  }}
                >
                  <Grid item>
                    <FormControlLabel
                      value={part.uuid}
                      control={
                        <Checkbox
                          checked={selectedPartUuids.includes(part.uuid)}
                          onChange={(_, checked) =>
                            props.setParams({
                              ...props.params,
                              selectedParts: checked
                                ? [...props.params.selectedParts, part]
                                : props.params.selectedParts.filter((p) => p.uuid !== part.uuid),
                            })
                          }
                          color="primary"
                          size="small"
                          style={{height: '100%'}}
                        />
                      }
                      label={part.name}
                      style={{
                        height: '1.5em',
                      }}
                      disabled={props.viewportState === 'loading'}
                    />
                  </Grid>
                  {props.params.selectedParts.includes(part) && (
                    <Grid item style={{height: '18px', paddingRight: 5}}>
                      <Tooltip title="Centre camera on this part">
                        <IconButton size="small" onClick={() => props.centreCameraOnPart?.(part.uuid)}>
                          <Search fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              ))}
            </FormGroup>
          ) : (
            'No parts available.'
          )}
        </div>
      </Grid>

      <Grid item style={{width: 'inherit'}}>
        <Typography>Analysis type selection</Typography>
        <div
          style={{
            maxHeight: 400,
            overflowX: 'scroll',
            padding: 10,
            margin: '10px 0 10px 0',
            border: '2px solid gray',
            whiteSpace: 'nowrap',
          }}
        >
          <FormGroup>
            {availableAnalysisTypes.length > 0 ? (
              availableAnalysisTypes.map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox
                      checked={props.params.selectedAnalysisTypes[type]}
                      onChange={(_, checked) =>
                        props.setParams({
                          ...props.params,
                          selectedAnalysisTypes: {
                            ...props.params.selectedAnalysisTypes,
                            [type]: checked,
                          },
                          modelOpacity: Object.entries(props.params.selectedAnalysisTypes).filter(
                            ([key, selected]) => key !== 'model' && !!selected
                          )?.length
                            ? props.params.modelOpacity
                            : 0.1,
                        })
                      }
                      color="primary"
                      size="small"
                      style={{height: '100%'}}
                    />
                  }
                  label={getPrettyAnalysisType3D(type)}
                  style={{height: '1.5em'}}
                  disabled={props.viewportState === 'loading'}
                />
              ))
            ) : props.params.selectedParts.length > 0 ? (
              'No analysis types available'
            ) : (
              // Disabled mock checkbox for before user selects part
              <FormControlLabel
                control={<Checkbox checked disabled color="primary" size="small" />}
                label="Model"
                style={{height: '1.5em'}}
              />
            )}
          </FormGroup>
        </div>
      </Grid>
    </>
  );
}
