import React, {useEffect, useState} from 'react';
import {get, omit} from 'lodash';
import {humanize} from 'inflection';
import {ListItemText, Typography, Divider as MuiDivider, Grid, styled} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import {ConfigDefaultsType, PROFILE_IGNORE_SECTIONS, SensorProfileType} from '../../../../store/model/sensorProfile';
import {DefaultCameraMode} from '../../../builds/buildSettings/BuildProfileConfig';
import {CameraSelect} from './CameraSelect';
import CameraModeSelect from './CameraModeSelect';
import ProfileField from './ProfileField';

const Divider = styled(MuiDivider)(spacing);

export const DIFFERENT_VALUES_TEXT = 'Selected cameras have different values';

const SECONDARY_HEADINGS = {
  calibration: 'Modify calibration mode settings',
  camera_mode: 'Select camera mode to edit settings for',
  camera_manager: 'Select camera(s) to edit settings for',
  layer_generator: 'Modify active build monitoring settings',
  output_filetypes: 'Set which files are saved to the local device during image capture',
} as any;

function getFields(section: string, cameraMode: string, configDefaults: ConfigDefaultsType) {
  switch (section) {
    case 'camera_manager':
      return [
        ...Object.entries(configDefaults.camera_manager.cameras[cameraMode || ''] || {}),
        ...(cameraMode === 'params_preview' ? Object.entries(omit(configDefaults.camera_manager, 'cameras')) : []),
      ];
    case 'output_filetypes':
      return Object.entries(configDefaults.output_filetypes.local[cameraMode || ''] || {});
    case 'calibration':
      return Object.entries(omit(configDefaults[section] || {}, 'pattern'));
    default:
      return Object.entries(configDefaults[section] || {});
  }
}

function getFieldPaths(section: string, field: string, cameraMode: string, cameraIndexes: number[]) {
  switch (section) {
    case 'camera_manager':
      if (field === 'preview_render_setting') return [`${section}[${field}]`];
      return cameraIndexes.map((cameraIndex) => `camera_manager.cameras[${cameraIndex}][${cameraMode}][${field}]`);
    case 'output_filetypes':
      return [`output_filetypes.local[${cameraMode}][${field}]`];
    default:
      return [`${section}[${field}]`];
  }
}

function ProfileForm({
  configDefaults,
  profileJSON,
  updateProfile,
  setErrors,
  errors,
  disableSections = [],
  readOnly = false,
  defaultCameraMode,
  uuid,
}: {
  configDefaults: ConfigDefaultsType;
  profileJSON: SensorProfileType;
  updateProfile: (paths: string[], value: any, updatingProfile?: SensorProfileType) => void;
  setErrors: (errors: {[key: string]: boolean}) => void;
  errors: {[key: string]: boolean};
  disableSections?: string[];
  readOnly?: boolean;
  defaultCameraMode?: DefaultCameraMode;
  uuid: string;
}) {
  const cameraCount = configDefaults.camera_manager.cameras.count;
  const availableCameraIds = configDefaults.camera_manager.cameras.available_camera_ids;

  const availableCameras = Array(cameraCount)
    .fill(null)
    .map((_, index) => (availableCameraIds ? availableCameraIds[index] : index + 1));

  const availableCameraModes = Object.keys(configDefaults.camera_manager.cameras).filter(
    (key) => !['available_camera_ids', 'count'].includes(key)
  );

  const [selectedCameras, setSelectedCameras] = useState<number[]>(availableCameras);
  const [cameraMode, setCameraMode] = useState<string>(defaultCameraMode || 'params_monitor');

  configDefaults.camera_mode = cameraMode;

  const sections = Object.keys(configDefaults).filter((section) => !PROFILE_IGNORE_SECTIONS.includes(section));

  // Putting 'camera_mode' right before 'camera_manager' section
  if (sections.indexOf('camera_mode') !== -1 && sections.indexOf('camera_manager') !== -1) {
    const oldIndex = sections.indexOf('camera_mode');
    const newIndex = sections.indexOf('camera_manager');
    sections.splice(newIndex, 0, sections.splice(oldIndex, 1)[0]);
  }

  // Putting 'output_filetypes' right after 'camera_manager' section
  if (sections.indexOf('output_filetypes') !== -1 && sections.indexOf('camera_manager') !== -1) {
    const oldIndex = sections.indexOf('output_filetypes');
    const newIndex = sections.indexOf('camera_manager') + 1;
    sections.splice(newIndex, 0, sections.splice(oldIndex, 1)[0]);
  }

  const sectionsToPad = ['camera_manager', 'output_filetypes'];

  useEffect(() => {
    setSelectedCameras(availableCameras);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  // In converting YAML to JSON and back, the camera's array can lose its sorting.
  // Here we find the correct array index for the selected cameras
  const correctCameraIndexes = selectedCameras.map((cameraNum) => {
    return profileJSON.camera_manager.cameras.findIndex((camera: SensorProfileType) => camera.id === cameraNum);
  });

  function calculateFrameRateFn(exposureTime?: number | null) {
    const overallMax = configDefaults.camera_manager.cameras[cameraMode].frame_rate.max;
    const overallMin = configDefaults.camera_manager.cameras[cameraMode].frame_rate.min;

    const selectedExposureTimes = exposureTime
      ? [exposureTime]
      : correctCameraIndexes.map((camIndex) =>
          Number(get(profileJSON, `camera_manager.cameras[${camIndex}][${cameraMode}].exposure_time`))
        );
    const minExposureTime = Math.min(...selectedExposureTimes);

    const frameRateMax = Math.max(Math.min(overallMax, 0.99 / minExposureTime), overallMin);

    return {
      calculatedValue: Math.floor(frameRateMax * 1e5) / 1e5,
      overallMin: overallMin,
      overallMax: overallMax,
    };
  }

  return (
    <>
      {sections.map((section, index) => {
        const disabled = readOnly || disableSections.includes(section);
        const shouldPadSection = sectionsToPad.includes(section);
        return (
          <React.Fragment key={section}>
            <Grid item style={{marginBottom: '12px', ...(shouldPadSection ? {paddingLeft: '3rem'} : {})}}>
              <ListItemText
                primary={
                  <Typography
                    variant={shouldPadSection ? 'subtitle2' : 'h6'}
                    style={shouldPadSection ? {fontWeight: 'bold'} : {}}
                  >
                    {humanize(section)}
                  </Typography>
                }
                secondary={SECONDARY_HEADINGS[section]}
                secondaryTypographyProps={shouldPadSection ? {style: {fontSize: '13px'}} : {}}
              />
            </Grid>
            {section === 'camera_mode' && (
              <Grid container spacing={4} style={{marginBottom: '12px'}}>
                <Grid item xs={12} sm={6} md={4}>
                  <CameraModeSelect
                    value={cameraMode}
                    onChange={setCameraMode}
                    availableModes={availableCameraModes}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            )}
            {section === 'camera_manager' && (
              <Grid container spacing={4} style={{paddingLeft: '3rem', marginBottom: '12px'}}>
                <Grid item xs={12} sm={6} md={4}>
                  <CameraSelect
                    availableCameras={availableCameras}
                    selectedCameras={selectedCameras}
                    setSelectedCameras={setSelectedCameras}
                    disabled={Object.keys(errors).some(
                      (key) => !!errors[key] && key.includes('camera_manager.cameras')
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              direction={section === 'layer_generator' ? 'column' : 'row'}
              spacing={4}
              style={shouldPadSection ? {paddingLeft: '3rem'} : {}}
            >
              {getFields(section, cameraMode, configDefaults).map(([field, config]) => (
                <ProfileField
                  key={field}
                  fieldName={field}
                  config={config as {}}
                  readOnly={disabled}
                  fieldPaths={getFieldPaths(section, field, cameraMode, correctCameraIndexes)}
                  profileJSON={profileJSON}
                  updateProfile={updateProfile}
                  calculateFrameRateFn={calculateFrameRateFn}
                  setErrors={setErrors}
                  errors={errors}
                  selectedCameras={selectedCameras}
                />
              ))}
            </Grid>
            {section === 'camera_manager' && selectedCameras.length === 0 && (
              <Grid container spacing={4} style={{paddingLeft: '3rem', marginTop: '1rem'}}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2">Please select Cameras...</Typography>
                </Grid>
              </Grid>
            )}

            {index + 1 !== sections.length && section !== 'camera_mode' ? (
              <Grid item style={section === 'camera_manager' ? {paddingLeft: '3rem'} : {}}>
                <Divider my={4} />
              </Grid>
            ) : (
              <Grid item style={{paddingLeft: '3rem'}}>
                <Divider my={1} style={{backgroundColor: 'transparent'}} />
              </Grid>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default ProfileForm;
