import React, {useEffect, useState} from 'react';
import {Typography, Box} from '@material-ui/core';
import styled from 'styled-components';
import {DeviceDetailedState, DeviceState, isCalibrating} from '@common/api/models/devices/IDevice';

// Images all taken from https://undraw.co/illustrations
// With colour #FF6600
import calibrationFinishedPNG from '../../../../../assets/img/calibration-finished.png';
import calibrationCalculatingPNG from '../../../../../assets/img/calibration-calculating.png';
import calibrationCapturingPNG from '../../../../../assets/img/calibration-capturing.png';
import calibrationStartingPNG from '../../../../../assets/img/calibration-starting.png';
import calibrationDefaultPNG from '../../../../../assets/img/calibration-default.png';
import calibrationCompletePNG from '../../../../../assets/img/calibration-complete.png';
import calibrationCancellingPNG from '../../../../../assets/img/calibration-cancelling.png';
import {useSmallScreenSize} from '../../../../../utils/utilHooks';
import LinearProgressWithLabel from '../../../../../components/atoms/LinearProgressWithLabel';
import {Alert} from '@material-ui/lab';

export const DeviceCalibrationInfo = ({
  deviceStateDetailed,
  deviceState,
  hasCalibrated,
  waitingForCalibration,
  waitingForCalibrationToStop,
}: {
  deviceStateDetailed: DeviceDetailedState;
  deviceState: DeviceState;
  hasCalibrated: boolean;
  waitingForCalibration: boolean;
  waitingForCalibrationToStop: boolean;
}) => {
  const isSmallScreen = useSmallScreenSize();

  const isCurrentlyCalibrating = isCalibrating(deviceStateDetailed) || deviceState === DeviceState.CALIBRATING;

  return (
    <DeviceCalibrationInfoContainer isSmallScreen={isSmallScreen}>
      <CalibrationImage
        deviceStateDetailed={deviceStateDetailed}
        isCurrentlyCalibrating={isCurrentlyCalibrating}
        hasCalibrated={hasCalibrated}
        waitingForCalibration={waitingForCalibration}
        waitingForCalibrationToStop={waitingForCalibrationToStop}
      />
      <Typography variant={isSmallScreen ? 'h4' : 'h2'} align="center">
        {headingText(
          deviceStateDetailed,
          isCurrentlyCalibrating,
          hasCalibrated,
          waitingForCalibration,
          waitingForCalibrationToStop
        )}
      </Typography>
      <Typography variant={isSmallScreen ? 'body1' : 'subtitle1'} align="center" style={{width: '75%'}}>
        {paragraphText(
          deviceStateDetailed,
          isCurrentlyCalibrating,
          hasCalibrated,
          waitingForCalibration,
          waitingForCalibrationToStop
        )}
      </Typography>
      <CalibrationHelperText deviceStateDetailed={deviceStateDetailed} />
      {!waitingForCalibrationToStop && isCalibrating(deviceStateDetailed) && (
        <Box width={'75%'} marginTop={isSmallScreen ? 2 : 5}>
          <LinearProgressWithLabel value={fakePercentageProgress(deviceStateDetailed)} style={{borderRadius: '4px'}} />
        </Box>
      )}
    </DeviceCalibrationInfoContainer>
  );
};

const headingText = (
  deviceStateDetailed: DeviceDetailedState,
  isCurrentlyCalibrating: boolean,
  hasCalibrated: boolean,
  waitingForCalibration: boolean,
  waitingForCalibrationToStop: boolean
) => {
  if (waitingForCalibrationToStop) return 'Stopping calibration';

  switch (deviceStateDetailed) {
    case DeviceDetailedState.CalibratingStarting:
      return 'Getting Ready...';
    case DeviceDetailedState.CalibratingCapturing:
      return 'Capturing images for calibration.';
    case DeviceDetailedState.CalibratingCalculating:
      return 'Calculating...';
    case DeviceDetailedState.CalibratingStopping:
      return 'Stopping calibration...';
    default: {
      if (isCurrentlyCalibrating || waitingForCalibration) return 'Getting ready...';
      if (hasCalibrated) return 'Device calibration complete';
      return 'Start device calibration';
    }
  }
};

const paragraphText = (
  deviceStateDetailed: DeviceDetailedState,
  isCurrentlyCalibrating: boolean,
  hasCalibrated: boolean,
  waitingForCalibration: boolean,
  waitingForCalibrationToStop: boolean
) => {
  if (waitingForCalibrationToStop) return 'Please wait a moment while we stop the current calibration.';

  switch (deviceStateDetailed) {
    case DeviceDetailedState.CalibratingStarting:
      return 'Please wait a moment while we get the sensor ready for calibrating.';
    case DeviceDetailedState.CalibratingCapturing:
      return 'The sensor is ready to calibrate. Please expose the calibration pattern on the machine now.';
    case DeviceDetailedState.CalibratingCalculating:
      return 'We are computing the new calibration profile.';
    case DeviceDetailedState.CalibratingStopping:
      return 'Please wait a moment while the calibration operation stops.';
    default: {
      if (isCurrentlyCalibrating || waitingForCalibration)
        return 'Please wait a moment while we get the sensor ready for calibrating.';

      if (hasCalibrated) return 'You can now head back to the overview tab to review your calibration';

      return 'This process may take up to 5 mins to complete.';
    }
  }
};

// These are just fake value as we do not know exactly how long each step takes, but give a more or less accurate representation
const fakePercentageProgress = (deviceStateDetailed: DeviceDetailedState) => {
  switch (deviceStateDetailed) {
    case DeviceDetailedState.CalibratingStarting:
      return 10;
    case DeviceDetailedState.CalibratingCapturing:
      return 30;
    case DeviceDetailedState.CalibratingCalculating:
      return 60;
    case DeviceDetailedState.CalibratingStopping:
      return 100;
    default:
      return 0;
  }
};

const CalibrationImage = ({
  deviceStateDetailed,
  isCurrentlyCalibrating,
  hasCalibrated,
  waitingForCalibration,
  waitingForCalibrationToStop,
}: {
  deviceStateDetailed: DeviceDetailedState;
  isCurrentlyCalibrating: boolean;
  hasCalibrated: boolean;
  waitingForCalibration: boolean;
  waitingForCalibrationToStop: boolean;
}) => {
  if (waitingForCalibrationToStop) return <img src={calibrationCancellingPNG} alt="man placing an X on the board" />;

  switch (deviceStateDetailed) {
    case DeviceDetailedState.CalibratingStarting:
      return <img src={calibrationStartingPNG} alt="woman pressing a big play button" />;
    case DeviceDetailedState.CalibratingCapturing:
      return <img src={calibrationCapturingPNG} alt="man looking though many documents" />;
    case DeviceDetailedState.CalibratingCalculating:
      return <img src={calibrationCalculatingPNG} alt="man looking at calculations on a device" />;
    case DeviceDetailedState.CalibratingStopping:
      return <img src={calibrationFinishedPNG} alt="woman looking at a large tick" />;
    default: {
      if (isCurrentlyCalibrating || waitingForCalibration)
        return <img src={calibrationStartingPNG} alt="man looking though many documents" />;
      if (hasCalibrated) return <img src={calibrationCompletePNG} alt="man looking at large phone with tick" />;
      return <img src={calibrationDefaultPNG} alt="man on phone with a cosmic background" />;
    }
  }
};

const DeviceCalibrationInfoContainer = styled.div<{isSmallScreen: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({isSmallScreen}) => (isSmallScreen ? '12px 0px' : '64px 0px')};
  h2,
  h4 {
    padding: ${({isSmallScreen}) => (isSmallScreen ? '12px 0px 12px 0px' : '32px 0px 12px 0px')};
  }
  img {
    height: 25vh;
    max-height: 250px;
  }
`;

const CalibrationHelperText = ({deviceStateDetailed}: {deviceStateDetailed: DeviceDetailedState}) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [timeStarted, setTimeStarted] = useState<null | number>();
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout> = setTimeout(() => {
      setCurrentTextIndex((currentTextIndex + 1) % 3);
    }, 15000);

    return () => clearInterval(interval);
  }, [currentTextIndex]);

  useEffect(() => {
    let timeout: null | ReturnType<typeof setTimeout> = null;
    if (timeStarted) {
      timeout = setTimeout(() => {
        setShouldShow(true);
      }, 60000);
    }

    return () => clearTimeout(timeout!);
  }, [timeStarted]);

  if (deviceStateDetailed !== DeviceDetailedState.CalibratingCapturing) {
    if (timeStarted !== null) {
      setTimeStarted(null);
    }
    return <></>;
  }

  if (!timeStarted) setTimeStarted(Date.now());
  if (!shouldShow) return <></>;

  return (
    <Wrapper>
      <Alert severity="info" style={{marginBottom: '12px', width: '100%'}}>
        <SpacedChangingText
          text="Please ensure the machine is started and exposing the calibration pattern for a single layer."
          isActive={currentTextIndex === 0}
        />

        <SpacedChangingText
          text="Is the chamber light on? If the chamber light is on, results may vary or the calibration process may wait indefinitely."
          isActive={currentTextIndex === 1}
        />

        <SpacedChangingText
          text="Is the gain too high? If the gain is too high, the calibration process may wait indefinitely."
          isActive={currentTextIndex === 2}
        />
      </Alert>
    </Wrapper>
  );
};

const SpacedChangingText = ({text, isActive}: {text: string; isActive: boolean}) => {
  return (
    <>
      {isActive ? <Typography className="inactive">{text}</Typography> : <></>}
      <Typography className={isActive ? 'active floating' : 'inactive floating'}>{text}</Typography>
    </>
  );
};

const Wrapper = styled.div`
  width: 75%;
  margin: 12px 0px;
  .MuiAlert-message {
    position: relative;
    width: 100%;
    margin: 8px 0px;
    padding: 0px;
  }

  .floating {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: opacity 2s ease-in-out;
  }
  .active {
    opacity: 1;
  }
  .inactive {
    opacity: 0;
  }
`;
