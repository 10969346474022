import React from 'react';
import {Button, Typography} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import VerticalButton from '../../../components/atoms/VerticalButton';
import {RootState} from '../../../store/reducers/index';

function NewProfileButton() {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const newProfileExists = useSelector((state: RootState) => !!state.sensorProfileStore.byId['new']);

  function handleAddProfile() {
    history.replace('/machines/sensorProfiles/new');
  }

  if (isSmallScreen) {
    return (
      <VerticalButton onClick={() => handleAddProfile()} color="secondary" disabled={newProfileExists}>
        <Add />
        <Typography variant="caption">New Profile</Typography>
      </VerticalButton>
    );
  }

  return (
    <Button variant="contained" color="primary" onClick={handleAddProfile} disabled={newProfileExists}>
      <Add />
      New Profile
    </Button>
  );
}

export default NewProfileButton;
