import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Modal as MuiModal,
  ModalProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import styled from 'styled-components';

import {toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RootState} from '../../../store/reducers';
import {useBatchStoreActions, useMaterialStoreActions} from '../../../store/actions';
import {BatchEventType, IBatch} from '@common/api/models/materials/batches/IBatch';
import {BatchSelectorButton} from '../Selector/BatchSelectorButton';
import {batchEventPOST} from '../../../api/ajax/batches';
import {IMaterial} from '@common/api/models/materials/IMaterial';
import {MaterialSupplyWidget} from '../Dashboard/MaterialSupplyWidget';
import {MaterialSelectorButton} from '../Selector/MaterialSelectorButton';
import CustomButton from '../../atoms/CustomButton';

export interface BatchAddModalProps extends Partial<ModalProps> {
  open: boolean;
  initialBatchUuid?: string;
  initialMaterialUuid?: string;
}

const ModalCard = styled(Card)`
  position: absolute;
`;

const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
`;

function BatchAddMaterialModal(props: BatchAddModalProps) {
  const batchStoreActions = useBatchStoreActions();
  const materialStoreActions = useMaterialStoreActions();
  const {initialMaterialUuid, initialBatchUuid, ...rest} = props;

  const batchStore = useSelector((state: RootState) => {
    return state.batchStore;
  });
  const materialStore = useSelector((state: RootState) => {
    return state.materialStore;
  });

  const history = useHistory();

  const [selectedBatchUuid, setSelectedBatchUuid] = useState<string | undefined>(initialBatchUuid);
  const [batch, setBatch] = useState<IBatch | undefined>(undefined);
  const [selectedMaterialUuid, setSelectedMaterialUuid] = useState<string | undefined>(initialMaterialUuid);
  const [material, setMaterial] = useState<IMaterial | undefined>(undefined);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (selectedMaterialUuid) {
      materialStoreActions.ensureConsistent({uuid: selectedMaterialUuid});
    }
    if (selectedBatchUuid) {
      batchStoreActions.ensureConsistent({uuid: selectedBatchUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBatchUuid, selectedMaterialUuid]);

  useEffect(() => {
    setSelectedBatchUuid(initialBatchUuid);
    setSelectedMaterialUuid(initialMaterialUuid);
  }, [initialBatchUuid, initialMaterialUuid]);

  useEffect(() => {
    const batch = selectedBatchUuid ? batchStore.byId[selectedBatchUuid] : undefined;
    const material = selectedMaterialUuid ? materialStore.byId[selectedMaterialUuid] : undefined;
    if (batch) {
      setBatch(batch);
    }
    if (material) {
      setMaterial(material);
    }
  }, [batchStore.byId, batchStore.fetched, material, materialStore.byId, selectedBatchUuid, selectedMaterialUuid]);

  const onSubmit = async () => {
    if (!selectedBatchUuid || !selectedMaterialUuid) {
      toast('Must choose a batch and material', {type: 'warning'});
      return;
    }

    const res = await batchEventPOST(selectedBatchUuid, {
      type: BatchEventType.ADD,
      quantity,
      materialUuid: selectedMaterialUuid,
    });
    props.onClose && props.onClose({}, 'escapeKeyDown');
    if (res.success) {
      history.push('/materials/batches/uuid/' + res.data.uuid);
    }
  };

  const isAddValid = () => {
    if (!material || !batch) {
      return false;
    }
    if (!selectedBatchUuid || !selectedMaterialUuid) {
      return false;
    }
    if (material.quantityRemaining < quantity) {
      return false;
    }
    if (quantity <= 0) {
      return false;
    }

    return true;
  };

  return (
    <Modal {...rest}>
      <ModalCard style={{maxWidth: 700}}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item sm={12} md={12} lg={12}>
              <h2>Add material to batch.</h2>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <Grid container justifyContent={'space-between'}>
                <Grid item xs={6}>
                  <BatchSelectorButton
                    placeholder={'Choose a batch to add to...'}
                    onSelect={(b) => {
                      b && setSelectedBatchUuid(b.uuid);
                    }}
                    selectedBatch={batchStore.byId[selectedBatchUuid!]}
                  />
                </Grid>

                <Grid item xs={6}>
                  <MaterialSelectorButton
                    placeholder={'Choose a material to add from...'}
                    onSelect={(m) => {
                      m && setSelectedMaterialUuid(m.uuid);
                    }}
                    selectedMaterial={materialStore.byId[selectedMaterialUuid!]}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {material && (
                    <MaterialSupplyWidget
                      raised={false}
                      elevation={0}
                      material={{
                        ...material,
                        quantityRemaining: material.quantityRemaining - quantity,
                      }}
                    />
                  )}
                </Grid>
                {material ? (
                  <Grid item xs={12}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <b>Quantity to Add</b>
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              defaultValue={quantity}
                              fullWidth
                              onChange={(e) => {
                                const newVal = Number(e.target.value);
                                if (newVal <= material.quantityRemaining) {
                                  setQuantity(newVal);
                                } else {
                                  toast('Value exceeds remaining material quantity: ' + material.quantityRemaining, {
                                    type: 'error',
                                    toastId: 'exceeds remaining material',
                                  });
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>kg</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                ) : (
                  <i>Please choose a material</i>
                )}
              </Grid>
            </Grid>

            <Grid item sm={12} md={12} lg={12}>
              <Grid container justifyContent={'space-between'}>
                <Grid item></Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button color="primary" onClick={() => props.onClose && props.onClose({}, 'escapeKeyDown')}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <CustomButton
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        disabled={!isAddValid()}
                        text="Confirm and Add"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </ModalCard>
    </Modal>
  );
}

export default BatchAddMaterialModal;
//export default withTheme(BatchSplitModal) as (props: BatchMergeModalProps) => any;
