import {useEffect, useMemo, useReducer, useState} from 'react';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {AnalysisTypeMap, PartPointClouds, PointCloudData, ThreePoints, View3DState, sphereGeometry} from '../types';
import {View3DViewportParams} from '../View3DViewport';
import {PointCloud2} from '../pointCloudV2';
import {
  downloadPointCloud,
  loadPointCloudAsMesh,
  loadPointCloudAsPoints,
  pointCloudBoundsFromPart,
} from '../pointCloudLoader';
import {objMapValues} from '../../../../../utils/objectFunctions';
import {usePointCloudStoreActions} from '../../../../../../src/store/actions';
import {FetchingState} from '../../../../../store/model/liveUpdateStore';
import {RootState} from '../../../../../store/reducers';
import {SOURCE_PART_COLOR, TARGET_PART_COLOR} from '../AnalysisTypeHelper';
import {BoundingBox} from '../Base3DViewport';
import {rotatePoints} from '../viewportFunctions';
import {Box3, Color, Vector3} from 'three';
import {PointCloud3} from '../pointCloudV3';

const getPointCloudDimensions = (points: ThreePoints) => {
  const center = new Vector3();
  const size = new Vector3();

  // Box3().setFromPoints() takes an additional parameter `precise` in an updated version of three.js
  // We need this, but our version doesn't have it, so we mimic the behaviour here.
  // Without it, centering with rotation doesn't always work.
  // https://github.com/mrdoob/three.js/blob/309b00afb6dcbc5e6c58e72f10eaa8d2e8888c83/src/math/Box3.js#L202L229
  const bbox = new Box3();

  points.updateWorldMatrix(false, false);
  const vector = new Vector3();
  const position = points.geometry.attributes.position;

  for (let i = 0, l = position.count; i < l; i++) {
    vector.fromBufferAttribute(position, i).applyMatrix4(points.matrixWorld);
    bbox.expandByPoint(vector);
  }

  bbox.getCenter(center);
  bbox.getSize(size);

  return {size, center};
};

const centerPointCloud = (points: ThreePoints, isSimilarity: boolean = false) => {
  points.geometry.center();

  const {size, center} = getPointCloudDimensions(points);

  if (isSimilarity) {
    points.position.sub(center);
    points.position.y = points.position.y + 0.1 + size.y / 2;
    points.position.x = points.position.x + size.x / 2;
    points.position.z = points.position.z - size.z / 2;
  } else {
    points.position.sub(center);
    // Put on base of plate instead of through the plate
    points.position.y = points.position.y + size.y / 2;
  }
};

/**
 * Custom hook that Adds and Hides Pointclouds based on the input arguments
 *
 * @param partUuids - An array of part UUIDs.
 * @param analysisTypes - A map of analysis types and their availability.
 * @param params - Viewport parameters.
 * @param renderScene - A function to render the scene.
 * @returns An object containing the viewport state, point clouds, point cloud data, scene bounds, and available analysis types.
 */
export function usePartPointClouds(
  partUuids: string[],
  analysisTypes: AnalysisTypeMap<boolean>,
  params: View3DViewportParams,
  renderScene: () => void
) {
  const [viewportState, setViewportState] = useState<View3DState>('noselection');

  const [pointCloudData] = useState(() => new PointCloudData());
  const [forcedReload, forceReload] = useReducer((x) => x + 1, 0);

  const [pointClouds] = useState(() => new PartPointClouds());

  const [availableAnalysisTypes, setAvailableAnalysisTypes] = useState<AnalysisTypeMap<boolean>>(
    objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>
  );

  useEffect(() => {
    if (partUuids.length === 0) {
      setViewportState('noselection');
      return;
    }
  }, [partUuids.length]);

  const loadPointCloudWrapper = async (
    pointCloud: PointCloud2 | PointCloud3,
    partUuid: string,
    analysisType: AnalysisType3D
  ) => {
    pointCloudData.incDownloadedPartitions(partUuid);

    const result = params.use3DPoints
      ? loadPointCloudAsMesh(pointCloud, analysisType, params, sphereGeometry)
      : loadPointCloudAsPoints(pointCloud, analysisType, params);

    if (!result.success) {
      toast(result.error, {type: 'error'});
      return;
    }
    const bounds: BoundingBox =
      pointCloudBoundsFromPart(params.selectedParts.find((part) => part.uuid === partUuid)!) || result.bounds;

    if (!bounds.layerBounds) {
      bounds.layerBounds = result.bounds.layerBounds;
    }

    if (params.centerAllParts) {
      const points = result.object as ThreePoints;

      if (params.rotation && partUuid in params.rotation) {
        rotatePoints(points, params.rotation[partUuid]);
      }
      centerPointCloud(points, params.isSimilarity);
      const {size} = getPointCloudDimensions(points);
      bounds.min = {x: 0 - size.x / 2, y: 0, z: 0 + size.z / 2};

      if (partUuids.length === 2) {
        if (partUuid === partUuids[0]) {
          points.material.color = new Color(SOURCE_PART_COLOR);
        } else {
          points.material.color = new Color(TARGET_PART_COLOR);
        }
      }

      pointClouds.addPointCloud(partUuid, analysisType, points);
      pointCloudData.setPartBounds(partUuid, bounds);
    } else {
      pointClouds.addPointCloud(partUuid, analysisType, result.object);
      pointCloudData.setPartBounds(partUuid, bounds);
    }
  };

  const downloadPointCloudWrapper = (pointCloudUuid: any, partUuid: string, analysisType: AnalysisType3D) =>
    downloadPointCloud(pointCloudUuid, partUuid, analysisType, loadPointCloudWrapper, () => {
      toast('Could not download 3D data', {type: 'error'});
      setViewportState('failed');
    });

  const pointCloudDataString = useMemo(
    () => JSON.stringify(pointCloudData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forcedReload, pointCloudData]
  );
  const partUuidsString = JSON.stringify(partUuids);

  useEffect(() => {
    // This useEffect is called when the selected parts or analysis types change.

    // Set visibility for previously loaded parts
    pointClouds.setVisibleParts(partUuids);
    renderScene();

    // Selected parts may have changed, so available analysis types may need updating
    setAvailableAnalysisTypes(pointCloudData.availableAnalysisTypes(partUuids));

    // If no parts are selected, there's nothing to load
    if (partUuids.length === 0) {
      return;
    }

    // If we get to this point, parts are selected,
    // so we should never be in the 'noselection' state
    if (viewportState === 'noselection') {
      setViewportState('viewing');
    }

    // Load analysis point clouds
    const promises = [] as Promise<void>[];

    partUuids.forEach((partUuid) => {
      Object.values(AnalysisType3D)
        .filter(
          // Select point clouds that are not Model, have been selected by the user,
          // aren't already loaded and that are available to load
          (analysisType) =>
            analysisType !== AnalysisType3D.Model &&
            analysisTypes[analysisType] &&
            pointClouds.getPointClouds(partUuid, analysisType).length === 0 &&
            pointCloudData.getPointClouds(partUuid, analysisType).length > 0
        )
        .forEach((analysisType) => {
          // For the time being, we only load the first partition.
          promises.push(
            downloadPointCloudWrapper(
              pointCloudData.getPointClouds(partUuid, analysisType)[0].uuid,
              partUuid,
              analysisType
            )
          );
        });

      // Load model point clouds
      // Only load enough point cloud partitions to to reach params.pointLimit across all parts (worst case)
      let pointCount = 0;
      for (
        let partitionIndex = 0;
        partitionIndex < pointCloudData.getPointClouds(partUuid, AnalysisType3D.Model).length &&
        pointCount < params.pointLimit / partUuids.length;
        partitionIndex++
      ) {
        const pointCloud = pointCloudData.getPointClouds(partUuid, AnalysisType3D.Model)[partitionIndex];
        pointCount += pointCloud.numPoints;

        if (pointCloudData.getPartitionsDownloaded(partUuid) > partitionIndex) continue;

        promises.push(downloadPointCloudWrapper(pointCloud.uuid, partUuid, AnalysisType3D.Model));
      }

      const noPointCloudsLoaded = pointClouds.numPointClouds === 0;

      if (!promises.length && noPointCloudsLoaded) {
        setViewportState('unavailable');
        return;
      }
    });

    // If there's no work to be done, retain current viewport state
    if (promises.length === 0) {
      return;
    }

    setViewportState('loading');

    // Wait for all point clouds to be loaded, then render
    // Check Promise.all twice since the first round of promises adds a second to the array
    Promise.all(promises).then(async () => {
      Promise.all(promises).then(async () => {
        if (partUuids.every((partUuid) => pointCloudData.getPartitionsDownloaded(partUuid) > 0)) {
          setViewportState('viewing');
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisTypes, params.pointLimit, partUuidsString, pointCloudDataString]);

  const pointCloudStoreActions = usePointCloudStoreActions();
  const pointCloudStore = useSelector((state: RootState) => state.pointCloudStore);

  useEffect(() => {
    if (partUuids && partUuids.length !== 0) pointCloudStoreActions.ensureConsistent({partUuid: partUuids});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(partUuids)]);

  const pointCloudStoreString = JSON.stringify(pointCloudStore);

  useEffect(() => {
    // Load point cloud locations for selected parts
    if (partUuids.length === 0) return;

    const relevantPointClouds = Object.values(pointCloudStore.byId).filter((pc) => partUuids.includes(pc.partUuid));

    if (pointCloudStore.fetched === FetchingState.Fetching && relevantPointClouds.length === 0) {
      setViewportState('loading');
      return;
    }
    if (pointCloudStore.fetched === FetchingState.Fetched && relevantPointClouds.length === 0) {
      setViewportState('unavailable');
      return;
    }

    relevantPointClouds.forEach(pointCloudData.addPointCloud.bind(pointCloudData));
    forceReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(partUuids), pointCloudStoreString]);

  return {
    viewportState,
    pointClouds,
    pointCloudData,
    sceneBounds: partUuids.length ? pointCloudData.getPartBounds(partUuids) : null,
    availableAnalysisTypes,
  };
}
