import {MachinePageProps} from './index';
import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, Card, CardContent, Checkbox, FormControlLabel, Grid, TextField} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import {IUser} from '@common/api/models/users/IUser';
import {MaintenanceLogEntry, MaintenanceLogTable} from '../../../components/molecules/Table/MaintenanceLogTable';
import {useMachineLogStoreActions} from '../../../store/actions';
import {RootState} from '../../../store/reducers';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import {machineLogCreatePOST} from '../../../api/ajax/machineLogs';
import {userSearchGET} from '../../../api/ajax/users';
import {shortNoSecondsDateTimeFormat} from '../../../utils/config';
import {DateTimePicker} from '@material-ui/pickers';

export function MaintenanceLogsPage(props: MachinePageProps) {
  const machineLogActions = useMachineLogStoreActions();
  const machineLogStore = useSelector((state: RootState) => state.machineLog);
  const [users, setUsers] = useState<IUser[]>([]);

  const relevantMachineLogs = useMemo(() => {
    const machineLogs = Object.values(machineLogStore.byId).filter((l) => l.machineUuid === props.machine.uuid);
    return machineLogs.sort((a, b) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });
  }, [machineLogStore.byId, props.machine.uuid]);

  const messages: MaintenanceLogEntry[] = useMemo(() => {
    return relevantMachineLogs.map((ml) => {
      const user = users.find((user) => user.uuid === ml.userUuid);
      return {
        user: user ? `${user.firstName} ${user.lastName}` : '***** *****',
        message: ml.message,
        date: new Date(ml.timestamp),
      };
    });
  }, [relevantMachineLogs, users]);

  useEffect(() => {
    if (props.machine.uuid) {
      machineLogActions.ensureConsistent({machineUuid: props.machine.uuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.machine.uuid]);

  useEffect(() => {
    async function getUsers() {
      const userUuids = relevantMachineLogs.map((machineLog) => machineLog.userUuid);
      if (userUuids.length) {
        const userInfoResponse = await userSearchGET('', {
          uuid: userUuids,
        });

        if (userInfoResponse.success) {
          setUsers(userInfoResponse.data);
        }
      }
    }

    getUsers();
  }, [relevantMachineLogs]);

  const isLoading = machineLogStore.fetched === FetchingState.Fetching;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MaintenanceLogTable messages={messages} isLoading={isLoading} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <MaintenanceLogForm machine={props.machine} device={props.device} />
      </Grid>
    </Grid>
  );
}

export function MaintenanceLogForm(props: MachinePageProps) {
  const [useCurrent, setUseCurrent] = useState(true);
  const [dateValue, setDateValue] = useState(new Date());
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);

  const handleSubmit = async () => {
    const toSubmit = message;
    setMessage('');
    setSending(true);
    await machineLogCreatePOST({
      machineUuid: props.machine.uuid,
      message: toSubmit,
      timestamp: useCurrent ? new Date() : dateValue,
    });
    setSending(false);
  };

  const handleUseCurrent = (useCurrent: boolean) => {
    setDateValue(new Date());
    setUseCurrent(useCurrent);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              multiline={true}
              rows={5}
              placeholder={'New Maintenance Note'}
              fullWidth
              variant="outlined"
              label={'Write maintenance note'}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={sending}>
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={useCurrent} onChange={(e, v) => handleUseCurrent(v)} />}
                  label="Use current date/time"
                />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    variant="inline"
                    label="Submission date"
                    value={dateValue}
                    onChange={(date) => date && setDateValue(date)}
                    format={shortNoSecondsDateTimeFormat}
                    ampm={false}
                    autoOk={true}
                    disabled={useCurrent}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
