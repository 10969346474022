import React, {useState} from 'react';
import {Add} from '@material-ui/icons';
import {Box, Button, TextField, Typography} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {OrderDirection} from '@common/utils/ordering/ordering';
import {IMachine} from '@common/api/models/devices/machines/IMachine';
import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {locationBasedDefectsReportPOST} from '../../api/ajax/locationBasedDefectsReport';
import VerticalButton from '../../components/atoms/VerticalButton';
import {GenericDialog} from '../../components/molecules/DialogButton';
import SearchAndSelect from '../../components/molecules/Selector/SearchAndSelect';
import {buildsAllGET} from '../../api/ajax/builds';
import {useSmallScreenSize} from '../../utils/utilHooks';
import {machinesAllGET} from '../../api/ajax/machines';
import {Alert} from '@material-ui/lab';

function NewReportButton() {
  const isSmallScreen = useSmallScreenSize();
  const [isOpen, setOpen] = useState(false);

  if (isSmallScreen) {
    return (
      <>
        <VerticalButton color="secondary" onClick={() => setOpen(true)}>
          <Add />
          <Typography variant="caption">New Report</Typography>
        </VerticalButton>
        <NewReportModal isOpen={isOpen} setOpen={setOpen} />
      </>
    );
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        <Add />
        New Report
      </Button>
      <NewReportModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
}

export default NewReportButton;

function NewReportModal({isOpen, setOpen}: {isOpen: boolean; setOpen: (isOpen: boolean) => void}) {
  const [requesting, setRequesting] = useState(false);
  const [name, setName] = useState<string>('');
  const [machine, setMachine] = useState<IMachine | undefined>();
  const [builds, setBuilds] = useState<Array<IBuild> | undefined>();
  const history = useHistory();

  function closeModal() {
    setName('');
    setMachine(undefined);
    setBuilds(undefined);
    setOpen(false);
  }

  async function handleCreateProfile() {
    setRequesting(true);
    if (!builds || !name) return;

    const res = await locationBasedDefectsReportPOST({name, buildUuids: builds.map((build) => build.uuid)});

    if (res.success) {
      closeModal();
      history.replace(`/reports/locationBasedDefects/${res.data.uuid}/`);
    }

    setRequesting(false);
  }

  return (
    <GenericDialog
      title="New Report"
      content={
        <NewReportContent
          builds={builds}
          name={name}
          setBuilds={setBuilds}
          setName={setName}
          machine={machine}
          setMachine={(machine) => {
            setMachine(machine);
            setBuilds([]);
          }}
        />
      }
      confirmText="Create report"
      isOpen={isOpen}
      closeDialog={closeModal}
      onSuccess={handleCreateProfile}
      confirmDisabled={requesting || !builds?.length || !name || name.length < 3}
      requestInProgress={requesting}
      maxWidth="lg"
    />
  );
}

function NewReportContent({
  machine,
  setMachine,
  builds,
  setBuilds,
  name,
  setName,
}: {
  machine: IMachine | undefined;
  setMachine: (newMachine: IMachine | undefined) => void;
  builds: Array<IBuild> | undefined;
  setBuilds: (newBuilds: Array<IBuild> | undefined) => void;
  name: string;
  setName: (newName: string) => void;
}) {
  async function fetchBuilds(search: string) {
    const res = await buildsAllGET({
      uuid: {notIn: builds?.map((build) => build.uuid)},
      ...(!!search ? {name: {like: search}} : {}),
      machineUuid: machine?.uuid,
      hasSliceData: true,
      state: [BuildState.COMPLETED, BuildState.SUCCEEDED],
      sortBy: {end: OrderDirection.DESC},
      take: 10,
    });

    if (res.success) return res.data;
    else return [];
  }

  async function fetchMachines(search: string) {
    const res = await machinesAllGET({
      uuid: {notIn: machine?.uuid ? [machine.uuid] : []},
      ...(!!search ? {name: {like: search}} : {}),
      take: 10,
    });

    if (res.success) return res.data;
    else return [];
  }

  return (
    <Box display="flex" flexDirection="column" maxWidth="440px" padding="8px 0px">
      <Alert severity="info" style={{marginBottom: '24px'}}>
        All builds included in a Location Based Defects Report must be completed, with at least 1 valid slice file and
        no slice files pending verification.
      </Alert>
      <TextField
        label="Report Name*"
        variant="outlined"
        fullWidth
        size="small"
        value={name}
        onChange={(e) => setName(e.target?.value)}
      />
      <br />
      <SearchAndSelect<IMachine>
        selected={machine}
        setSelected={setMachine}
        getSuggestionValue={(resource) => resource.name}
        isSelected={(resource) => resource.uuid === machine?.uuid}
        fetchFunction={fetchMachines}
        label="Select Machine"
      />
      <br />
      <SearchAndSelect<IBuild>
        selected={builds || []}
        setSelected={setBuilds}
        getSuggestionValue={(resource) => resource.name}
        isSelected={(resource) => !!builds?.find((build) => build.uuid === resource.uuid)}
        fetchFunction={fetchBuilds}
        disabled={!machine}
        label="Builds to include"
        disableCloseOnSelect
      />
    </Box>
  );
}
