import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react';

import {isEmpty, isEqual} from 'lodash';
import {useLocation} from 'react-router-dom';

import {isCompletedBuildState} from '@common/api/models/builds/IBuild';
import {AnalysisType2D} from '@common/api/models/builds/data/defects/IDefect';

import {
  chooseImageForViewing,
  getSmallestImage,
} from '../../../../pages/builds/liveBuild/activeBuildPages/ViewportsPage';
import {defaultOverlayParams} from './analysisType';
import {GotoLayerInput} from './controls/GotoLayerInput';
import LayerSlider from './controls/LayerSlider';
import SingleImageViewport, {ImageOverlays} from './SingleImageViewport';
import {
  BUTTON_HEIGHT,
  BUTTON_MARGIN,
  BUTTON_WIDTH,
  defaultPart,
  getCalibrationData,
  generateColourMaps,
  generateOverlayColourMaps,
  imageSizePixels,
  getClosestFullLayer,
  shouldColourLsdd,
} from './utils';

import InfoButton from '../../../atoms/InfoButton';
import LazyLayerImage from '../../../atoms/LazyLayerImage';

import {RGBColor} from 'react-color';
import AddView from './controls/AddView';
import CloseView from './controls/CloseView';
import CmSelector, {colourMapOptions} from './controls/CmSelector';
import CombinedDownload from './controls/CombinedDownload';
import LayerSelectorOverlay from './controls/LayerSelector';
import LinkLayer from './controls/LinkLayer';
import LinkView from './controls/LinkView';
import LiveUpdating from './controls/LiveUpdating';
import NavigateLayer from './controls/NavigateLayer';
import {PerformanceToggle} from './controls/PerformanceToggle';
import ViewSelector from '../controls/ViewSelector';
import {ViewportProps} from '../viewportProps';
import {MultiViewerProps} from '../MultiViewer';
import {FadingDiv} from '../FadingComponents';
import {GetPositionFn, OnPositionChangeFn, SetPositionFn} from './viewportHooks';
import {BoundingBoxToggle} from './controls/BoundingBoxToggle';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store/reducers/index';
import {HoveredPartLabel} from './controls/HoveredPartLabel';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {cmap} from '../../../../utils/colormap';
import {isTouchDevice} from '../../../../utils/webtools';
import {FadingButtonsToggle} from './controls/FadingButtonsToggle';
import CmRangeSlider from './controls/CmRangeSlider';
import CrosshairsToggle from './CrosshairsToggle';
import {PartialLayersToggle} from './controls/PartialLayersToggle';
import {LayerImageResolutionSize} from '@common/api/models/builds/data/ILayerImage';
import HideOverlays from './HideOverlaysToggle';
import BrightnessSlider from './controls/BrightnessSlider';

export interface MultiLayerViewportState {
  linkedLayers?: boolean;
  linkedPosition?: boolean;
}

export const defaultViewportState: MultiLayerViewportState = {
  linkedLayers: true,
  linkedPosition: true,
};

export const defaultCmRange = [20, 255];
export const defaultSevereDefectColours = {r: 247, g: 74, b: 16, a: 1};
export const defaultLayerMaskColour = {r: 50, g: 230, b: 100, a: 0.5};
export interface MultiLayerViewportProps extends ViewportProps, MultiViewerProps, MultiLayerViewportState {
  viewportKey?: string;

  /** layer number to show if linkedLayers is true. Otherwise, viewport layer number is independent */
  currentLinkedLayer: number;
  onCurrentLinkedLayerChange?: (newLayer: number) => void;

  fetchLayerData: (currentLayer: number) => void;
  hoveredLayerId?: number;
  fetchDataForHoveredThumbnail?: (hoveredLayerId: number) => any;

  alone2d?: boolean; // True if this the only 2D viewport

  getInitialPosition?: GetPositionFn;
  onPositionChange?: OnPositionChangeFn;
  setPositionFnRef?: React.MutableRefObject<SetPositionFn | undefined>;

  onLinkedLayers?: () => void;
  onLinkedPosition?: () => void;

  linkedLoading?: boolean;
  setLinkedLoading?: (value: boolean) => void;

  partialLayerNumbers: Set<number>;

  linkedLiveUpdating: boolean;
  setLinkedLiveUpdating: (value: boolean) => void;
  setCrosshairsEnabled: (enabled: boolean) => void;
  crosshairsEnabled: boolean;
  setHoveredPosition: (newPosition: {x?: number; y?: number; z?: number} | null) => void;
  hoveredPosition: {x?: number; y?: number; z?: number} | null;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function MultiLayerViewport(props: MultiLayerViewportProps) {
  const isTouchScreen = isTouchDevice();
  // Layer shown by this viewport: May differ from props.currentLinkedLayer if linkedLayers is false
  const [currentLayerInternal, setCurrentLayerInternal] = useState(props.currentLinkedLayer);
  const [liveUpdatingInternal, setLiveUpdatingInternal] = useState(props.linkedLiveUpdating);
  const [layerLoaded, setLayerLoaded] = useState<number>();
  const viewContainerRef = useRef() as React.RefObject<HTMLInputElement>;

  const [isLoading, setLoading] = useState(false);
  const [paginationStatus, setPaginationStatus] = useState(true);
  const [stageWidth, setStageWidth] = useState(1000);
  const [stageHeight, setStageHeight] = useState(500);
  // @ts-ignore
  const [stage, setStage] = useState<Stage | null>(null);
  const [forcedUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const [selectKey, setSelectKey] = useState(AnalysisType2D.Layer);
  const [lazyLayerImage, setLazyLayerImage] = useState<LazyLayerImage>();
  const [slidingLayer, setSlidingLayer] = useState<number | null>(null);

  const [overlayParams, setOverlayParams] = useState<ImageOverlays[]>([]);
  const [currentColourMap, setColourMap] = useState(colourMapOptions[0]);
  const [hideOverlays, setHideOverlays] = useState(false);
  const [cmRange, setCmRange] = useState(defaultCmRange);
  const [colouredLoadedImages, setColouredLoadedImage] = useState<{
    [layerID_defectType_cmRange: string]: {image: HTMLImageElement};
  }>({});
  const [selectedOverlays, setSelectedOverlays] = useState<{
    [analysisType in AnalysisType2D]?: boolean;
  }>({[AnalysisType2D.Layer]: true});

  const [gotoFrameOpen, setGotoFrameOpen] = useState(false);
  const [performanceMode, setPerformanceMode] = useState(true);
  const [showBoundingBoxes, setShowBoundingBoxes] = useState(false);
  const [hidePartialLayers, setHidePartialLayers] = useState(false);
  const [analysisOverlayParams, setAnalysisTypes] = useState(defaultOverlayParams);
  const [layerMaskColour, setLayerMaskColour] = useState<RGBColor>(defaultLayerMaskColour);
  const [hasOverlayColourChanged, setHasOverlayColourChanged] = useState(false);
  const [brightness, setBrightness] = useState(0);
  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const [hoveredPart, setHoveredPart] = useState<IPartGETResponse | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mouseOn, setMouseOn] = useState(isTouchScreen);

  const query = useQuery();
  const queryAnalysisType = query.get('analysisType');

  const partStore = useSelector((s: RootState) => s.partStore);
  const parts = Object.values(partStore.byId).filter((part) => part.buildUuid === props.build.uuid);

  const calibrationStore = useSelector((state: RootState) => state.calibrationStore);

  const {plateBoundingBox, calibrationResolution, calibrationScale} = !!props.build
    ? getCalibrationData(calibrationStore, props.build.calibrationUuid)
    : {plateBoundingBox: undefined, calibrationResolution: undefined, calibrationScale: undefined};

  const colormappings = useMemo(() => cmap({colormap: currentColourMap, nshades: 256}), [currentColourMap]);

  /** Uses either the linked layer or this viewport's independent layer, according to `props.linked` layers */
  const currentLayer = props.linkedLayers ? props.currentLinkedLayer : currentLayerInternal;
  const liveUpdating = props.linkedLayers ? props.linkedLiveUpdating : liveUpdatingInternal;

  const hasPartialLayers = props.partialLayerNumbers.size > 0;

  useEffect(() => {
    viewContainerRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Fetch the new layerImage metadata for the new layer on layer change */
  useEffect(() => {
    if (currentLayer !== undefined && currentLayer > 0) {
      props.fetchLayerData(currentLayer);
      setCurrentLayerInternal(currentLayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLayer]);

  useEffect(() => {
    if (queryAnalysisType) {
      setSelectedOverlays({
        layer: true,
        [queryAnalysisType]: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryAnalysisType]);

  /** Sets the linked layer or just this viewport's layer according to `props.linked` layers */
  const setCurrentLayer = (newLayer: number) => {
    setCurrentLayerInternal(newLayer);
    if (props.linkedLayers && props.onCurrentLinkedLayerChange) {
      props.onCurrentLinkedLayerChange(newLayer);
    }

    if (newLayer !== props.totalLayers) {
      setLiveUpdatingInternal(false);
      if (props.linkedLayers) props.setLinkedLiveUpdating(false);
    }
  };

  const setLiveUpdating = (liveUpdating: boolean) => {
    setLiveUpdatingInternal(liveUpdating);

    if (props.linkedLayers) props.setLinkedLiveUpdating(liveUpdating);
    if (liveUpdating) setCurrentLayer(props.totalLayers);
  };

  const handleChangeDims = (width: number, height: number) => {
    setStageWidth(width);
    setStageHeight(height);
  };

  const onCurrentLayerChange = (newLayer: number) => {
    // Todo: Skip Layers
    if (newLayer < 1) newLayer = 1;
    if (newLayer > props.totalLayers) {
      newLayer = props.totalLayers;
    }
    if (newLayer === currentLayerInternal) return;

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      props.setLinkedLoading ? props.setLinkedLoading(true) : setLoading(true);
    }, 2000);

    setCurrentLayer(newLayer);
  };

  const handleAddView = () => {
    if (props.onAddView) {
      props.onAddView();
    }
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleLinkedLayers = () => {
    if (props.onLinkedLayers) {
      props.onLinkedLayers();
    }
  };

  const handleLinkedPosition = () => {
    if (props.onLinkedPosition) {
      props.onLinkedPosition();
    }
  };

  const handleOn3DClick = () => {
    props.onViewportTypeToggle();
  };

  const incLayer = (increment: number) => {
    let newLayer = currentLayerInternal;

    if (!hidePartialLayers) {
      newLayer = currentLayerInternal + increment;
    } else {
      const direction = increment > 0 ? 1 : -1;
      let lastGoodLayerNum = currentLayerInternal;

      while (increment !== 0) {
        newLayer += direction;
        if (newLayer <= 0 || newLayer > props.totalLayers) break;

        if (!props.partialLayerNumbers.has(newLayer)) {
          increment -= direction;
          lastGoodLayerNum = newLayer;
        }
      }
      newLayer = lastGoodLayerNum;
    }
    onCurrentLayerChange(newLayer);
  };

  const handleFrameButtonClick = () => {
    setGotoFrameOpen(!gotoFrameOpen);
  };

  const handleGoToFrameClose = () => {
    setGotoFrameOpen(false);
  };

  const handleGoToFrame = (value: number) => {
    onCurrentLayerChange(value);
  };

  const onMouseEnter = () => {
    setMouseOn(true);
  };

  const onMouseLeave = () => {
    setMouseOn(false);
  };

  const selectOverlay = (id: AnalysisType2D) => {
    const overlayEnabled = !selectedOverlays[id];
    const newSelectedOverlays = {...selectedOverlays, [id]: overlayEnabled};

    // if colourmap set to true, load
    if (id === 'colourMap' && overlayEnabled) {
      props.setLinkedLoading ? props.setLinkedLoading(true) : setLoading(true);
    }

    if (overlayEnabled) setSelectKey(id);
    else {
      const nextUp = Object.keys(newSelectedOverlays)
        .reverse()
        .find((overlayKey) => newSelectedOverlays[overlayKey as AnalysisType2D]);
      // Can't disable the last overlay
      if (!nextUp) return;
      setSelectKey(nextUp as AnalysisType2D);
    }

    setSelectedOverlays(newSelectedOverlays);
  };

  const chooseBestImages = async () => {
    let bestLayerImageUuid: string | undefined;
    const result: ImageOverlays[] = [];
    const loadedImages: {[key: string]: HTMLImageElement | undefined} = {};
    const partImages = props.loadedLayers[currentLayer]?.[defaultPart];

    await Promise.all(
      Object.keys(selectedOverlays).map((overlayKey) => {
        const overlayImages = partImages?.[overlayKey]?.images || [];

        if (overlayKey !== 'colourMap' && partImages?.[overlayKey]) {
          const smallestGoodImage = getSmallestImage(overlayImages);
          const bestLayerImage = chooseImageForViewing(overlayImages, false, performanceMode);
          const bestLoadedLayerImage = chooseImageForViewing(overlayImages, true, performanceMode);

          bestLayerImageUuid = bestLoadedLayerImage?.uuid;

          const isBestLoaded =
            !!bestLayerImage?.uuid &&
            bestLayerImage.uuid === overlayParams.find((overlay) => overlay.id === overlayKey)?.uuid;

          function loadImageIfNeeded(image: HTMLImageElement) {
            if (!(image as any)[props.viewportKey!]) {
              (image as any)[props.viewportKey!] = true;
              // If this image is loaded, all we need to do is re-draw the canvas
              // otherwise we should re-check for a better image
              image.addEventListener('load', () => {
                if (isBestLoaded) {
                  if (stage) stage.getStage().batchDraw();
                } else {
                  forceUpdate();
                }
              });
            }
          }

          if (bestLayerImage !== bestLoadedLayerImage) {
            if (!bestLoadedLayerImage) {
              // without any loaded images, we should load the smallest good image so it arrives first.
              if (smallestGoodImage) {
                smallestGoodImage.image.then((image) => {
                  loadImageIfNeeded(image);
                });
              }
            }

            // If the best image is not loaded, then load best other loaded image instead,
            // and wait for the actual best image to load.
            if (bestLayerImage) {
              bestLayerImage.image.then((image) => {
                loadImageIfNeeded(image);
              });
            }
          }

          return new Promise(async (res, _rej) => {
            if (bestLoadedLayerImage) {
              setLazyLayerImage(bestLoadedLayerImage);
              const image = await bestLoadedLayerImage.image;

              loadImageIfNeeded(image);
              loadedImages[overlayKey] = image;
            }
            res(null);
          });
        }
        return null;
      })
    );

    if (selectedOverlays['colourMap']) {
      if (props.cmLoadedImages?.[currentLayer]?.[`${currentColourMap}_${cmRange[0]}_${cmRange[1]}`]) {
        loadedImages['colourMap'] =
          props.cmLoadedImages[currentLayer][`${currentColourMap}_${cmRange[0]}_${cmRange[1]}`].image;
      } else {
        if (
          loadedImages['layer']?.complete &&
          loadedImages['layer']?.width > 400 &&
          loadedImages['layer']?.height > 400
        ) {
          loadedImages['colourMap'] = generateColourMaps(
            loadedImages['layer'] as HTMLImageElement,
            cmRange,
            colormappings,
            forceUpdate
          );
          props.setCmLoadedImage({
            ...props.cmLoadedImages,
            [currentLayer]: {
              ...props.cmLoadedImages[currentLayer],
              [`${currentColourMap}_${cmRange[0]}_${cmRange[1]}`]: {
                image: loadedImages['colourMap'],
              },
            },
          });
        }
      }
    }

    const shouldColour = (defectType: 'layerMask' | 'lsdd') => {
      return (
        !!loadedImages[defectType] &&
        loadedImages[defectType]!.complete &&
        loadedImages[defectType]!.width > 400 &&
        loadedImages[defectType]!.height > 400
      );
    };

    const colourImage = (defectType: 'layerMask' | 'lsdd', colour: RGBColor) => {
      const colorMapRange = defectType === 'lsdd' ? defaultCmRange : cmRange;
      return generateOverlayColourMaps(
        loadedImages[defectType] as HTMLImageElement,
        colorMapRange,
        colour,
        forceUpdate
      );
    };

    if (selectedOverlays['layerMask']) {
      const savedImageKey = `${currentLayer}_layerMask_${cmRange[0]}_${cmRange[1]}`;
      const savedColouredImage = colouredLoadedImages?.[savedImageKey];

      if (savedColouredImage && !hasOverlayColourChanged) {
        loadedImages['layerMask'] = savedColouredImage.image;
      } else {
        if (shouldColour('layerMask')) {
          loadedImages['layerMask'] = colourImage('layerMask', layerMaskColour);

          setColouredLoadedImage({
            ...colouredLoadedImages,
            [savedImageKey]: {image: loadedImages['layerMask']},
          });
        }
      }
    }

    if (selectedOverlays['lsdd'] && shouldColourLsdd(partImages)) {
      const savedImageKey = `${currentLayer}_lsdd`;
      const savedColouredImage = colouredLoadedImages?.[savedImageKey];

      if (savedColouredImage && !hasOverlayColourChanged) {
        loadedImages['lsdd'] = savedColouredImage.image;
      } else {
        if (shouldColour('lsdd')) {
          loadedImages['lsdd'] = colourImage('lsdd', defaultSevereDefectColours);

          setColouredLoadedImage({
            ...colouredLoadedImages,
            [savedImageKey]: {image: loadedImages['lsdd']},
          });
        }
      }
    }

    for (const overlayKey of Object.values(AnalysisType2D)) {
      if (loadedImages[overlayKey]) {
        if (selectedOverlays[overlayKey]) {
          const overlayParams = analysisOverlayParams[overlayKey];
          result.push({
            filters: overlayParams.filters,
            filterParams: overlayParams.filterParams,
            image: loadedImages[overlayKey],
            id: overlayKey,
            uuid: bestLayerImageUuid,
          });
        }
      }
    }

    // If it is the same, don't cause a render.
    if (isEqual(result, overlayParams)) {
      // is equal, so not updating
    } else {
      setOverlayParams(result);
    }
  };

  const onPositionChange = useCallback(
    (newPosition) => props.onPositionChange?.(newPosition),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onPositionChange]
  );

  // handle loadedlayer change to prevent updating viewport
  useEffect(() => {
    const hasDisplayable = props.loadedLayers?.[currentLayer]?.[defaultPart]?.layer?.images.some((image) =>
      performanceMode
        ? image.resolutionSize === LayerImageResolutionSize.HALF_RES
        : image.resolutionSize === LayerImageResolutionSize.FULL_RES
    );

    if (layerLoaded !== currentLayer && isEmpty(overlayParams) && hasDisplayable) {
      chooseBestImages();
      setLayerLoaded(currentLayer);
      if (stage) {
        stage.getStage().batchDraw();
      }
    }
    // assuming there could be atmost 150 missing layers
    if (Object.values(props.loadedLayers).length >= props.totalLayers - 150) {
      setPaginationStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loadedLayers]);

  // If a linked view has loaded the colour map - redraw with it instead of loading it here.
  const cmLoadedImage = useMemo(
    () =>
      !!(
        selectedOverlays['colourMap'] &&
        props.cmLoadedImages?.[currentLayer]?.[`${currentColourMap}_${cmRange[0]}_${cmRange[1]}`]
      ),
    [props.cmLoadedImages, currentLayer, selectedOverlays, currentColourMap, cmRange]
  );

  useEffect(() => {
    if (cmLoadedImage) chooseBestImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmLoadedImage]);

  useEffect(() => {
    chooseBestImages();
    if (stage) {
      stage.getStage().batchDraw();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOverlays, colormappings, currentLayer, performanceMode, analysisOverlayParams]);

  useEffect(() => {
    if (stage) {
      if (
        !(
          selectedOverlays['colourMap'] &&
          props.cmLoadedImages?.[currentLayer]?.[`${currentColourMap}_${cmRange[0]}_${cmRange[1]}`]
        ) ||
        hasOverlayColourChanged
      ) {
        chooseBestImages();

        if (hasOverlayColourChanged) {
          setHasOverlayColourChanged(false);
        }
      }
      stage.getStage().batchDraw();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedUpdate]);

  const setOverlayColours = (analysisType: AnalysisType2D, newColour: RGBColor) => {
    if (analysisType === AnalysisType2D.LayerMask) {
      setLayerMaskColour(newColour);
    }

    setHasOverlayColourChanged(true);
  };

  const downloadURI = (uri: any, name: string) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const StackedDownload = () => {
    if (stage) {
      const dataURL = stage.getStage().toDataURL({pixelRatio: 5});
      downloadURI(dataURL, props.build.name + '-layer-' + currentLayer + '.png');
    }
  };

  const incLayerOnArrowKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 38) incLayer(event.shiftKey || event.ctrlKey ? 10 : 1);
    if (event.keyCode === 40) incLayer(event.shiftKey || event.ctrlKey ? -10 : -1);
  };

  const [imageWidthPixels, imageHeightPixels]: number[] = imageSizePixels(
    calibrationResolution,
    lazyLayerImage,
    performanceMode
  );

  const imagesLoaded = Object.keys(selectedOverlays).some(
    (key) => props.loadedLayers[currentLayer]?.[defaultPart]?.[key]?.images.length > 0
  );
  const anyMatchingImagesAvailable = Object.entries(selectedOverlays).some(
    ([key, value]) => value && props.loadedLayers[currentLayer]?.[defaultPart]?.[key]?.images.length > 0
  );
  const imageNotFound = !anyMatchingImagesAvailable && imagesLoaded;
  const layerOverlaySelected = Object.entries(selectedOverlays).some(([key, value]) => value && key === 'layer');

  return (
    <div
      onMouseEnter={isTouchScreen ? undefined : onMouseEnter}
      onMouseLeave={isTouchScreen ? undefined : onMouseLeave}
      onMouseMove={
        isTouchScreen
          ? undefined
          : () => {
              if (!mouseOn) setMouseOn(true);
            }
      }
      onKeyDown={incLayerOnArrowKey}
      tabIndex={-1}
      ref={viewContainerRef}
    >
      <SingleImageViewport
        isLoading={props.linkedLoading || isLoading}
        overlays={
          hideOverlays && layerOverlaySelected
            ? overlayParams.filter((overlay) => overlay.id === 'layer')
            : overlayParams
        }
        mmPerPixel={props.mmPerPixel}
        imageWidthPixels={imageWidthPixels}
        imageHeightPixels={imageHeightPixels}
        onChangeDims={handleChangeDims}
        height={props.height}
        parentGridColumns={props.parentGridColumns}
        setPositionFnRef={props.setPositionFnRef}
        fitToScreenOnStart={props.alone2d}
        onPositionChange={onPositionChange}
        imageWidth={lazyLayerImage?.widthPx}
        // @ts-ignore
        getInitialPosition={props.getInitialPosition?.bind(null, !!props.linkedPosition)}
        stageRef={setStage}
        stopLoading={() => {
          if (timer.current) {
            clearTimeout(timer.current);
          }
          props.setLinkedLoading ? props.setLinkedLoading(false) : setLoading(false);
        }}
        alone2d={props.alone2d}
        isFitToWrapper={true}
        buildUuid={props.build.uuid}
        currentLayer={currentLayer}
        showBoundingBoxes={showBoundingBoxes}
        onPartHover={setHoveredPart}
        hoveredPart={hoveredPart}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        mouseOn={mouseOn}
        setMouseOn={isTouchScreen ? (_: boolean) => {} : setMouseOn}
        setHoveredPosition={props.setHoveredPosition}
        hoveredPosition={props.crosshairsEnabled ? props.hoveredPosition : undefined}
        showHoveredMmPosition={!sidebarOpen}
        plateBoundingBoxMM={plateBoundingBox?.map((point) => point * calibrationScale)}
        imageNotFound={imageNotFound}
        // Convert -4-0 to 0-1 and 0-4 to 1-5
        brightness={brightness >= 0 ? brightness + 1 : (brightness + 4) / 4}
      >
        <LayerSelectorOverlay
          selectKey={selectKey}
          onSelect={selectOverlay}
          selectedIds={selectedOverlays}
          partImages={props.loadedLayers[currentLayer]?.[defaultPart]!}
          analysisOverlayParams={analysisOverlayParams}
          setAnalysisOverlayParams={setAnalysisTypes}
          overlayColors={{[AnalysisType2D.LayerMask]: layerMaskColour}}
          setOverlayColours={setOverlayColours}
          forceUpdate={forceUpdate}
        />

        <HideOverlays
          mouseOn={mouseOn}
          hideOverlays={hideOverlays}
          setHideOverlays={setHideOverlays}
          disabled={!layerOverlaySelected}
          left={BUTTON_MARGIN * 2}
          top={stageHeight - BUTTON_HEIGHT * 5 - BUTTON_HEIGHT / 2}
        />
        <CrosshairsToggle
          mouseOn={mouseOn}
          crosshairsEnabled={props.crosshairsEnabled}
          setCrosshairsEnabled={props.setCrosshairsEnabled}
          left={BUTTON_MARGIN * 2}
          top={stageHeight - BUTTON_HEIGHT * 4 - BUTTON_HEIGHT / 2}
        />
        <CombinedDownload StackedDownload={StackedDownload} stageHeight={stageHeight} mouseOn={mouseOn} />
        {props.multiViewEnabled && props.canAdd && <AddView handleAddView={handleAddView} mouseOn={mouseOn} />}
        {props.multiViewEnabled && !props.alone && <CloseView handleClose={handleClose} mouseOn={mouseOn} />}
        {props.multiViewEnabled && !props.alone && (
          <LinkLayer handleLinkedLayers={handleLinkedLayers} mouseOn={mouseOn} linkedLayers={props.linkedLayers} />
        )}

        {props.multiViewEnabled && !props.alone && (
          <LinkView
            handleLinkedPosition={handleLinkedPosition}
            mouseOn={mouseOn}
            linkedPosition={props.linkedPosition}
          />
        )}

        {isTouchScreen && (
          <FadingButtonsToggle
            checked={mouseOn}
            setChecked={setMouseOn}
            // Push down button when live update is removed
            cssTop={
              !isCompletedBuildState(props.build.state)
                ? stageHeight - BUTTON_HEIGHT * (hasPartialLayers ? 11.6 : 10.65)
                : stageHeight - BUTTON_HEIGHT * (hasPartialLayers ? 10.6 : 9.65)
            }
          />
        )}

        {hasPartialLayers && (
          <PartialLayersToggle
            mouseOn={mouseOn}
            cssTop={
              !isCompletedBuildState(props.build.state)
                ? stageHeight - BUTTON_HEIGHT * 10.7
                : stageHeight - BUTTON_HEIGHT * 9.7
            }
            hidePartialLayers={hidePartialLayers}
            setHidePartialLayers={(newHidePartialLayers) => {
              setHidePartialLayers(newHidePartialLayers);
              if (newHidePartialLayers && props.partialLayerNumbers.has(currentLayer)) {
                const newLayer = getClosestFullLayer(currentLayer, props.totalLayers, true, props.partialLayerNumbers);
                setCurrentLayer(newLayer);
              }
            }}
            partialLayersCount={props.partialLayerNumbers.size}
          />
        )}

        {props.loadedLayers[currentLayer] && props.loadedLayers[currentLayer]?.[defaultPart]?.['layer'] && (
          <PerformanceToggle
            checked={performanceMode}
            setChecked={setPerformanceMode}
            mouseOn={mouseOn}
            // Push down button when live update is removed
            cssTop={
              !isCompletedBuildState(props.build.state)
                ? stageHeight - BUTTON_HEIGHT * 9.8
                : stageHeight - BUTTON_HEIGHT * 8.8
            }
          />
        )}

        {props.loadedLayers[currentLayer] && props.loadedLayers[currentLayer]?.[defaultPart]?.['layer'] && (
          <InfoButton
            cssRight={BUTTON_MARGIN}
            // Push down button when live update is removed
            cssTop={
              !isCompletedBuildState(props.build.state)
                ? stageHeight - BUTTON_HEIGHT * 9.25
                : stageHeight - BUTTON_HEIGHT * 8.25
            }
            width={lazyLayerImage?.widthPx}
            height={lazyLayerImage?.heightPx}
            mouseOn={mouseOn}
            timestamp={props.loadedLayers[currentLayer]?.[defaultPart]?.['layer'].timestamp}
            isPartialLayer={props.partialLayerNumbers.has(currentLayer)}
          />
        )}

        <BoundingBoxToggle
          checked={showBoundingBoxes}
          setChecked={setShowBoundingBoxes}
          mouseOn={mouseOn}
          boundingBoxesAvailable={!!plateBoundingBox && !!plateBoundingBox.length && !!parts.length}
          // Push down button when live update is removed
          cssTop={
            !isCompletedBuildState(props.build.state)
              ? stageHeight - BUTTON_HEIGHT * 7.95
              : stageHeight - BUTTON_HEIGHT * 6.95
          }
        />

        {/* Only show `LiveUpdating` option if build has not finished */}
        {!isCompletedBuildState(props.build.state) && (
          <LiveUpdating
            isLiveUpdating={liveUpdating}
            handleLiveClicked={setLiveUpdating}
            stageHeight={stageHeight}
            mouseOn={mouseOn}
          />
        )}

        <NavigateLayer
          stageHeight={stageHeight}
          mouseOn={mouseOn}
          currentLayer={slidingLayer || currentLayer}
          totalLayers={props.totalLayers}
          incLayer={incLayer}
          handleFrameButtonClick={handleFrameButtonClick}
        />

        {gotoFrameOpen && (
          <GotoLayerInput
            initialValue={currentLayer}
            onSubmit={handleGoToFrame}
            onClose={handleGoToFrameClose}
            stageHeight={stageHeight}
          />
        )}

        <CmSelector
          stageHeight={stageHeight}
          mouseOn={mouseOn}
          currentColourMap={currentColourMap}
          setColourMap={setColourMap}
        />

        <ViewSelector
          mouseOn={mouseOn}
          on3DToggle={handleOn3DClick}
          viewport3dAvailable={props.viewport3DAvailable}
          is3DView={false}
        />

        {selectedOverlays['colourMap'] && (
          <CmRangeSlider
            mouseOn={mouseOn}
            cmRange={cmRange}
            onChange={(val: number[]) => setCmRange(val)}
            onChangeCommitted={(_val: number[]) => chooseBestImages()}
            divPositionStyle={
              stageWidth > 700
                ? {
                    left: BUTTON_WIDTH + BUTTON_MARGIN * 20,
                    top: stageHeight - (BUTTON_HEIGHT + 20),
                    position: 'absolute',
                  }
                : {
                    left: BUTTON_MARGIN * 2,
                    top: stageHeight - BUTTON_HEIGHT * 9,
                    position: 'absolute',
                  }
            }
          />
        )}

        <BrightnessSlider
          mouseOn={mouseOn}
          brightness={brightness}
          onChange={(val: number) => setBrightness(val)}
          divPositionStyle={
            stageWidth > 700
              ? {
                  left: BUTTON_WIDTH + BUTTON_MARGIN * 7,
                  top: stageHeight - (BUTTON_HEIGHT + 20),
                  position: 'absolute',
                }
              : {
                  left: BUTTON_MARGIN * 2,
                  top: stageHeight - BUTTON_HEIGHT * 7.5,
                  position: 'absolute',
                }
          }
        />

        <FadingDiv className={(!mouseOn && 'fade') || undefined}>
          <LayerSlider
            paginationStatus={paginationStatus}
            partialLayerNumbers={props.partialLayerNumbers}
            hidePartialLayers={hidePartialLayers}
            selectedOverlays={selectedOverlays}
            totalLayers={props.totalLayers}
            currentLayer={currentLayer}
            hoveredLayerId={props.hoveredLayerId!}
            analysisType={'layer'}
            layers={props.loadedLayers}
            stageWidth={stageWidth}
            stageHeight={stageHeight}
            onCurrentLayerChange={onCurrentLayerChange}
            fetchDataForHoveredThumbnail={props.fetchDataForHoveredThumbnail!}
            setSlidingLayer={setSlidingLayer}
          />
        </FadingDiv>

        {!sidebarOpen && <HoveredPartLabel hoveredPart={hoveredPart} />}
      </SingleImageViewport>
    </div>
  );
}
