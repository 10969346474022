import React, {useEffect, useState} from 'react';
import {ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight} from 'react-feather';
import {Box, IconButton, Tooltip, Typography} from '@material-ui/core';
import {Info} from '@material-ui/icons';

type RotateDirection = 'left' | 'right';
type RotateSize = 10 | 1;

function PartRotator({rotation = 0, setRotation}: {rotation?: number; setRotation: (rotation: number) => void}) {
  const [direction, setDirection] = useState<RotateDirection | undefined>();
  const [size, setSize] = useState<RotateSize | undefined>();
  const [timeoutDuration, setTimeoutDuration] = useState<number | undefined>();

  useEffect(() => {
    let timer: null | ReturnType<typeof setTimeout> = null;

    if (direction && size && timeoutDuration) {
      timer = setTimeout(() => rotate(direction, size), timeoutDuration);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction, size, timeoutDuration]);

  function rotate(direction: RotateDirection, size: RotateSize) {
    let newRotation = direction === 'left' ? rotation - size : rotation + size;

    if (newRotation < 0) {
      newRotation = 360 - Math.abs(newRotation);
    }
    if (newRotation >= 360) {
      newRotation = 0 + (newRotation - 360);
    }

    setRotation(newRotation);
    if (timeoutDuration) {
      setTimeoutDuration(timeoutDuration / 2);
    }
  }

  function onMouseDown(direction: RotateDirection, size: RotateSize) {
    rotate(direction, size);
    setDirection(direction);
    setSize(size);
    setTimeoutDuration(200);
  }

  function onMouseUp() {
    setDirection(undefined);
    setSize(undefined);
    setTimeoutDuration(undefined);
  }

  return (
    <Box position="absolute" bottom="24px" right="64px">
      <Box display="flex" justifyContent="center">
        <Tooltip
          title={
            <Typography>
              Please rotate the selected part (red) to align to the source part (blue). This is needed to ensure the
              similarity calculations are as accurate as possible.
            </Typography>
          }
        >
          <Typography style={{color: '#ffffff'}}>
            <b>Rotation: {rotation}°</b> <Info style={{height: '18px', marginBottom: '-2px'}} />
          </Typography>
        </Tooltip>
      </Box>
      <Box display="flex" justifyContent="center">
        <RotateButton direction="left" size={10} onMouseDown={onMouseDown} onMouseUp={onMouseUp} />
        <RotateButton direction="left" size={1} onMouseDown={onMouseDown} onMouseUp={onMouseUp} />
        <RotateButton direction="right" size={1} onMouseDown={onMouseDown} onMouseUp={onMouseUp} />
        <RotateButton direction="right" size={10} onMouseDown={onMouseDown} onMouseUp={onMouseUp} />
      </Box>
    </Box>
  );
}

export default PartRotator;

function RotateButton({
  direction,
  size,
  onMouseDown,
  onMouseUp,
}: {
  direction: RotateDirection;
  size: RotateSize;
  onMouseDown: (direction: RotateDirection, size: RotateSize) => void;
  onMouseUp: () => void;
}) {
  function getIcon() {
    if (direction === 'left') {
      if (size === 1) return ChevronLeft;
      return ChevronsLeft;
    } else {
      if (size === 1) return ChevronRight;
      return ChevronsRight;
    }
  }

  const Icon = getIcon();

  return (
    <Tooltip
      title={
        <Typography>
          Rotate part {direction === 'left' ? '-' : ''}
          {size}°
        </Typography>
      }
    >
      <IconButton
        onMouseDown={(e) => e.button === 0 && onMouseDown(direction, size)}
        onMouseUp={onMouseUp}
        // Prevent infinite spinning
        onMouseOut={() => onMouseUp()}
        size="small"
        style={{color: '#ffffff'}}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
}
