import React from 'react';
import {CloudUpload} from '@material-ui/icons';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import {Button, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {grayColor} from '../../assets/jss/material-dashboard-react';
import {useMultiPartUpload} from '../../utils/contexts/MultiPartUploadContext';
import {MultiPartUploadResourceType} from '../../utils/contexts/IMultiPartUploadContext';

type MultipartUploadProps = {
  materialUuid: string;
  orgUuid: string;
  setNumFilesUploading: React.Dispatch<React.SetStateAction<number>>;
  numFilesUploading?: number;
};

export type uploadParamType = {
  signedUrls: string[];
  filename: string;
  key: string;
  uploadId: string;
};

function MaterialsMultipartUpload({
  materialUuid,
  orgUuid,
  setNumFilesUploading,
  numFilesUploading,
}: MultipartUploadProps) {
  const {onDrop} = useMultiPartUpload();

  function dropZoneOnDrop(droppedFiles: File[]) {
    onDrop(droppedFiles, MultiPartUploadResourceType.material, {
      resourceUuid: materialUuid,
      orgUuid: orgUuid,
      setNumFilesUploading,
    });
  }

  return (
    <Dropzone onDrop={dropZoneOnDrop} noClick>
      {({getRootProps, getInputProps, open, isDragActive}) => (
        <CustomDiv isDragActive={isDragActive}>
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <Row>
                <CloudUpload color="primary" style={{fontSize: 48}} />
              </Row>
              <Row>
                <Typography variant="h4">Drag & drop to upload</Typography>
              </Row>
              <Row>
                <Typography variant="subtitle1">or</Typography>
              </Row>
              <Row>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={open}
                  size="large"
                  fullWidth={false}
                  endIcon={numFilesUploading ? <CircularProgress size={20} /> : undefined}
                >
                  Browse files...
                </Button>
              </Row>
              <Row>
                <Typography style={{fontStyle: 'italic', marginTop: '16px'}}>
                  Accepted files include .pdf, .png, .jpg, .jpeg, .gif and .zip
                </Typography>
              </Row>
            </div>
          </section>
        </CustomDiv>
      )}
    </Dropzone>
  );
}

export default MaterialsMultipartUpload;

const CustomDiv = styled.div<{isDragActive: boolean}>`
  border: dashed 2px ${({isDragActive}) => (isDragActive ? grayColor[6] : grayColor[4])};
  ${({isDragActive}) => (isDragActive ? `background-color: ${grayColor[5]};` : '')};
  transition: all 0.3s;
  border-radius: 5px;
  padding: 32px 64px;
  text-align: center;
  .dropzone {
    display: grid;
    align-items: center;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4px 0px;
`;
