import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {Paper, Grid, Divider as MuiDivider, styled} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {RootState} from '../../../store/reducers/index';
import NotFoundPage from '../../../components/organisms/NotFoundPage';
import ProfileName from './ProfileEditor/ProfileName';
import ProfileForm from './ProfileEditor/ProfileForm';
import LoadingPage from '../../../components/organisms/LoadingPage';
import {cloneDeep, set} from 'lodash';
import {useSensorProfileStoreActions} from '../../../store/actions/index';
import ProfileDevice from './ProfileEditor/ProfileDevice';
import FormToolbar from './ProfileEditor/FormToolbar';
import {SensorProfileType} from '../../../store/model/sensorProfile';
import {spacing} from '@material-ui/system';
import OfflineDeviceAlert from '../../../components/molecules/OfflineDeviceAlert';
import {SensorProfileState} from '@common/api/models/devices/ISensorProfile';
import {Alert} from '@material-ui/lab';

const Divider = styled(MuiDivider)(spacing);

function SensorProfileEditor() {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const profile = useSelector((state: RootState) => state.sensorProfileStore.byId[selectedUuid]);
  const profileJSON = useSelector((state: RootState) => state.sensorProfileStore.profiles[selectedUuid]);
  const configDefaults = useSelector(
    (state: RootState) => state.sensorProfileStore.configDefaults[profile?.deviceSerial]
  );
  const device = useSelector((state: RootState) => state.deviceStore.byId[profile?.deviceSerial]);

  const [errors, setErrors] = useState<{
    [uuid: string]: {[key: string]: boolean};
  }>({});
  const [saving, setSaving] = useState<boolean>(false);

  const sensorProfileActions = useSensorProfileStoreActions();

  useEffect(() => {
    if (!configDefaults && profile?.deviceSerial) {
      sensorProfileActions.fetchConfigDefaults(profile.deviceSerial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configDefaults, profile?.deviceSerial]);

  function updateProfile(fieldPaths: string[], newValue: any, updatingProfile?: SensorProfileType) {
    const newProfile = cloneDeep(updatingProfile || profileJSON || {});

    fieldPaths.forEach((path) => set(newProfile, path, newValue));

    sensorProfileActions.setSensorProfile(selectedUuid, newProfile);
  }

  if (!profile && selectedUuid !== 'new')
    return (
      <Paper style={{padding: '24px', height: '100%'}}>
        <NotFoundPage
          notFoundText={selectedUuid ? 'Sensor Profile not found!' : 'Please select or create a profile.'}
          returnText={
            <>
              <Add /> New Profile
            </>
          }
          backToUrl="/machines/sensorProfiles/new"
        />
      </Paper>
    );

  if (!profile) return <></>;

  return (
    <Paper style={{padding: '24px'}}>
      <Grid container direction="column" spacing={2}>
        <ProfileName />
        <ProfileDevice />
        {!!device && profile?.state === SensorProfileState.INVALID && (
          <Alert severity="error" style={{marginBottom: '16px'}}>
            Sensor profile is invalid. Please view the{' '}
            <Link to={`/machines/uuid/${device.machineUuid}/device_logs/`}>device logs</Link> for more information.
          </Alert>
        )}
        {!!device && !device.online && (
          <OfflineDeviceAlert lastActivityTime={device.lastActivityTime} mt={0} mb={2}>
            Cannot update profile while device is offline.
          </OfflineDeviceAlert>
        )}
        {!configDefaults || !profileJSON ? (
          <Paper style={{padding: '24px', height: '100%'}}>
            {profile?.deviceSerial ? <LoadingPage loadingText="Loading profile..." /> : <></>}
          </Paper>
        ) : (
          <ProfileForm
            configDefaults={configDefaults}
            profileJSON={profileJSON}
            updateProfile={updateProfile}
            errors={errors[selectedUuid] || {}}
            readOnly={saving}
            setErrors={(newErrors) => setErrors({...errors, [selectedUuid]: newErrors})}
            uuid={selectedUuid}
          />
        )}
        <Divider my={4} />
        <FormToolbar
          hasError={Object.values(errors[selectedUuid] || {}).some((error) => !!error)}
          clearErrors={() => setErrors({})}
          saving={saving}
          setSaving={setSaving}
          deviceOffline={!device?.online}
        />
      </Grid>
    </Paper>
  );
}

export default SensorProfileEditor;
