import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {spacing} from '@material-ui/system';
import {CircularProgress} from '@material-ui/core';
import {Card as MuiCard, Divider as MuiDivider} from '@material-ui/core';
import {DeviceDetailedState} from '@common/api/models/devices/IDevice';
import {DeviceCalibrationInfo} from './CalibrationStep/DeviceCalibrationInfo';
import CalibrationDescription from './shared/CalibrationDescription';
import {RootState} from '../../../../store/reducers';
import {FetchingState} from '../../../../store/model/liveUpdateStore';
import {ActionButtons} from './CalibrationStep/ActionButtons';
import {StagingBuildDataProps} from '../';
import {useSmallScreenSize} from '../../../../utils/utilHooks';

const CalibrationCard = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

export const ActiveStepCALIBRATE = ({
  build,
  hasCalibrated,
  latestCalibration,
  waitingForCalibration,
  waitingForCalibrationToStop,
  setWaitingForCalibration,
  setWaitingForCalibrationToStop,
  isCurrentlyCalibrating,
  calibrationError,
  setCalibrationError,
}: StagingBuildDataProps) => {
  const isSmallScreen = useSmallScreenSize();

  const device = useSelector((state: RootState) => state.deviceStore.byMachineUuid[build.machineUuid!]);
  const machine = useSelector((s: RootState) => s.machineStore.byId[build.machineUuid!]);
  const calibrationsFetched = useSelector((state: RootState) => state.calibrationStore.fetched);

  const [internalState, setInternalState] = useState(device.stateDetailed);
  const deviceState = device?.state;
  const calibrationFetching = calibrationsFetched === FetchingState.Fetching;

  useEffect(() => {
    const currentState = device.stateDetailed;

    if (currentState === internalState) return;
    if (currentState === DeviceDetailedState.Staging && internalState === DeviceDetailedState.CalibratingStarting) {
      setCalibrationError(true);
    }
    setInternalState(currentState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device.stateDetailed, internalState]);

  if (!device) return <></>;

  return (
    <CalibrationCard p={isSmallScreen ? 4 : 6}>
      {calibrationFetching ? (
        <LoadingContainer isSmallScreen={isSmallScreen}>
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <>
          <CalibrationDescription
            calibration={latestCalibration}
            currentBuild={build}
            isCurrentlyCalibrating={isCurrentlyCalibrating}
            calibrationError={calibrationError}
          />

          <DeviceCalibrationInfo
            deviceStateDetailed={internalState}
            deviceState={deviceState}
            hasCalibrated={hasCalibrated}
            waitingForCalibration={waitingForCalibration}
            waitingForCalibrationToStop={waitingForCalibrationToStop}
          />

          <Divider mt={isSmallScreen ? 3 : 6} style={{height: '2px'}} />

          <ActionButtons
            deviceStateDetailed={internalState}
            deviceState={deviceState}
            build={build}
            machine={machine}
            hasCalibrated={hasCalibrated}
            calibrationAvailable={!!latestCalibration}
            setCalibrationError={setCalibrationError}
            waitingForCalibration={waitingForCalibration}
            setWaitingForCalibration={setWaitingForCalibration}
            waitingForCalibrationToStop={waitingForCalibrationToStop}
            setWaitingForCalibrationToStop={setWaitingForCalibrationToStop}
            isCurrentlyCalibrating={isCurrentlyCalibrating}
          />
        </>
      )}
    </CalibrationCard>
  );
};

const LoadingContainer = styled.div<{isSmallScreen: boolean}>`
  height: ${({isSmallScreen}) => (isSmallScreen ? '312px' : '624px')};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
