import {ILayerImage} from '@common/api/models/builds/data/ILayerImage';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function layerImageGET(filter: QueryFilter<ILayerImage>): AjaxApiResult<ILayerImage[]> {
  return ajaxWrapper(() => {
    return axios.get('/api/layerImages/', {params: filter});
  }, 'Could not fetch layer images');
}

export async function layerImageDownloadUrlGET(uuid: string): AjaxApiResult<string> {
  return ajaxWrapper(() => {
    return axios.get(`/api/layerImages/downloadUrl/`, {params: {uuid}});
  }, 'Could not download layer image');
}

export async function partialLayerNumbersGET(buildUuid: string): AjaxApiResult<number[]> {
  return ajaxWrapper(() => {
    return axios.get(`/api/layerImages/partialLayers/${buildUuid}`);
  }, 'Could not fetch partial layer numbers');
}
