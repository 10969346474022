import * as React from 'react';
import {useEffect, useState} from 'react';
import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {AppBar, Button, ButtonGroup, Grid, Tab, Tabs as MuiTabs, Tooltip, Typography, Fab} from '@material-ui/core';
import {Add, Help} from '@material-ui/icons';

import {useSelector} from 'react-redux';
import {Redirect, useHistory, useParams} from 'react-router-dom';
import styled from 'styled-components';

import BatchMaterialCompositionWidget from '../../../components/molecules/Dashboard/BatchMaterialCompositionWidget';
import BatchUsageCompositionWidget from '../../../components/molecules/Dashboard/BatchUsageCompositionWidget';
import BatchAddMaterialModal from '../../../components/molecules/Forms/BatchAddMaterialModal';
import BatchEventModal from '../../../components/molecules/Forms/BatchEventModal';
import BatchMergeModal from '../../../components/molecules/Forms/BatchMergeModal';
import BatchSplitModal from '../../../components/molecules/Forms/BatchSplitModal';
import {useBatchStoreActions} from '../../../store/actions';
import {RootState} from '../../../store/reducers';
import BuildsHistoryPage from './BuildsHistoryPage';
import GeneralDetailsPage from './GeneralDetailsPage';
import {BatchPageComponent} from './index';
import TimelinePage from './TimelinePage';
import {BaseTour} from '../../../components/molecules/BaseTour';
import Header from '../../../components/organisms/Header';
import LoadingPage from '../../../components/organisms/LoadingPage';

export interface BatchPage {
  id: string;
  title: string;
  component: BatchPageComponent;
}

interface BatchActionsProps {
  batch: IBatch;
  disabled: boolean;
}

const pages: BatchPage[] = [
  {
    id: 'general',
    title: 'General',
    component: GeneralDetailsPage,
  },
  {
    id: 'build',
    title: 'Build History',
    component: BuildsHistoryPage,
  },
  {
    id: 'timeline',
    title: 'Full Timeline',
    component: TimelinePage,
  },
];

export const CustomHelpIcon = styled(Help)`
  &:hover {
    &.MuiSvgIcon-fontSizeLarge {
      color: rgb(17, 82, 147);
    }
  }
`;

const Tabs = styled(MuiTabs)`
  background-color: ${(props) => props.theme.palette.background.paper};
  color: #000000;
`;

function BatchActions(props: BatchActionsProps) {
  const [mergeOpen, setMergeOpen] = useState(false);
  const [splitOpen, setSplitOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [otherOpen, setOtherOpen] = useState(false);
  const history = useHistory();

  return (
    <React.Fragment>
      <Tooltip title={props.disabled ? 'Batch Outdated' : ''} aria-label="Batch Outdated" placement="top">
        <ButtonGroup>
          <Button variant={'outlined'} color={'primary'} onClick={() => setMergeOpen(true)} disabled={props.disabled}>
            Merge
          </Button>
          <Button variant={'outlined'} color={'primary'} onClick={() => setSplitOpen(true)} disabled={props.disabled}>
            Split
          </Button>
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={() => setAddOpen(true)}
            disabled={props.disabled}
            startIcon={<Add />}
          >
            Material
          </Button>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => setOtherOpen(true)}
            disabled={props.disabled}
            startIcon={<Add />}
          >
            Action
          </Button>
        </ButtonGroup>
      </Tooltip>
      <BatchMergeModal
        open={mergeOpen}
        initialBatchAUuid={props.batch.uuid}
        initialBatchBUuid={undefined}
        onClose={() => setMergeOpen(false)}
        redirect={(uuid?: string) => history.push('/materials/batches/uuid/' + uuid)}
      />
      <BatchSplitModal open={splitOpen} initialBatchUuid={props.batch.uuid} onClose={() => setSplitOpen(false)} />
      <BatchAddMaterialModal open={addOpen} initialBatchUuid={props.batch.uuid} onClose={() => setAddOpen(false)} />
      <BatchEventModal open={otherOpen} initialBatchUuid={props.batch.uuid} onClose={() => setOtherOpen(false)} />
    </React.Fragment>
  );
}

export function ViewBatch() {
  const tutorialSteps = [
    {
      selector: '',
      content: "Let's start a tutorial about the Batches page and it's functionalities.",
      style: {
        padding: '20px 35px 20px 35px',
      },
      action: () => {
        if (!history.location.pathname.includes('timeline')) {
          handleTabChange({}, 2);
        }
      },
    },
    {
      selector: '.rt-batch-tab',
      content: () => {
        return (
          <div>
            <p>
              The Full Timeline tab depicts the history of the current batch. These events include: adding additional
              material, splitting the batch, sieving events, & more.
            </p>
          </div>
        );
      },
      action: () => {
        if (!history.location.pathname.includes('timeline')) {
          handleTabChange({}, 2);
        }
      },
      style: {
        padding: '20px 35px 20px 35px',
      },
    },
    {
      selector: '.rt-batch-tab',
      content: 'The General tab holds info about the current batch. You can edit details using the edit button.',
      action: () => {
        if (!history.location.pathname.includes('general')) {
          handleTabChange({}, 0);
        }
      },
      style: {
        padding: '20px 35px 20px 35px',
      },
    },
    {
      selector: '.rt-batch-tab',
      content: 'The Build History tab lists the builds that have used this particular batch.',
      action: () => {
        if (!history.location.pathname.includes('build')) {
          handleTabChange({}, 1);
        }
      },
      style: {
        padding: '20px 35px 20px 35px',
      },
    },
    {
      selector: '.rt-batch-actions',
      content:
        'These buttons are used to perform batch actions; such as, merge with another batch, split batch, add additional material, & more.',
      style: {
        padding: '20px 35px 20px 35px',
      },
    },
    {
      selector: '.rt-batch-material-comp',
      content:
        'The Material Composition widget depicts the amount of various materials used to create the current batch.',
      style: {
        padding: '20px 35px 20px 35px',
      },
    },
    {
      selector: '.rt-batch-condition-comp',
      content: 'The Condition Composition widget depicts the amount of recycled material within the current batch.',
      style: {
        padding: '20px 35px 20px 35px',
      },
    },
  ];
  const history = useHistory();
  const batchStoreActions = useBatchStoreActions();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const {uuid, page} = useParams<{uuid: string; page: string}>();
  const batch = useSelector((state: RootState) => (uuid ? (state.batchStore.byId[uuid] as IBatch) : undefined));

  const activePageIndex = pages.findIndex((p) => p.id === page);
  const activePage = pages[activePageIndex];

  useEffect(() => {
    if (uuid) {
      batchStoreActions.ensureConsistent({uuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const handleTabChange = (e: any, newIndex: number) => {
    history.push('/materials/batches/uuid/' + uuid + '/' + pages[newIndex].id + '/');
  };

  if (!batch) {
    return <LoadingPage loadingText="Loading Batch" />;
  }

  if (!activePage) {
    return <Redirect to={'/materials/batches/uuid/' + uuid + '/general/'} />;
  }

  const isOutdated = !batch.current;

  return (
    <React.Fragment>
      <Header
        helmet={`${batch.name} Batch`}
        title={
          <Typography variant="h4">
            Viewing Batch: "{batch.name}"{isOutdated && <i> (Outdated)</i>}
            {batch.retired && <i> (Retired)</i>}
          </Typography>
        }
        breadcrumbs={[{title: 'Batches', path: '/materials/batches/'}, batch.name]}
        endAdornment={
          <Grid item className="rt-batch-actions">
            <BatchActions batch={batch} disabled={isOutdated} />
          </Grid>
        }
      />
      <BaseTour steps={tutorialSteps} isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen} />
      <div
        style={{
          position: 'fixed',
          right: '10px',
          bottom: '65px',
          display: 'inline-block',
          zIndex: 1000,
        }}
      >
        <Fab
          aria-label="help"
          component="span"
          onClick={() => {
            setIsTourOpen(true);
          }}
          style={{
            boxShadow: 'none',
            backgroundColor: 'rgb(247,249,252)',
          }}
        >
          <CustomHelpIcon fontSize="large" color="primary" />
        </Fab>
      </div>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <BatchMaterialCompositionWidget batch={batch} />
            </Grid>
            <Grid item xs={12}>
              <BatchUsageCompositionWidget batch={batch} />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={8}>
          <div className="rt-batch-tab">
            <AppBar position="static" color="secondary">
              <Tabs
                value={activePageIndex}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                aria-label="full width tabs example"
              >
                {pages.map((p) => {
                  return <Tab id={'tab-' + p.id} label={p.title} />;
                })}
              </Tabs>
            </AppBar>

            <Grid item xs={12}>
              {<activePage.component batch={batch} />}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
