import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Box} from '@material-ui/core';
import SensorProfileEditor from './sensorProfiles/SensorProfileEditor';
import SensorProfileList from './sensorProfiles/SensorProfilesList';
import SensorProfileBottomToolbar from './sensorProfiles/SensorProfileBottomToolbar';
import {useSmallScreenSize} from '../../utils/utilHooks';
import {useSensorProfileStoreActions} from '../../store/actions/index';
import {RootState} from '../../store/reducers/index';
import {useSelector} from 'react-redux';
import {FetchingState} from '../../store/model/liveUpdateStore';
import LoadingPage from '../../components/organisms/LoadingPage';
import NewProfileButton from './sensorProfiles/NewProfileButton';
import Header from '../../components/organisms/Header';

const alertUser = (e: any) => {
  e.preventDefault();
  e.returnValue = '';
};

function SensorProfilesPage() {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const sensorProfileActions = useSensorProfileStoreActions();
  const {uuid: selectedUuid, deviceSerial: initialDeviceSerial} = useParams<{
    uuid: string;
    deviceSerial: string;
  }>();
  const isFetchingProfiles = useSelector(
    (state: RootState) => state.sensorProfileStore.fetched === FetchingState.Fetching
  );
  const newProfileExists = useSelector((state: RootState) => !!state.sensorProfileStore.byId['new']);
  const firstProfile = useSelector((state: RootState) => Object.values(state.sensorProfileStore.byId)?.[0]);

  useEffect(() => {
    sensorProfileActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedUuid && firstProfile) {
      history.replace(`/machines/sensorProfiles/${firstProfile.uuid}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUuid, firstProfile]);

  useEffect(() => {
    if (newProfileExists) {
      window.addEventListener('beforeunload', alertUser);
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [newProfileExists]);

  useEffect(() => {
    if (!!selectedUuid && selectedUuid !== 'new') {
      sensorProfileActions.loadSensorProfile(selectedUuid);
    } else if (selectedUuid === 'new' && !newProfileExists) {
      sensorProfileActions.saveProfileName('new', 'New Profile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUuid]);

  useEffect(() => {
    if (selectedUuid === 'new' && newProfileExists && !!initialDeviceSerial) {
      sensorProfileActions.setDevice('new', initialDeviceSerial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUuid, newProfileExists]);

  if (isFetchingProfiles) return <LoadingPage loadingText="Loading Sensor Profiles..." />;

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        helmet="Sensor Profiles"
        title="Sensor Profiles"
        breadcrumbs={['Sensor Profiles']}
        endAdornment={isSmallScreen ? undefined : <NewProfileButton />}
      />

      <Box display="flex" flex={1}>
        {!isSmallScreen && (
          <Box height="75vh" maxHeight="75vh" width="400px" marginRight="24px">
            <SensorProfileList />
          </Box>
        )}

        <Box height="100%" width={isSmallScreen ? '100%' : 'calc(100% - 400px - 24px)'}>
          <SensorProfileEditor />
        </Box>
      </Box>
      {isSmallScreen && <SensorProfileBottomToolbar />}
    </Box>
  );
}

export default SensorProfilesPage;
