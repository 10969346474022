import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {isEqual} from 'lodash';
import {Box, Button, CircularProgress} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {SliceState} from '@common/api/models/attachments/ISliceAttachment';
import {IBuild} from '@common/api/models/builds/IBuild';
import {DeviceState, IDevice} from '@common/api/models/devices/IDevice';
import {RootState} from '../../../../../store/reducers/index';
import {GenericDialog} from '../../../../../components/molecules/DialogButton';
import {buildStopGET, monitorBuildGET} from '../../../../../api/ajax/builds';
import {useSmallScreenSize} from '../../../../../utils/utilHooks';
import ConditionalTooltip from '../../../../../components/atoms/Texts/ConditionalTooltip';
import {useMachineStatusText} from '../../../draftBuild/StepNavigationButtons';
import {useCameraState} from '../../shared/CameraState';

const OverviewActions = ({
  build,
  device,
  calibrationAvailable,
  isCurrentlyCalibrating,
  isCurrentlyFocusing,
  focusFailed,
  failedCalibration,
}: {
  build: IBuild;
  device: IDevice;
  calibrationAvailable: boolean;
  isCurrentlyCalibrating: boolean;
  isCurrentlyFocusing: boolean;
  focusFailed: boolean;
  failedCalibration: boolean;
}) => {
  const {allCamerasConnected} = useCameraState(device.serial);
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const [accepting, setAccepting] = useState(false);
  const [noSliceModalOpen, setNoSliceModalOpen] = useState(false);
  const machineStatusText = useMachineStatusText(build);

  const buttonStyle = isSmallScreen ? {marginTop: '12px'} : {marginLeft: '12px'};

  const sliceFiles = useSelector(
    (state: RootState) =>
      Object.values(state.sliceAttachmentStore.byId).filter((slice) => slice.buildUuid === build.uuid),
    isEqual
  );

  const validSlices = sliceFiles
    .filter((slice) => !slice.deletedAt)
    .filter((slice) => slice.state !== SliceState.INVALID);

  const acceptAndMonitor = async (force: boolean) => {
    if (!force && validSlices.length === 0) {
      setNoSliceModalOpen(true);
      return;
    }

    setNoSliceModalOpen(false);
    setAccepting(true);

    if (device && (device.state === DeviceState.CALIBRATING || device.state === DeviceState.PREVIEWING)) {
      await buildStopGET(build.uuid);
    }

    const res = await monitorBuildGET(build.uuid);

    setAccepting(false);
    if (res.success) {
      localStorage.setItem(`${build.uuid}-staging-complete`, String(Date.now()));
      toast('Monitor request sent. Waiting for device...', {type: 'success'});
      history.push(`/builds/uuid/${build.uuid}`);
    }
  };

  // Disabled if one of the below is true:
  // - Device is offline
  // - User doesn't have machine permissions for device
  // - No calibration available
  // - Calibration failed (user can select to use most recent calibration)
  // - Calibration in progress
  // - Focus in progress
  // - Focus failed
  const disabledButtonTooltip = machineStatusText
    ? machineStatusText
    : !calibrationAvailable
    ? 'No calibration result available. Please calibrate the build before proceeding.'
    : isCurrentlyCalibrating
    ? 'Calibration in progress, please wait for calibration to finish or stop the calibration operation.'
    : failedCalibration
    ? 'The most recent calibration failed. Please re-run calibration or use the last successful calibration.'
    : isCurrentlyFocusing
    ? 'Focusing in progress, please wait for focus to finish or stop the focus operation.'
    : !allCamerasConnected
    ? 'One or more cameras are disconnected. Please ensure all cameras are correctly connected to the device before starting to monitor.'
    : '';

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end" width="100%" mt={isSmallScreen ? 1 : 4}>
      {focusFailed ? (
        <Alert severity="warning" style={{width: '100%', marginBottom: '12px'}}>
          Some cameras failed to focus. You can still proceed to monitoring, though it is recommended to retry to
          auto-focus operation or manually focus the cameras.
        </Alert>
      ) : null}
      <ConditionalTooltip tooltip={disabledButtonTooltip} hideTooltip={!disabledButtonTooltip}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => acceptAndMonitor(false)}
          disabled={!!disabledButtonTooltip || accepting || !allCamerasConnected}
          style={buttonStyle}
          fullWidth={isSmallScreen}
          size={isSmallScreen ? 'small' : 'medium'}
        >
          Accept & Start Monitoring {accepting && <CircularProgress style={{marginLeft: '12px'}} size={20} />}
        </Button>
      </ConditionalTooltip>
      <GenericDialog
        title="No Slice Files Uploaded"
        isOpen={noSliceModalOpen}
        closeDialog={() => {
          setNoSliceModalOpen(false);
        }}
        content="This build currently has no slice files attached to it. We recommend that you upload these before proceeding to the next stage."
        closeText="Go Back & Upload"
        confirmText="Skip Upload & Continue"
        onSuccess={() => acceptAndMonitor(true)}
        maxWidth="xl"
      />
    </Box>
  );
};

export default OverviewActions;
