import React, {useCallback, useMemo, useState} from 'react';
import {Box, Tooltip, Typography} from '@material-ui/core';
import {
  CenterFocusStrong,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  Launch,
} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';

import {FullScreen} from 'react-full-screen';
import {useSelector} from 'react-redux';
import {CircularProgress} from '@material-ui/core';

import {AnalysisType2D} from '@common/api/models/builds/data/defects/IDefect';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';

import ImageDisplay from './ImageDisplay';
import {ViewportControl} from './controls';
import {Scale} from './controls/Scale';
import {DistortionScale} from './controls/DistortionScale';
import {BUTTON_HEIGHT, BUTTON_MARGIN, BUTTON_WIDTH, getCalibrationData} from './utils';
import {ViewportDefect} from '../../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {ImageOverlaysParams} from '../2D/analysisType';
import {BlankDiv, FadingDiv, ViewerButton} from '../FadingComponents';
import {GetPositionFn, OnPositionChangeFn, SetPositionFn, useViewportStage} from './viewportHooks';
import PartSelector from './controls/PartSelector';
import {RootState} from '../../../../store/reducers/index';
import {toast} from 'react-toastify';
import {Vector2d} from 'konva/types/types';

export interface ImageOverlays extends ImageOverlaysParams {
  image: HTMLImageElement | undefined;
  id: AnalysisType2D;
  uuid?: string;
}

export interface HoveredMmAxisTransforms {
  x: 'x' | 'y' | 'z';
  y: 'x' | 'y' | 'z';
}
export interface SingleImageViewportProps {
  isLoading?: boolean;
  stopLoading?: () => void;
  imageWidthPixels: number;
  imageHeightPixels: number;
  currentLayer?: number;

  overlays: ImageOverlays[];

  parentGridColumns?: number; // this is needed to trigger resizes when adding/removing views
  mmPerPixel: number;
  height?: number;

  imageWidth?: number;

  isDefectViewport?: boolean;
  isLocationBasedDefectsViewport?: boolean;
  showHoveredMmPosition?: boolean;
  hoveredMmAxisTransforms?: HoveredMmAxisTransforms;
  hoveredPosition?: {x?: number; y?: number} | null;
  dataPointValue?: string;
  setHoveredPosition?: (hoveredPosition: {x?: number; y?: number; z?: number} | null) => void;
  plateBoundingBoxMM?: number[];
  defectData?: ViewportDefect | undefined;

  getInitialPosition?: GetPositionFn;
  onChangeDims?: (width: number, height: number) => any;
  controls?: ViewportControl[];
  onPositionChange: OnPositionChangeFn;

  setPositionFnRef?: React.MutableRefObject<SetPositionFn | undefined>;

  fitToScreenOnStart?: boolean;
  // @ts-ignore
  stageRef?: (stage: Stage | null) => void;
  buildUuid?: string;
  isFitToWrapper?: boolean;
  alone2d?: boolean;
  showBoundingBoxes?: boolean;
  onPartHover?: (part: IPartGETResponse | null) => void;
  hoveredPart?: IPartGETResponse | null;

  // Like `children` but elements fade in/out on mouseover
  fadingChildren?: JSX.Element;
  sidebarOpen?: boolean;
  setSidebarOpen?: (open: boolean) => void;

  mouseOn?: boolean;
  setMouseOn?: (mouseOn: boolean) => void;

  imageNotFound?: boolean;
  brightness?: number;
}

const SingleImageViewport: React.FunctionComponent<SingleImageViewportProps> = (props) => {
  const {
    setContainer,
    onStageRef,
    stage,

    fullScreen,
    fullScreenHandle,
    setFullScreen,

    stageHeight,
    stageWidth,

    viewportPosition,
    setViewportPosition,
    setZoomScaleOverride,

    fitToView,
    setIsViewportFit,

    mouseOn,
    setMouseOn,

    previousOverlays,
    availableAnalysisTypes,

    parts,
  } = useViewportStage(props);

  const history = useHistory();
  const [hoveredSidebarPart, setHoveredSidebarPart] = useState<IPartGETResponse | null>(null);
  const [hoveredMmPosition, setHoveredMmPosition] = useState<{x: number; y: number} | null>(null);

  const widthMM = props.imageWidthPixels * props.mmPerPixel;
  const heightMM = props.imageHeightPixels * props.mmPerPixel;
  const build = useSelector((state: RootState) =>
    props.buildUuid ? state.buildStore.byId[props.buildUuid] : undefined
  );
  const calibrationStore = useSelector((state: RootState) => state.calibrationStore);

  const {plateBoundingBox, calibrationResolution} = useMemo(
    () =>
      !!build
        ? getCalibrationData(calibrationStore, build.calibrationUuid)
        : {plateBoundingBox: undefined, calibrationResolution: undefined},
    [calibrationStore, build]
  );

  const redirectToViewport = () => {
    if (props.buildUuid && props.defectData) {
      history.push(
        '/builds/uuid/' +
          props.buildUuid +
          '/live/viewports/?layerNum=' +
          props.defectData?.layerNum +
          '&analysisType=' +
          props.defectData.analysisType
      );
    }
  };

  const setFitToView = () => {
    fitToView(true);
    setIsViewportFit(true);
    setViewportPosition((p) => ({...p, zoom: 1}));
  };

  const toggleFullScreen = () => {
    try {
      fullScreen ? fullScreenHandle.exit() : fullScreenHandle.enter();
    } catch (e) {
      toast('Fullscreen is unavailable on this device', {type: 'info'});
    }
  };

  const handleHover = useCallback(
    (pointerPosition: Vector2d | null) => {
      let xPos: number | null = null;
      let yPos: number | null = null;
      let yPosMM: number | null = null;
      const heightMM = props.imageHeightPixels * props.mmPerPixel;

      if (pointerPosition) {
        xPos = pointerPosition!.x - (props.plateBoundingBoxMM?.[0] || 0);
        if (xPos < 0 || (props.plateBoundingBoxMM && pointerPosition!.x > props.plateBoundingBoxMM[2])) xPos = null;

        const trueY = heightMM - pointerPosition!.y;
        yPos = pointerPosition.y - (props.plateBoundingBoxMM?.[1] || 0);
        yPosMM = yPos;

        if (props.plateBoundingBoxMM) {
          const plateHeight = props.plateBoundingBoxMM[3] - props.plateBoundingBoxMM[1];
          yPosMM = plateHeight - (trueY - (props.plateBoundingBoxMM?.[1] || 0));

          if (trueY < props.plateBoundingBoxMM[1] || trueY > props.plateBoundingBoxMM[3]) {
            yPos = null;
            yPosMM = null;
          }
        }
      }

      if (xPos === null || yPosMM === null) {
        setHoveredMmPosition(null);
      } else {
        setHoveredMmPosition({x: xPos, y: yPosMM});
      }
      props.setHoveredPosition?.(
        pointerPosition
          ? {
              [props.hoveredMmAxisTransforms?.y || 'y']: yPos,
              [props.hoveredMmAxisTransforms?.x || 'x']: xPos,
            }
          : null
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.plateBoundingBoxMM, props.hoveredMmAxisTransforms]
  );

  const isMouseOn = props.mouseOn ?? mouseOn;
  const setIsMouseOn = props.setMouseOn ?? setMouseOn;

  const hoveredY = props.hoveredPosition?.y;
  const hoveredX = props.hoveredPosition?.x;
  const hoveredPositionTransformedToBuildPlate =
    props.hoveredPosition && props.plateBoundingBoxMM
      ? {
          x: hoveredY && hoveredX ? hoveredX + props.plateBoundingBoxMM[0] : undefined,
          y: hoveredY && hoveredX ? hoveredY + props.plateBoundingBoxMM[1] : undefined,
        }
      : props.hoveredPosition;

  const hidePointer = !!(
    hoveredPositionTransformedToBuildPlate &&
    hoveredPositionTransformedToBuildPlate.x &&
    hoveredPositionTransformedToBuildPlate.x > 0 &&
    hoveredPositionTransformedToBuildPlate.y &&
    hoveredPositionTransformedToBuildPlate.y > 0
  );

  return (
    <FullScreen
      handle={fullScreenHandle}
      onChange={(isFull) => {
        setFullScreen(isFull);
      }}
    >
      <BlankDiv
        ref={(node: HTMLDivElement) => {
          setContainer(node);
        }}
        style={{
          height: stageHeight,
        }}
        onMouseEnter={() => setIsMouseOn(true)}
        onMouseMove={() => {
          if (!isMouseOn) setIsMouseOn(true);
        }}
        onMouseLeave={() => setIsMouseOn(false)}
        hidePointer={hidePointer}
        fullscreen={fullScreen}
        brightness={props.brightness}
      >
        <div
          style={{
            position: 'relative',
            height: props.isFitToWrapper ? '100%' : undefined,
          }}
        >
          <ImageDisplay
            isFitToWrapper={props.isFitToWrapper}
            isDefectViewport={props.isDefectViewport}
            overlays={
              props?.imageNotFound ||
              (props.overlays.length > 0 && props.overlays.some((overlay) => overlay.image?.complete))
                ? props.overlays
                : previousOverlays
            }
            width={stageWidth}
            imagePixelWidth={props.imageWidthPixels}
            imagePixelHeight={props.imageHeightPixels}
            height={stageHeight}
            stageRef={onStageRef}
            mmPerPixel={props.mmPerPixel}
            setViewportFit={setIsViewportFit}
            zoomScale={viewportPosition.zoom}
            setZoomScale={setZoomScaleOverride}
            positionScale={viewportPosition.scale}
            parts={parts}
            buildUuid={props.buildUuid}
            currentLayer={props.currentLayer}
            showBoundingBoxes={props.showBoundingBoxes}
            onPartHover={props.onPartHover}
            hoveredSidebarPart={hoveredSidebarPart}
            handleHover={props.showHoveredMmPosition ? handleHover : undefined}
            hoveredPosition={hoveredPositionTransformedToBuildPlate}
          />

          {!!props.setSidebarOpen && props.sidebarOpen !== undefined && (
            <PartSelector
              stageHeight={stageHeight}
              parts={parts}
              onPartHover={setHoveredSidebarPart}
              hoveredViewportPart={props.hoveredPart}
              sidebarOpen={props.sidebarOpen}
              setSidebarOpen={props.setSidebarOpen}
              currentLayer={props.currentLayer}
              imageWidthMM={widthMM}
              imageHeightMM={heightMM}
              plateBoundingBox={plateBoundingBox}
              calibrationResolution={calibrationResolution}
              scale={props.mmPerPixel}
              stage={stage}
              setIsViewportFit={setIsViewportFit}
              setFitToView={setFitToView}
            />
          )}

          {hoveredMmPosition && hoveredMmPosition.x >= 0 && hoveredMmPosition.y >= 0 && (
            <FadingDiv className={(!isMouseOn && 'fade-half') || undefined}>
              <Box
                position="absolute"
                top={props.isLocationBasedDefectsViewport ? '6px' : BUTTON_MARGIN}
                left={props.isLocationBasedDefectsViewport ? '6px' : 120 + BUTTON_MARGIN * 3}
              >
                <Typography style={{color: 'orange'}}>
                  {props.hoveredMmAxisTransforms?.x || 'x'}: {hoveredMmPosition!.x.toFixed(1)} mm
                </Typography>
                <Typography style={{color: 'orange'}}>
                  {props.hoveredMmAxisTransforms?.y || 'y'}: {hoveredMmPosition!.y.toFixed(1)} mm
                </Typography>
                {props.dataPointValue && (
                  <Typography style={{color: 'orange'}}>Value: {props.dataPointValue}</Typography>
                )}
              </Box>
            </FadingDiv>
          )}

          <FadingDiv className={(!isMouseOn && 'fade-half') || undefined}>
            <Tooltip title={fullScreen ? 'Exit Fullscreen' : 'Fullscreen'} placement="left">
              <ViewerButton
                onClick={toggleFullScreen}
                style={{
                  right: BUTTON_MARGIN,
                  top: stageHeight - BUTTON_WIDTH,
                  position: 'absolute',
                }}
              >
                {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </ViewerButton>
            </Tooltip>
          </FadingDiv>
          <FadingDiv className={(!isMouseOn && 'fade') || undefined}>
            <Tooltip title="Fit View to Screen" placement="left">
              <ViewerButton
                onClick={setFitToView}
                style={{
                  right: BUTTON_MARGIN,
                  top: stageHeight - BUTTON_HEIGHT * 2,
                  position: 'absolute',
                }}
              >
                <CenterFocusStrong />
              </ViewerButton>
            </Tooltip>
          </FadingDiv>
          {props.isDefectViewport && (
            <FadingDiv className={(!isMouseOn && 'fade') || undefined}>
              <Tooltip title="Open in viewport" placement="left">
                <ViewerButton
                  onClick={() => redirectToViewport()}
                  style={{
                    right: BUTTON_MARGIN,
                    top: stageHeight - BUTTON_HEIGHT * 3,
                    position: 'absolute',
                  }}
                >
                  <Launch />
                </ViewerButton>
              </Tooltip>
            </FadingDiv>
          )}
          {props.isLoading ? (
            <div>
              <div
                style={{
                  position: 'absolute',
                  width: stageWidth,
                  height: stageHeight,
                  backgroundColor: 'black',
                  opacity: 0.3,
                  pointerEvents: 'none',
                }}
              />
              <CircularProgress
                size={30}
                style={{
                  position: 'absolute',
                  top: stageHeight / 2,
                  left: stageWidth / 2,
                }}
              />
            </div>
          ) : null}
          {props.overlays.length > 0 && props.overlays[0].image && (
            <FadingDiv className={(!isMouseOn && 'fade-half') || undefined}>
              <Scale
                stageWidth={stageWidth}
                stageHeight={stageHeight}
                stageScale={viewportPosition.scale}
                imageWidth={props.imageWidth || props.imageWidthPixels}
              />
              {availableAnalysisTypes.includes(AnalysisType2D.EdgeDistortion) && (
                <DistortionScale
                  stageWidth={stageWidth}
                  mmPerPixel={props.mmPerPixel}
                  // TODO: these values are hard-coded here and in analyis, they shouldn't be
                  // https://app.asana.com/0/1135560298718334/1204782815258134/f
                  minDistortion={20}
                  maxDistortion={20}
                />
              )}
            </FadingDiv>
          )}
          {props.controls &&
            props.controls.map((Component) => {
              return <Component stageWidth={stageWidth} stageHeight={stageHeight} />;
            })}
          {props.children}
          <FadingDiv className={(!isMouseOn && 'fade') || undefined}>{props.fadingChildren}</FadingDiv>
        </div>
      </BlankDiv>
    </FullScreen>
  );
};

export default SingleImageViewport;
