import React, {useEffect} from 'react';
import {Card, CardContent, CardHeader, Grid} from '@material-ui/core';

import {useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import styled from 'styled-components';

import {Role} from '@common/api/models/users/IUser';
import {BuildState} from '@common/api/models/builds/IBuild';
import {BuildAttachmentType} from '@common/api/models/attachments/IAttachmentBase';

import {useBuildAttachmentStoreActions, useBuildStoreActions, useDeviceStoreActions} from '../../store/actions';
import {RootState} from '../../store/reducers';
import {filterBuildPages} from './liveBuild/ActiveBuild';
import ActiveBuildBottomToolbar from './liveBuild/activeBuildPages/ActiveBuildBottomToolbar';
import StagingBuildBottomToolbar from './liveBuild/stagingBuildSteps/StagingBuildBottomToolbar';
import {useSmallScreenSize} from '../../utils/utilHooks';
import BuildProfileConfig, {DefaultCameraMode} from './buildSettings/BuildProfileConfig';
import BuildManualActions from './buildSettings/BuildManualActions';
import Header from '../../components/organisms/Header';
import BuildHeader from './shared/BuildHeader';
import BuildPhotoAvatar from '../../components/atoms/BuildPhotoAvatar';
import LoadingPage from '../../components/organisms/LoadingPage';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function BuildSettings() {
  const {uuid} = useParams<{uuid: string}>();
  const query = useQuery();
  const isSmallScreen = useSmallScreenSize();
  const auth = useSelector((state: RootState) => state.auth);

  const buildActions = useBuildStoreActions();
  const deviceActions = useDeviceStoreActions();

  const build = useSelector((state: RootState) => state.buildStore.byId[uuid!]);
  const deviceSerial = build?.deviceSerial;

  const buildAttachmentStoreActions = useBuildAttachmentStoreActions();
  const buildPhotoMain = useSelector((s: RootState) =>
    Object.values(s.buildAttachmentStore.byId).find(
      (attachment) => attachment.buildUuid === build.uuid && attachment.type === BuildAttachmentType.BuildPhotoMain
    )
  );

  useEffect(() => {
    if (build) {
      buildAttachmentStoreActions.ensureConsistent({
        buildUuid: build.uuid,
        type: BuildAttachmentType.BuildPhotoMain,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build?.uuid, build?.state]);

  useEffect(() => {
    if (uuid) buildActions.ensureConsistent({uuid});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deviceSerial) deviceActions.ensureConsistent({serial: deviceSerial});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceSerial]);

  if (!build) return <LoadingPage loadingText="Loading Build Settings" />;

  const pages = filterBuildPages(build);

  return (
    <BuildSettingsWrapper>
      <Header
        helmet={`Build Settings - ${build.name}`}
        title={<BuildHeader build={build} buildNameSuffix="- Settings" />}
        breadcrumbs={[
          {title: 'Builds', path: '/builds/'},
          {title: build.name, path: `/builds/uuid/${uuid}/`},
          'Settings',
        ]}
        startAdornment={
          <BuildPhotoAvatar signedUrl={buildPhotoMain?.signedUrl} pxSize={38} imgStyle={{marginRight: '12px'}} />
        }
        startAdornmentWidth="50px"
      />
      <Grid container spacing={3}>
        <Grid item md={12} lg={6} xl={5}>
          <Card>
            <CardHeader title={'Build Settings'} />
            <CardContent>
              {auth.user?.role && auth.user.role >= Role.TECHNICIAN ? (
                <BuildManualActions build={build} />
              ) : (
                <p>No settings to configure</p>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={7}>
          <Grid container spacing={3} direction="column">
            <Grid item style={{maxWidth: '100%'}}>
              <BuildProfileConfig defaultCameraMode={query.get('defaultCameraMode') as DefaultCameraMode} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isSmallScreen ? (
        <>
          {![BuildState.DRAFT, BuildState.STAGING].includes(build.state) && <ActiveBuildBottomToolbar pages={pages} />}
          {build.state === BuildState.STAGING && <StagingBuildBottomToolbar />}
        </>
      ) : (
        <></>
      )}
    </BuildSettingsWrapper>
  );
}
const BuildSettingsWrapper = styled.div`
  .alerts-table-paper {
    padding: 0px !important;
  }
`;
