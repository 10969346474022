import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Box} from '@material-ui/core';

import {RootState} from '../../store/reducers/index';
import {FetchingState} from '../../store/model/liveUpdateStore';
import {useSimilarityReportStoreActions} from '../../store/actions/index';
import SimilarityResultsHeader from './results/SimilarityResultsHeader';
import ResultsContent from './results/ResultsContent';
import AddMoreTargetPartsScreen from './results/AddMoreTargetPartsScreen';
import GeneratingInfoBar from './results/GeneratingInfoBar';
import SourceToSourceWarningMessage from './results/SourceToSourceWarningMessage';
import {useSmallScreenSize} from '../../utils/utilHooks';
import SimilarityResultsBottomToolbar from './results/SimilarityResultsBottomToolbar';
import LoadingPage from '../../components/organisms/LoadingPage';
import NotFoundPage from '../../components/organisms/NotFoundPage';

const SimilarityResultsPage = () => {
  const isSmallScreen = useSmallScreenSize();
  const [isViewportFullScreen, setIsViewportFullScreen] = useState(false);
  const {uuid, step} = useParams<{uuid: string; step?: string}>();

  const currentReportUuid = useSelector(
    (state: RootState) => state.similarityReportStore.currentSimilarityReport?.uuid
  );
  const fetchingState = useSelector((state: RootState) => state.similarityReportStore.currentReportFetching);

  const {fetchSimilarityReport} = useSimilarityReportStoreActions();

  useEffect(() => {
    fetchSimilarityReport(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  if (fetchingState === FetchingState.Fetching) return <LoadingPage loadingText="Loading Similarity Report..." />;

  if (!currentReportUuid || currentReportUuid !== uuid)
    return (
      <NotFoundPage
        notFoundText="Report not found!"
        backToUrl="/reports/similarity"
        returnText="Return to Similarity Reports"
      />
    );

  return (
    <>
      <SimilarityResultsHeader uuid={uuid} />
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100% - 56px)"
        style={isViewportFullScreen ? {overflow: 'hidden', maxHeight: '0px'} : {}}
      >
        {step === 'addMoreTargetParts' ? (
          <AddMoreTargetPartsScreen uuid={uuid} isViewportFullScreen={isViewportFullScreen} />
        ) : (
          <Box height="100%">
            <GeneratingInfoBar />
            <SourceToSourceWarningMessage />
            <ResultsContent isViewportFullScreen={isViewportFullScreen} />
          </Box>
        )}
        {isSmallScreen && (
          <SimilarityResultsBottomToolbar
            toggleViewportFullscreen={() => {
              window.scrollTo(0, 0);
              setIsViewportFullScreen(!isViewportFullScreen);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default SimilarityResultsPage;
