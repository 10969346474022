import {CircularProgress, Tooltip, Typography} from '@material-ui/core';
import {Add, Close} from '@material-ui/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';

import {RootState} from '../../../../store/reducers';
import {objMapValues} from '../../../../utils/objectFunctions';
import ViewSelector from '../controls/ViewSelector';
import {ViewerButton} from '../FadingComponents';
import {BUTTON_MARGIN, BUTTON_WIDTH} from '../2D/utils';
import Base3DViewport, {BoundingBox, initialParams, ViewportParams} from './Base3DViewport';
import {usePointCloudEffects} from './pointCloudHooks/usePointCloudEffects';
import {usePartPointClouds} from './pointCloudHooks/usePartPointClouds';
import {initialPointCloudParams, PointCloudViewportParams} from './pointCloudLoader';
import {View3DViewportSidebar} from './Sidebar/View3DViewportSidebar';
import {AnalysisTypeMap} from './types';
import {toggleAnalysisTypeVisibility} from './viewportFunctions';
import {showOverlayMessage, ViewportMessage} from './ViewportMessage';
import {MinMax} from '../../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {ViewportProps} from '../viewportProps';
import {LayerImages} from '../../../../pages/builds/liveBuild/activeBuildPages/ViewportsPage';
import {useLayerImagePlane} from './pointCloudHooks/useLayerImagePlane';

export interface View3DViewportProps extends ViewportProps {
  initialPartUuid?: string;
  initialAnalysisType?: string;
  fetchLayerData?: (layerNum: number) => void;
  loadedLayers: LayerImages;
}

export interface View3DViewportParams extends ViewportParams, PointCloudViewportParams {
  availableParts: IPartGETResponse[];
  selectedParts: IPartGETResponse[];
  hoveredPartUuid?: string;

  availableAnalysisTypes: AnalysisTypeMap<boolean>;
  selectedAnalysisTypes: AnalysisTypeMap<boolean>;

  pointSizeCutoff?: {min?: number; max?: number};
  analysisAmountCutoff?: {min?: number; max?: number};
  analysisTypeSizes?: AnalysisTypeMap<MinMax<number>>;
  isSimilarity?: boolean;
  rotation?: {[uuid: string]: number};
  sourceGeometryEnabled?: boolean;
  targetGeometryEnabled?: boolean;
  comparisonScaling?: boolean;
  centerAllParts?: boolean;
}

export const initialView3DViewportParams: View3DViewportParams = {
  ...initialParams,
  ...initialPointCloudParams,

  availableParts: [],
  selectedParts: [],

  availableAnalysisTypes: objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>,
  selectedAnalysisTypes: {
    ...(objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>),
    [AnalysisType3D.Model]: true,
  },
};

export default function View3DViewport(props: View3DViewportProps) {
  // Initialise state
  const [params, setParams] = useState<View3DViewportParams>({
    ...initialView3DViewportParams,
    selectedAnalysisTypes: {
      ...initialView3DViewportParams.selectedAnalysisTypes,
      ...(props.initialAnalysisType ? {[props.initialAnalysisType]: true} : {}),
    },
  });

  const rerenderRef = React.useRef(() => {});
  // Function to force a rerender of the 3D viewport
  const renderScene = rerenderRef.current;

  const {viewportState, pointClouds, pointCloudData, sceneBounds, availableAnalysisTypes} = usePartPointClouds(
    params.selectedParts.map((part) => part.uuid),
    params.selectedAnalysisTypes,
    params,
    renderScene
  );

  const partStore = useSelector((state: RootState) => state.partStore);

  const minLayerNum = Math.min(...params.selectedParts.map((part) => part.layerStart || 1));
  const maxLayerNum = Math.max(...params.selectedParts.map((part) => part.layerEnd || 0));
  const numLayers = maxLayerNum - minLayerNum + 1;

  // Viewport settings useEffects

  useEffect(() => {
    // Update params if available analysis types changes
    setParams((params) => ({
      ...params,
      availableAnalysisTypes,
      // Select model analysis type if none are selected
      selectedAnalysisTypes: Object.values(params.selectedAnalysisTypes).some((enabled) => enabled)
        ? params.selectedAnalysisTypes
        : initialView3DViewportParams.selectedAnalysisTypes,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAnalysisTypes]);

  useEffect(() => {
    // Keep available parts up-to-date
    setParams((params) => ({
      ...params,
      availableParts: Object.values(partStore.byId).filter((part) => part.buildUuid === props.build.uuid),
      selectedParts:
        params.selectedParts.length === 0 && props.initialPartUuid
          ? [partStore.byId[props.initialPartUuid]]
          : params.selectedParts,
    }));
  }, [partStore.byId, props.build.uuid, props.initialPartUuid]);

  useEffect(() => {
    if (viewportState === 'viewing') {
      toggleAnalysisTypeVisibility(params, setParams, pointClouds, renderScene);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.selectedAnalysisTypes, pointClouds, renderScene, viewportState]);

  usePointCloudEffects(pointClouds, params, renderScene, viewportState);

  const layerImageLoadingState = useLayerImagePlane(
    params.layerImagePosition,
    params.showLayerImage,
    params.clipLayerImagePlane,
    params.layerImageOpacity,
    pointClouds,
    renderScene,
    numLayers,
    minLayerNum,
    props.build.calibrationUuid,
    props.loadedLayers,
    props.fetchLayerData,
    sceneBounds
  );

  const centreCameraFunc = useRef((_bounds: BoundingBox) => {});

  const centreCameraOnPart = (partUuid: string) => {
    const partBounds = pointCloudData.getPartBounds([partUuid]);
    centreCameraFunc.current(partBounds);
  };

  return (
    <Base3DViewport
      sceneGroup={pointClouds}
      sceneBounds={sceneBounds}
      params={params}
      sidebar={
        <View3DViewportSidebar
          params={params}
          sceneBounds={sceneBounds}
          centreCameraOnPart={centreCameraOnPart}
          setParams={setParams}
          resetParams={() =>
            setParams({
              ...initialView3DViewportParams,
              availableParts: params.availableParts,
              selectedParts: params.selectedParts,
              availableAnalysisTypes: params.availableAnalysisTypes,
            })
          }
          viewportState={viewportState}
        />
      }
      sidebarInitOpen={true}
      showOverlayMessage={showOverlayMessage(viewportState)}
      overlayMessage={
        <ViewportMessage
          viewportState={viewportState}
          noSelectionTitle="No part selected"
          noSelectionMessage="Please select a part in the sidebar to the left."
        />
      }
      showHelpers={['viewing', 'loading'].includes(viewportState)}
      rerenderRef={rerenderRef}
      centreCameraOnBoundsRef={centreCameraFunc}
      leftButtons={
        <ViewSelector
          mouseOn={true}
          on3DToggle={props.onViewportTypeToggle}
          viewport3dAvailable={true}
          is3DView={true}
        />
      }
      rightButtons={
        <>
          {props.multiViewEnabled && props.canAdd && (
            <ViewerButton
              onClick={props.onAddView}
              style={{
                right: props.alone ? BUTTON_MARGIN : BUTTON_MARGIN * 2 + BUTTON_WIDTH,
                top: BUTTON_MARGIN,
                position: 'absolute',
              }}
            >
              <Add /> Add View
            </ViewerButton>
          )}

          <div
            style={{
              right: props.alone ? BUTTON_MARGIN : BUTTON_MARGIN * 2 + BUTTON_WIDTH,
              top: BUTTON_MARGIN + 40,
              position: 'absolute',
              color: 'white',
            }}
          >
            {layerImageLoadingState !== 'viewing' && (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
                <Typography>
                  {layerImageLoadingState === 'loading' ? 'Layers Loading' : "Couldn't load Layer Images"}
                </Typography>
                {layerImageLoadingState === 'loading' && <CircularProgress size="20px" />}
              </div>
            )}
          </div>
          {props.multiViewEnabled && !props.alone && (
            <Tooltip title="Close" placement="left">
              <ViewerButton
                onClick={props.onClose}
                style={{
                  right: BUTTON_MARGIN,
                  top: BUTTON_MARGIN,
                  position: 'absolute',
                }}
              >
                <Close />
              </ViewerButton>
            </Tooltip>
          )}
        </>
      }
      {...props}
    />
  );
}
