import React, {useEffect, useState} from 'react';
import {PlayArrow} from '@material-ui/icons';
import {IBuild} from '@common/api/models/builds/IBuild';
import {DeviceDetailedState} from '@common/api/models/devices/IDevice';
import {DialogButton} from '../../../../components/molecules/DialogButton';
import {monitorBuildGET} from '../../../../api/ajax/builds';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';
import {usePermissionsForBuild} from '../../../../utils/utilHooks';
import {toast} from 'react-toastify';
import {BUILD_LONG_TRANSITION_TIMEOUT} from '../index';
import {useCameraState} from '../shared/CameraState';

interface IResumeMonitoringButton {
  build: IBuild;
  deviceDetailedState: DeviceDetailedState;
  fullWidthButton?: boolean;
}

let timeout: null | ReturnType<typeof setTimeout> = null;

export const useResumeMonitoring = (
  build: IBuild,
  deviceDetailedState: DeviceDetailedState
): [boolean, () => Promise<void>] => {
  const [resumingBuild, setResumeMonitoring] = useState(false);
  const [deviceDetailedStateWas, setDeviceDetailedStateWas] = useState<DeviceDetailedState>();

  useEffect(() => {
    return () => clearTimeout(timeout!);
  }, []);

  useEffect(() => {
    // If device state transitioned from MonitoringStopping, then the job has finished, whether successful or not.
    if (
      deviceDetailedStateWas === DeviceDetailedState.MonitoringStarting &&
      deviceDetailedState !== DeviceDetailedState.MonitoringStarting
    ) {
      setResumeMonitoring(false);
      clearTimeout(timeout!);
    }

    setDeviceDetailedStateWas(deviceDetailedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDetailedState]);

  const handleResumeMonitoring = async () => {
    setResumeMonitoring(true);
    const res = await monitorBuildGET(build.uuid);
    if (!res.success) {
      setResumeMonitoring(false);
      return;
    }

    timeout = setTimeout(() => {
      setResumeMonitoring(false);
      toast('Resuming build timed out', {type: 'error'});
    }, BUILD_LONG_TRANSITION_TIMEOUT);
  };

  return [resumingBuild, handleResumeMonitoring];
};

export const RESUME_DIALOG_PROPS = {
  title: 'Resume Monitoring',
  content: `Are you sure you want to resume this build?
  This will return to monitoring mode.`,
  confirmText: 'Resume',
};

export function ResumeMonitoringButton({build, deviceDetailedState, fullWidthButton}: IResumeMonitoringButton) {
  const {allCamerasConnected} = useCameraState(build.deviceSerial!);
  const {machinePermission} = usePermissionsForBuild(build);
  const [resumeMonitoring, handleResumeMonitoring] = useResumeMonitoring(build, deviceDetailedState);

  return (
    <ConditionalTooltip
      tooltip={
        !allCamerasConnected
          ? 'One or more cameras are disconnected. Please ensure all cameras are correctly connected to the device before resuming the build.'
          : "Resuming the build requires permission to access this build's machine."
      }
      hideTooltip={!!machinePermission && allCamerasConnected}
    >
      <DialogButton
        text="Resume Monitoring"
        icon={<PlayArrow />}
        loading={resumeMonitoring}
        dialog={RESUME_DIALOG_PROPS}
        handleConfirm={handleResumeMonitoring}
        fullWidth={fullWidthButton}
        disabled={!machinePermission || !allCamerasConnected}
      />
    </ConditionalTooltip>
  );
}
