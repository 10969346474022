import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {isEqual} from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import {Button, Card, CardContent, Grid, Typography, CircularProgress, Box} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {CloudUpload} from '@material-ui/icons';
import {ISliceAttachment, ScanStrategy, SliceState} from '@common/api/models/attachments/ISliceAttachment';
import {useSliceAttachmentStoreActions} from '../../../store/actions/index';
import {RootState} from '../../../store/reducers/index';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import SliceFilesUploadModal from './SliceFilesUploadModal';
import {useMultiPartUpload} from '../../../utils/contexts/MultiPartUploadContext';
import {MultiPartUploadResourceType} from '../../../utils/contexts/IMultiPartUploadContext';
import {grayColor} from '../../../assets/jss/material-dashboard-react';

const NoSliceFilesAlert = () => {
  return (
    <Alert severity="warning" style={{margin: 5}}>
      <AlertTitle>No files uploaded</AlertTitle>
      <Typography variant="body2">No slice files available</Typography>
    </Alert>
  );
};

const SliceFilesAvailableAlert = ({sliceFiles, buildUuid}: {sliceFiles: ISliceAttachment[]; buildUuid: string}) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(moment());

  const validated = sliceFiles.filter((slice) => slice.state === SliceState.VALID).length;
  const validating = sliceFiles.filter((slice) =>
    [SliceState.NEED_TO_VERIFY, SliceState.VERIFYING].includes(slice.state)
  ).length;
  const invalid = sliceFiles.filter((slice) => slice.state === SliceState.INVALID).length;

  const statusString = [
    [validated, 'validated'],
    [validating, 'validating'],
    [invalid, 'invalid'],
  ]
    .filter(([count, _name]) => Number(count) > 0)
    .map(([count, name]) => `${count} ${name}`)
    .join(', ');

  useEffect(() => {
    // Update the current time every 10 seconds
    setTimeout(() => setCurrentTime(moment()), 10e3);
  }, [currentTime]);

  return (
    <>
      <AlertContainer>
        <Alert severity="success">
          <AlertTitle style={{width: '100%'}}>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography>
                <b>Files uploaded</b>
              </Typography>
              <Button variant="text" style={{padding: '0px'}} onClick={() => setUploadModalOpen(true)}>
                View
              </Button>
            </Box>
          </AlertTitle>
          <Typography variant="body2">{statusString}</Typography>
        </Alert>
      </AlertContainer>
      <SliceFilesUploadModal isOpen={uploadModalOpen} setOpen={setUploadModalOpen} buildUuid={buildUuid} />
    </>
  );
};

const SliceFilesUploadButton = ({
  buildUuid,
  uploading,
  uploadModalOpen,
  setUploadModalOpen,
}: {
  buildUuid: string;
  uploading: boolean;
  uploadModalOpen: boolean;
  setUploadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setUploadModalOpen(true)}
        style={{marginBottom: '12px'}}
        endIcon={uploading ? <CircularProgress size={20} /> : <CloudUpload />}
        disabled={uploading}
      >
        Upload
      </Button>
      <SliceFilesUploadModal isOpen={uploadModalOpen} setOpen={setUploadModalOpen} buildUuid={buildUuid} />
    </>
  );
};

export const SliceFilesCard = ({
  buildUuid,
  orgUuid,
  isDraft,
}: {
  buildUuid: string;
  orgUuid: string;
  isDraft?: boolean;
}) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const sliceAttachmentActions = useSliceAttachmentStoreActions();
  const sliceFilesFetching = useSelector(
    (state: RootState) => state.sliceAttachmentStore.fetched === FetchingState.Fetching
  );
  const sliceFiles = useSelector(
    (state: RootState) =>
      Object.values(state.sliceAttachmentStore.byId).filter((slice) => slice.buildUuid === buildUuid),
    isEqual
  );
  const [numFilesUploading, setNumFilesUploading] = useState(0);

  const {onDrop} = useMultiPartUpload();

  useEffect(() => {
    if (buildUuid) {
      sliceAttachmentActions.ensureConsistent({buildUuid: buildUuid}, {buildUuid: buildUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildUuid]);

  function dropZoneOnDrop(droppedFiles: File[]) {
    if (uploadModalOpen) return;

    onDrop(droppedFiles, MultiPartUploadResourceType.sliceAttachment, {
      resourceUuid: buildUuid,
      orgUuid: orgUuid,
      setNumFilesUploading,
      scanStrategy: ScanStrategy.BORDER,
    });
  }

  const slices = sliceFiles.filter((slice) => !slice.deletedAt);

  const someUploaded = !sliceFilesFetching && slices.length > 0;

  return (
    <Grid item xs={12} sm={12}>
      <Dropzone onDrop={dropZoneOnDrop} noClick disabled={uploadModalOpen}>
        {({getRootProps, getInputProps, isDragActive}) => (
          <CustomDiv isDragActive={isDragActive}>
            <section className="container">
              <div {...getRootProps({className: 'dropzone'})}>
                <div className="blur-div">
                  <input {...getInputProps()} />

                  <Card>
                    <CardContent style={{padding: isDraft ? '0px' : '16px'}}>
                      {/* padding needed to remove padding-bottom: 24px; on last-child */}
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant={isDraft ? 'h6' : 'h5'} align="center" style={{margin: 5}}>
                          Slice Files
                        </Typography>
                        <SliceFilesUploadButton
                          buildUuid={buildUuid}
                          uploading={numFilesUploading > 0}
                          uploadModalOpen={uploadModalOpen}
                          setUploadModalOpen={setUploadModalOpen}
                        />
                      </Grid>
                      {sliceFilesFetching && (
                        <Box display="flex" width="100%" pt={2} pb={2} justifyContent="center">
                          <CircularProgress />
                        </Box>
                      )}
                      {!sliceFilesFetching && !someUploaded && <NoSliceFilesAlert />}
                      {!sliceFilesFetching && someUploaded && (
                        <SliceFilesAvailableAlert sliceFiles={slices} buildUuid={buildUuid} />
                      )}
                    </CardContent>
                  </Card>
                </div>
                <Typography variant="h5" className="drag-tip">
                  Drag file here
                </Typography>
              </div>
            </section>
          </CustomDiv>
        )}
      </Dropzone>
    </Grid>
  );
};

const AlertContainer = styled.div`
  .MuiAlert-message {
    width: 100%;
  }
`;

const CustomDiv = styled.div<{isDragActive: boolean}>`
  ${({isDragActive}) => (isDragActive ? `border: dashed 2px ${grayColor[6]};` : 'border: 2px solid transparent;')}
  ${({isDragActive}) => (isDragActive ? `background-color: ${grayColor[5]};` : '')}
  transition: all 0.3s;
  border-radius: 5px;
  padding: 8px;
  margin: -8px;
  position: relative;

  .blur-div {
    ${({isDragActive}) => (isDragActive ? `filter: blur(10px)` : '')};
  }
  .drag-tip {
    ${({isDragActive}) => (isDragActive ? `` : `display: none;`)}
    position: absolute;
    top: 50%;
    width: calc(100% - 16px);
    text-align: center;
  }
`;
