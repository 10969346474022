import React from 'react';
import {CoolLinkedTable} from './CoolTable';
import {MaterialTableProps} from 'material-table';
import {renderDateTimeString} from '../../../utils/string';
import {Typography} from '@material-ui/core';
import EllipsisTextWithTooltip from '../../atoms/Texts/EllipsisTextWithTooltip';

export interface MaintenanceLogEntry {
  user: string;
  message: string;
  date: Date;
}

export interface MaintenanceLogRow {
  user: string;
  message: string;
  date: Date;
  url: string;
}

export function MaintenanceLogTable(
  props: {
    messages: MaintenanceLogEntry[];
    errorMessage?: string;
    filters?: Partial<MaintenanceLogRow>;
  } & Partial<MaterialTableProps<MaintenanceLogRow>>
) {
  const {messages, errorMessage, filters, ...rest} = props;
  return (
    <div style={{maxWidth: '100%'}}>
      <CoolLinkedTable
        columns={[
          {title: 'User', field: 'user', width: '168px'},
          {
            title: 'Message',
            field: 'message',
            render: (row: MaintenanceLogEntry) => <EllipsisTextWithTooltip>{row.message}</EllipsisTextWithTooltip>,
          },
          {
            title: 'Date',
            field: 'date',
            type: 'date',
            width: '168px',
            render: (row: MaintenanceLogEntry) => (
              <Typography>{renderDateTimeString('short', row.date, true)}</Typography>
            ),
          },
        ]}
        data={messages.map((m): MaintenanceLogRow => ({...m, url: ''}))}
        filters={filters}
        errorMessage={errorMessage}
        linked={false}
        options={{tableLayout: 'fixed'}}
        {...rest}
      />
    </div>
  );
}
