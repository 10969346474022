import React, {useRef} from 'react';
import styled from 'styled-components';
import {Box, Tooltip, Typography} from '@material-ui/core';
import {KeyboardArrowDown} from '@material-ui/icons';

import {AnalysisType3D, getPrettyAnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {turboColourMap, redBlueDivergentColourMap} from '../3D/pointCloudLoader';
import {View3DViewportParams} from './View3DViewport';
import {CustomSelect} from '../FadingComponents';
import {AnalysisTypeMap} from './types';
import {MinMax} from '../../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
export const SOURCE_PART_COLOR = '#366fe0';
export const TARGET_PART_COLOR = '#eb4034';

interface IAnalysisTypeHelper {
  selectedAnalysisTypes: View3DViewportParams['selectedAnalysisTypes'];
  setAnalysisType?: (newAnalysisType: AnalysisType3D, oldAnalysisType: AnalysisType3D) => void;
  availableAnalysisTypes?: View3DViewportParams['availableAnalysisTypes'];
  backgroundColour: string;
  position?: 'absolute' | 'relative';
  analysisTypeSizes?: AnalysisTypeMap<MinMax<number>>;
  comparisonScaling?: boolean;
}

const AnalysisTypeHelper = ({
  selectedAnalysisTypes,
  backgroundColour,
  setAnalysisType,
  availableAnalysisTypes,
  position = 'absolute',
  analysisTypeSizes,
  comparisonScaling,
}: IAnalysisTypeHelper) => {
  const asimIsSelected = !!selectedAnalysisTypes[AnalysisType3D.ASIM];
  const colourMap = asimIsSelected ? turboColourMap : redBlueDivergentColourMap;
  const currentlySelected = Object.entries(selectedAnalysisTypes).find(
    ([_type, enabled]) => !!enabled
  )![0] as AnalysisType3D;
  const anyAvailable = Object.entries(availableAnalysisTypes || {}).some(([_type, enabled]) => !!enabled);

  let scaledThreshold = 0;
  if (comparisonScaling) {
    scaledThreshold = Math.max(...Object.values(analysisTypeSizes![currentlySelected]).map(Math.abs));
  }

  return (
    <Box
      position={position}
      bottom={position === 'absolute' ? '24px' : undefined}
      right={position === 'absolute' ? '64px' : undefined}
    >
      {!!setAnalysisType && !!anyAvailable && (
        <AnalysisTypeSelector
          selectedAnalysisTypes={selectedAnalysisTypes}
          backgroundColour={backgroundColour}
          setAnalysisType={setAnalysisType}
          availableAnalysisTypes={availableAnalysisTypes}
        />
      )}
      {!!anyAvailable && currentlySelected === AnalysisType3D.Geometry && (
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <StyledTypography $backgroundColor={backgroundColour}>Source Part:</StyledTypography>
            <ColourDot colour={SOURCE_PART_COLOR} />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <StyledTypography $backgroundColor={backgroundColour}>Target Part:</StyledTypography>
            <ColourDot colour={TARGET_PART_COLOR} />
          </Box>
        </Box>
      )}
      {!!currentlySelected && ![AnalysisType3D.Geometry, AnalysisType3D.Model].includes(currentlySelected) && (
        <Box display="flex" alignItems="center">
          <StyledTypography
            $backgroundColor={backgroundColour}
            style={{
              marginRight: '10px',
            }}
          >
            {asimIsSelected ? '0%' : comparisonScaling ? `${-scaledThreshold}%` : '-100%'}
          </StyledTypography>
          <Box display="flex" width="150px" height="10px">
            {colourMap.map((colour, index: number) => {
              // Only show 100 points, regardless of how many colours in the colourmap
              if (index % (colourMap.length / 100) !== 0) return <></>;
              return (
                <Box
                  key={`${colour}-${index}`}
                  width="1%"
                  height="100%"
                  style={{
                    backgroundColor: `rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`,
                  }}
                />
              );
            })}
          </Box>
          <StyledTypography
            $backgroundColor={backgroundColour}
            style={{
              marginLeft: '10px',
            }}
          >
            {asimIsSelected ? '100%' : comparisonScaling ? `${scaledThreshold}%` : '100%'}
          </StyledTypography>
        </Box>
      )}
    </Box>
  );
};

export default AnalysisTypeHelper;

const AnalysisTypeSelector = ({
  selectedAnalysisTypes,
  backgroundColour,
  setAnalysisType,
  availableAnalysisTypes,
}: Omit<IAnalysisTypeHelper, 'position' | 'analysisTypeSizes'>) => {
  const currentlySelected = Object.entries(selectedAnalysisTypes).find(([_type, enabled]) => !!enabled)![0];
  const selectContainerRef = useRef<HTMLDivElement>(null);

  if (!setAnalysisType || !availableAnalysisTypes) return <></>;

  return (
    <Box
      marginBottom="6px"
      style={{
        backgroundColor: backgroundColour,
        display: 'inline',
        position: 'relative',
      }}
    >
      <Tooltip title="Select Analysis Type" PopperProps={{anchorEl: selectContainerRef.current}} placement="left">
        <div ref={selectContainerRef}>
          <StyledTypography $backgroundColor={backgroundColour} style={{paddingRight: '6px'}}>
            Viewing:{' '}
          </StyledTypography>
          <CustomSelect
            style={{color: '#fff'}}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              container: () => document.fullscreenElement ?? null,
            }}
            IconComponent={customKeyboardArrowDown}
            value={currentlySelected}
            onChange={(
              e: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
              }>
            ) => {
              setAnalysisType(e.target.value as AnalysisType3D, currentlySelected as AnalysisType3D);
            }}
          >
            {Object.entries(availableAnalysisTypes)
              .filter(([_, available]) => !!available)
              .map(([analysisType, _]) => {
                return (
                  <option value={analysisType} style={{padding: '5px 10px', cursor: 'pointer'}} key={analysisType}>
                    {getPrettyAnalysisType3D(analysisType as AnalysisType3D)}
                  </option>
                );
              })}
          </CustomSelect>
        </div>
      </Tooltip>
    </Box>
  );
};

const customKeyboardArrowDown = styled(KeyboardArrowDown)`
  color: #fff;
`;

const StyledTypography = styled(Typography)<{$backgroundColor: string}>`
  color: white;
  font-weight: bold;
  display: inline;
  background-color: ${({$backgroundColor}) => $backgroundColor};
`;

const ColourDot = styled.div<{colour: string}>`
  width: 12px;
  height: 12px;
  margin-left: 12px;
  border-radius: 25px;
  background-color: ${({colour}) => `${colour}`};
`;
