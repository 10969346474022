import React, {useEffect, useRef, useState} from 'react';
import * as uuid from 'uuid';
import {useLocation} from 'react-router-dom';
import {isNumber} from 'lodash';
import {Grid} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {BuildState, IBuild, isRunningBuildState} from '@common/api/models/builds/IBuild';
import {LayerImages, ViewportPlaceholder} from '../../../pages/builds/liveBuild/activeBuildPages/ViewportsPage';
import MultiLayerViewport, {defaultViewportState, MultiLayerViewportState} from './2D/MultiLayerViewport';
import {OnPositionChangeFn, SetPositionFn, SetPositionFnReturn} from './2D/viewportHooks';
import {useExtraSmallScreenSize, useWindowSize} from '../../../utils/utilHooks';
import View3DViewport from './3D/View3DViewport';
import {ViewportProps} from './viewportProps';
import {RootState} from '../../../store/reducers';

export interface MultiViewerProps {
  build: IBuild;
  fetchLayerData: (layerNumber: number) => void;
  loadedLayers: LayerImages;
  cmLoadedImages: any;
  setCmLoadedImage: (newValue: {}) => void;
  totalLayers: number;
  mmPerPixel: number;
  viewport3DAvailable: boolean;
  partialLayerNumbers: Set<number>;
}

function getColWidth(items: number) {
  if (items === 1) {
    return 12;
  } else if (items <= 4) {
    return 6;
  } else if (items <= 9) {
    return 4;
  }
  return 12;
}

function getNumRows(items: number) {
  if (items > 9) {
    return undefined;
  }
  return [-1, 1, 1, 2, 2, 2, 2, 3, 3, 3][items];
}

function getIndividualViewHeight(windowHeight: number | undefined, items: number, deviceOfflineBannerShown: boolean) {
  if (!windowHeight) {
    return undefined;
  }
  const unscrolledHeight = windowHeight - 202 - (deviceOfflineBannerShown ? 48 : 0);
  const scrolledHeight = windowHeight - 220 - (deviceOfflineBannerShown ? 48 : 0);
  const numRows = getNumRows(items);
  if (!numRows) {
    return undefined;
  }
  if (numRows === 1) {
    return unscrolledHeight;
  } else {
    return scrolledHeight / numRows;
  }
}

interface View2DItemRecord {
  type: '2D';
  state: MultiLayerViewportState;
}

interface View3DItemRecord {
  type: '3D';
}

type ViewItemRecord = (View2DItemRecord | View3DItemRecord) & {key: string};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const computeInitialLayer = (buildState: BuildState, totalLayers: number, queryLayerNum: number) => {
  // If layer number specified in URL query string, use that
  if (isNumber(queryLayerNum) && queryLayerNum !== 0) {
    return queryLayerNum;
  }

  // If build is active, show the most recent layer
  if (buildState === BuildState.ACTIVE || buildState === BuildState.PAUSED) {
    return totalLayers;
  }

  // Otherwise, the build has ended, show the first layer
  return 1;
};

export default function MultiViewer(props: MultiViewerProps) {
  const isXsScreen = useExtraSmallScreenSize();
  const windowSize = useWindowSize();
  const maxViews = isXsScreen ? 1 : 4;
  const query = useQuery();
  const queryLayerNum = Number(query.get('layerNum'));
  const queryPartUuid = query.get('partUuid') ?? undefined;
  const queryAnalysisType = query.get('analysisType') ?? undefined;
  const queryViewport = query.get('viewport') ?? undefined;
  const [linkedLoading, setLinkedLoading] = useState(false);
  const [hoveredLayerId, setHoveredLayerId] = useState(0);
  const [isLiveUpdating, setLiveUpdating] = useState(props.build.state === BuildState.ACTIVE);
  const [needScroll, setNeedScroll] = useState(false);
  const deviceOfflineBannerShown = useSelector((state: RootState) =>
    isRunningBuildState(props.build.state) && state.deviceStore.byId[props.build.deviceSerial!]
      ? !state.deviceStore.byId[props.build.deviceSerial!]?.online
      : false
  );
  const [crosshairsEnabled, setCrosshairsEnabled] = useState(false);
  const [hoveredPosition, setHoveredPosition] = useState<{x?: number; y?: number; z?: number} | null>(null);

  const [currentLayer, setCurrentLayer] = useState(
    computeInitialLayer(props.build.state, props.totalLayers, queryLayerNum)
  );
  const [colWidth, setColWidth] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>(12);
  const [viewsList, setViewsList] = useState<ViewItemRecord[]>(() => {
    const key = uuid.v4();
    const type = queryViewport === '3d' ? '3D' : '2D';
    return type === '2D' ? [{type, key, state: defaultViewportState}] : [{type, key}];
  });
  const updateFns = useRef<{
    [key: string]: React.MutableRefObject<SetPositionFn | undefined>;
  }>({});

  const {fetchLayerData} = props;

  useEffect(() => {
    if (isLiveUpdating) {
      // new layer got recorded
      setCurrentLayer(props.totalLayers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.totalLayers]);

  useEffect(() => {
    const liveUpdating = props.build.state === BuildState.ACTIVE;
    setLiveUpdating(liveUpdating);
    if (liveUpdating) {
      setCurrentLayer(props.totalLayers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.state]);

  useEffect(() => {
    if (needScroll) {
      setNeedScroll(false);
      window.scrollTo({top: 200, left: 0, behavior: 'smooth'});
    }
  }, [needScroll]);

  const getInitialPosition: () => SetPositionFnReturn = () => {
    for (const view of viewsList) {
      if (view.type === '2D' && view.state.linkedPosition) {
        const fn = updateFns.current[view.key];
        if (fn && fn.current) {
          return fn.current({});
        }
      }
    }

    return {x: 0, y: 0, scale: 0, zoom: 1, viewportFit: true};
  };

  useEffect(() => {
    setColWidth(getColWidth(viewsList.length));
  }, [viewsList]);

  const handleAddView = () => {
    const oldRows = getNumRows(viewsList.length);
    const newRows = getNumRows(viewsList.length + 1);

    const key = uuid.v4();
    const type = queryViewport === '3d' ? '3D' : '2D';

    const newView: ViewItemRecord = type === '2D' ? {type, key, state: defaultViewportState} : {type, key};

    setViewsList([...viewsList, newView]);

    // Scroll window so that all views fit when there is a new row
    if (oldRows && newRows && newRows > oldRows) {
      setNeedScroll(true);
    }
  };

  const getViewIndex = (key: string) => viewsList.findIndex((view) => view.key === key);

  const handleLinkedLayers = (key: string) => {
    const index = getViewIndex(key);
    const view = viewsList[index];
    if (view.type === '3D') return;

    setViewsList([
      ...viewsList.slice(0, index),
      {
        ...view,
        state: {
          ...view.state,
          linkedLayers: !view.state.linkedLayers,
        },
      },
      ...viewsList.slice(index + 1, viewsList.length),
    ]);
  };

  const handleLinkedPosition = (key: string) => {
    const index = getViewIndex(key);
    const view = viewsList[index];
    if (view.type === '3D') return;

    setViewsList([
      ...viewsList.slice(0, index),
      {
        ...view,
        state: {
          ...view.state,
          linkedPosition: !view.state.linkedPosition,
        },
      },
      ...viewsList.slice(index + 1, viewsList.length),
    ]);
  };

  const handleClose = (key: string) => {
    const index = getViewIndex(key);
    setViewsList([...viewsList.slice(0, index), ...viewsList.slice(index + 1, viewsList.length)]);
  };

  const handleOn3DClick = (key: string) => {
    const index = getViewIndex(key);
    setViewsList([
      ...viewsList.slice(0, index),
      {...viewsList[index], type: '3D'},
      ...viewsList.slice(index + 1, viewsList.length),
    ]);
  };

  const handleOn2DClick = (key: string) => {
    const index = getViewIndex(key);
    setViewsList([
      ...viewsList.slice(0, index),
      {...viewsList[index], type: '2D', state: defaultViewportState},
      ...viewsList.slice(index + 1, viewsList.length),
    ]);
  };

  const onPositionChange: OnPositionChangeFn = (newPosition) => {
    for (const view of viewsList) {
      const fn = updateFns.current[view.key];
      if (fn?.current && view.type === '2D' && view.state.linkedPosition) {
        fn.current(newPosition);
      }
    }
  };

  const fetchDataForHoveredThumbnail = (hoveredLayerId: number, shouldFetch = true) => {
    if (shouldFetch) fetchLayerData(hoveredLayerId);
    setHoveredLayerId(hoveredLayerId);
  };

  if (!currentLayer) {
    if (props.totalLayers) {
      return <ViewportPlaceholder build={props.build} waitingForFirstFullLayer />;
    }

    return <></>;
  }

  return (
    <Grid
      container
      justifyContent="center"
      spacing={1}
      style={isXsScreen ? {position: 'fixed', top: '74px', bottom: '65px', left: 0, right: 0} : {}}
    >
      {viewsList.map((view) => {
        if (!updateFns.current.hasOwnProperty(view.key)) {
          updateFns.current[view.key] = {current: undefined};
        }

        const isLinkedPosition = (view.type === '2D' && view.state.linkedPosition) ?? false;

        const isLinkedLayer = (view.type === '2D' && view.state.linkedLayers) ?? false;

        const commonProps: ViewportProps = {
          height:
            isXsScreen && windowSize.height
              ? windowSize.height - 135
              : Math.max(
                  getIndividualViewHeight(windowSize.height, viewsList.length, deviceOfflineBannerShown) || 0,
                  482
                ),
          alone: viewsList.length === 1,
          canAdd: viewsList.length < maxViews,
          multiViewEnabled: true,
          parentGridColumns: colWidth,
          onAddView: handleAddView,
          onClose: () => handleClose(view.key),
          onViewportTypeToggle: () => (view.type === '2D' ? handleOn3DClick(view.key) : handleOn2DClick(view.key)),
          build: props.build,
        };

        return (
          <Grid item xs={colWidth} key={view.key}>
            {view.type === '3D' ? (
              <View3DViewport
                {...commonProps}
                initialPartUuid={queryPartUuid}
                initialAnalysisType={queryAnalysisType}
                fetchLayerData={fetchLayerData}
                loadedLayers={props.loadedLayers}
              />
            ) : (
              <MultiLayerViewport
                {...props}
                {...commonProps}
                {...view.state}
                partialLayerNumbers={props.partialLayerNumbers}
                viewportKey={view.key}
                hoveredLayerId={hoveredLayerId}
                fetchDataForHoveredThumbnail={fetchDataForHoveredThumbnail}
                setPositionFnRef={updateFns.current[view.key]}
                onLinkedLayers={() => handleLinkedLayers(view.key)}
                onLinkedPosition={() => handleLinkedPosition(view.key)}
                onPositionChange={isLinkedPosition ? onPositionChange : undefined}
                alone2d={viewsList.filter((view) => view.type === '2D').length === 1}
                onCurrentLinkedLayerChange={setCurrentLayer}
                setLinkedLoading={isLinkedLayer ? setLinkedLoading : undefined}
                linkedLoading={isLinkedLayer ? linkedLoading : undefined}
                currentLinkedLayer={currentLayer}
                fetchLayerData={fetchLayerData}
                getInitialPosition={getInitialPosition}
                setLinkedLiveUpdating={setLiveUpdating}
                linkedLiveUpdating={isLiveUpdating}
                setCrosshairsEnabled={setCrosshairsEnabled}
                crosshairsEnabled={crosshairsEnabled}
                setHoveredPosition={setHoveredPosition}
                hoveredPosition={hoveredPosition}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
