import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/reducers';
import {Button} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {Role} from '@common/api/models/users/IUser';
import IndexPage from '../shared/IndexPage/IndexPage';
import {IDevicesTableGETResponse} from '@common/api/models/devices/IDevice';

const useDeviceData = () => {
  const devices = useSelector((state: RootState) => state.deviceStore.list);

  return devices.map((device: IDevicesTableGETResponse) => {
    return {
      ...device,
      availability: device.availability,
      machine: device.machineName || '',
      onClickUrl: device.machineUuid ? '/machines/uuid/' + device.machineUuid + '/' : undefined,
    };
  });
};

const CreateDeviceButton = () => {
  const history = useHistory();
  const auth = useSelector((s: RootState) => s.auth);
  const canCreateDevice = auth.user!.role === Role.SUPERUSER;

  if (!canCreateDevice) return <></>;

  return (
    <Button variant="contained" color="primary" onClick={() => history.push('/machines/devices/createDevice/')}>
      <Add />
      New Device
    </Button>
  );
};

export function DevicesPage() {
  return (
    <IndexPage
      resourceType="device"
      CreateResourceComponent={CreateDeviceButton}
      useData={useDeviceData}
      title="Devices"
    />
  );
}
