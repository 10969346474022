import React, {useEffect, useState} from 'react';
import {CircularProgress, Slider, Typography, withStyles} from '@material-ui/core';
import {AnalysisType2D} from '@common/api/models/builds/data/defects/IDefect';
import {getThumbnailImage, LayerImages} from '../../../../../pages/builds/liveBuild/activeBuildPages/ViewportsPage';
import {BUTTON_MARGIN, BUTTON_WIDTH, defaultPart, getClosestFullLayer} from '../utils';
import {layerImage404, layerImageLoading} from '../../../../assets/images';

let timeout: null | ReturnType<typeof setTimeout> = null;

const CustomSlider = withStyles({
  root: {
    height: 6,
    '&$vertical': {
      width: 6,
      padding: '0px 6px',
    },
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -6,
    marginLeft: -9,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
  },

  vertical: {
    '& $rail': {
      width: 6,
    },
    '& $track': {
      width: 6,
    },
    '& $thumb': {
      marginLeft: -6,
      marginBottom: -9,
    },
  },
})(Slider);
export interface LayerSliderProps {
  paginationStatus: boolean;
  totalLayers: number;
  currentLayer: number;
  hoveredLayerId: number;
  layers: LayerImages;
  partialLayerNumbers: Set<number>;
  hidePartialLayers: boolean;
  selectedOverlays: {[analysisType in AnalysisType2D]?: boolean};

  stageWidth: number;
  stageHeight: number;
  analysisType: string;

  setSlidingLayer: (layerId: number | null) => void;
  onCurrentLayerChange: (newLayerId: number) => any;
  fetchDataForHoveredThumbnail: (overedLayerId: number, shouldFetch?: boolean) => any;
}

export default function LayerSlider({
  paginationStatus,
  totalLayers,
  currentLayer,
  hoveredLayerId,
  layers,
  partialLayerNumbers,
  hidePartialLayers,
  selectedOverlays,
  stageHeight,
  analysisType,
  setSlidingLayer,
  onCurrentLayerChange,
  fetchDataForHoveredThumbnail,
}: LayerSliderProps) {
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [internalLayer, setInternalLayer] = useState(currentLayer);
  const [thumbnailLocation, setThumbnailLocation] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [loadingLayerId, setLoadingLayerId] = useState(0);
  const sliderHeightPixels = stageHeight - (BUTTON_MARGIN * 2 + 12);

  const pixelGapPerLayer = sliderHeightPixels / Math.max(totalLayers - 1, 1);

  const [thumbnailSrcs, setThumbnailSrcs] = useState<{
    [key: number]: {
      result: string[];
      analysisString: string;
    };
  }>({});

  useEffect(() => {
    setInternalLayer(currentLayer);
  }, [currentLayer]);

  // Find the correct thumbnail source URL
  useEffect(() => {
    let overlayString = '';
    Object.entries(selectedOverlays).forEach(([overlay, value]) => {
      if (value) overlayString = `${overlayString}-${overlay}`;
    });
    const alreadyLoaded =
      thumbnailSrcs[hoveredLayerId] &&
      thumbnailSrcs[hoveredLayerId].analysisString === `${overlayString}-${analysisType}`;
    if (layers[hoveredLayerId] && !alreadyLoaded) {
      fetchImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredLayerId, analysisType, selectedOverlays, layers]);

  const fetchLayerData = async (y: number) => {
    if (y >= 0 && y <= sliderHeightPixels) {
      const thisHoveredLayerId = calculateHoveredLayerId(y);
      if (loadingLayerId !== thisHoveredLayerId) {
        setLoading(true);
        setThumbnailLocation(y);
        setLoadingLayerId(thisHoveredLayerId);

        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
          if (thumbnailSrcs[thisHoveredLayerId]) {
            fetchDataForHoveredThumbnail(thisHoveredLayerId, false);
            setLoading(false);
            return;
          }
          fetchDataForHoveredThumbnail(thisHoveredLayerId);
        }, 300);
      }
    }
  };

  const handleOnMouseMove = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.persist();
    const rect = event.currentTarget.getBoundingClientRect();
    const y: number = event.clientY - rect.top;
    fetchLayerData(y);
  };

  const handleTouchMove = async (event: React.TouchEvent<HTMLDivElement>) => {
    if (event.touches.length !== 1) return;
    event.persist();
    const rect = event.currentTarget.getBoundingClientRect();
    const y: number = event.touches[0].clientY - rect.top;
    fetchLayerData(y);
  };

  /** Returns layerId based on mouse-hover on slider */
  function calculateHoveredLayerId(y: number): number {
    const yPixelProgress = sliderHeightPixels - y;
    const hoveredLayerPixel = Math.round(yPixelProgress / pixelGapPerLayer) + 1;
    const layerNum = Math.max(Math.min(hoveredLayerPixel, totalLayers), 1);

    return getClosestFullLayer(layerNum, totalLayers, hidePartialLayers, partialLayerNumbers);
  }

  const fetchImage = async () => {
    const result: string[] = [];
    let overlayString = '';
    for (const selectedOverlay of Object.keys(selectedOverlays) as AnalysisType2D[]) {
      if (!selectedOverlays[selectedOverlay]) {
        continue;
      }
      const images = layers[hoveredLayerId]?.[defaultPart]?.[selectedOverlay]?.images;
      if (!images) {
        continue;
      }
      const thumbnail = getThumbnailImage(images);
      if (thumbnail) {
        result.push(await thumbnail.image.src);
        overlayString = `${overlayString}-${selectedOverlay}`;
      }
    }
    if (result.length === 0 && paginationStatus) {
      result.push(layerImageLoading.src);
    } else if (result.length === 0) {
      // add a 404
      result.push(layerImage404.src);
    }

    setThumbnailSrcs({
      ...thumbnailSrcs,
      [hoveredLayerId]: {
        result,
        analysisString: `${overlayString}-${analysisType}`,
      },
    });
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          top: BUTTON_MARGIN + 6,
          bottom: BUTTON_MARGIN + 6,
          right: BUTTON_WIDTH + BUTTON_MARGIN,
          width: 18,
        }}
        onMouseMove={handleOnMouseMove}
        onMouseLeave={() => {
          timeout && clearTimeout(timeout);
          setShowThumbnail(false);
        }}
        onMouseEnter={() => setShowThumbnail(true)}
        onTouchStart={() => {
          setShowThumbnail(true);
        }}
        onTouchMove={handleTouchMove}
        onTouchEnd={() => {
          timeout && clearTimeout(timeout);
          setShowThumbnail(false);
        }}
      >
        <CustomSlider
          orientation="vertical"
          aria-labelledby="vertical-layer-slider"
          getAriaValueText={() => `layer-${internalLayer}`}
          defaultValue={internalLayer}
          value={internalLayer}
          max={totalLayers}
          min={1}
          onChange={(_e, value) => {
            const layerNum = getClosestFullLayer(value as number, totalLayers, hidePartialLayers, partialLayerNumbers);
            setInternalLayer(layerNum);
            setSlidingLayer(layerNum);
          }}
          onChangeCommitted={(_e, value) => {
            const layerNum = getClosestFullLayer(value as number, totalLayers, hidePartialLayers, partialLayerNumbers);
            setInternalLayer(layerNum);
            onCurrentLayerChange(layerNum);
            setSlidingLayer(null);
          }}
        />
      </div>

      {showThumbnail && (
        <div
          style={{
            position: 'absolute',
            right: BUTTON_WIDTH + BUTTON_WIDTH,
            top: Math.max(15, Math.min(15 + thumbnailLocation - 30, sliderHeightPixels - 160)),
            padding: '2px',
            backgroundColor: 'rgba(150, 220, 250, 0.8)',
            pointerEvents: 'none',
          }}
        >
          {isLoading && (
            <div
              style={{
                width: '150px',
                height: '150px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress
                color="secondary"
                size={30}
                style={{
                  color: 'rgba(25, 118, 210, 0.8)',
                }}
              />
            </div>
          )}
          {!isLoading &&
            thumbnailSrcs[hoveredLayerId] &&
            thumbnailSrcs[hoveredLayerId].result.map((src, i) => {
              return (
                <img
                  alt={`Thumbnail for layer ${hoveredLayerId}`}
                  src={src}
                  width={150}
                  height={150}
                  style={{
                    display: 'block',
                    position: i === 0 ? 'relative' : 'absolute',
                    top: 0,
                    left: 0,
                  }}
                  key={`thumbnail-${i}`}
                />
              );
            })}
          <Typography>
            {hoveredLayerId}/{totalLayers}
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
}
