import React, {useEffect, useState} from 'react';
import {AlertSeverity} from '@common/api/models/devices/IAlert';
import {Chip, IconButton, Tooltip, Typography} from '@material-ui/core';
import {getSeverityColor} from '../../../../../src/utils/color';
import {renderDateTimeString} from '../../../../../src/utils/string';
import {toast} from 'react-toastify';
import {Check, Copy} from 'react-feather';
import DisappearingTag from '../../../../components/atoms/DisappearingTag';

const ClipBoardButton = (row: AlertsRow) => {
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const onCopy = async () => {
    if (!navigator.clipboard) {
      toast('Clipboard unavailable.', {type: 'error'});
      return;
    }
    try {
      await navigator.clipboard.writeText(row.message);
      setCopied(true);
      setShowTooltip(true);
    } catch {
      toast('Failed to copy to clipboard.', {type: 'error'});
    }
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => setShowTooltip(false), 1000);
    }
  }, [showTooltip]);

  return (
    <Tooltip title="Copied!" open={showTooltip}>
      <IconButton size="small" onClick={onCopy}>
        {copied ? <Check /> : <Copy />}
      </IconButton>
    </Tooltip>
  );
};

export interface AlertsRow {
  severity: AlertSeverity;
  message: string;
  date: Date;
  build?: string;
}

export const alertTableSchema = [
  {
    title: 'Severity',
    field: 'severity',
    width: '124px',
    render: (row: AlertsRow) => (
      <Chip
        size="small"
        style={{backgroundColor: getSeverityColor(row.severity)}}
        label={row.severity}
        color="primary"
      />
    ),
  },
  {
    title: 'Message',
    field: 'message',
    render: (row: AlertsRow) => {
      const newAlert = new Date(row.date).getTime() > new Date().getTime() - 60 * 60 * 1000;
      return (
        <Typography noWrap={false}>
          {newAlert && <DisappearingTag label="New" />} {row.message}
        </Typography>
      );
    },
  },
  {
    title: 'Date',
    field: 'date',
    defaultSort: 'desc',
    width: '180px',
    render: (row: AlertsRow) => <Typography>{renderDateTimeString('short', row.date)}</Typography>,
  },
  {
    title: 'Build',
    field: 'build',
    width: '150px',
  },
  {
    title: 'Actions',
    width: '100px',
    sorting: false,
    render: (row: AlertsRow) => <ClipBoardButton {...row} />,
    cellStyle: {
      textAlign: 'center' as 'center',
    },
  },
];

export default alertTableSchema;
