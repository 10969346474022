import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {transformInterval} from '../../../../../utils/MathUtils';
import {Color} from 'three';
import {useCallback, useEffect} from 'react';
import {BufferGeometry} from 'three';
import {View3DViewportParams} from '../View3DViewport';
import {ExplicitPoint, SimilarityPoint, turboColourMap, redBlueDivergentColourMap} from '../pointCloudLoader';
import {PartPointClouds, View3DState} from '../types';
import {
  toggleUse3DPoints,
  toggleSimilarityTransparency,
  updateNumPointsDisplayed,
  updatePointSize,
  updateModelOpacity,
  updatePointColourOverride,
  updatePointCloudHighlight,
  toggleSimilarityGeometry,
  rotatePointCloud,
  toggleSimilarityRotationColours,
} from '../viewportFunctions';

/**
 * Custom hook for handling point cloud effects in the 3D viewport.
 *
 * @param pointClouds - The point clouds to be rendered.
 * @param params - The viewport parameters.
 * @param renderScene - A function to render the scene.
 * @param viewportState - The state of the 3D viewport.
 * @param geometry - Optional buffer geometry for the point clouds.
 * @param loadedLayers - Optional loaded layer images.
 * @param fetchLayerData - Optional function to fetch layer data.
 * @returns The loading state of the layer images.
 */
export function usePointCloudEffects(
  pointClouds: PartPointClouds,
  params: View3DViewportParams,
  renderScene: () => void,
  viewportState: View3DState,
  geometry?: BufferGeometry
) {
  const numPointCloudsLoaded = pointClouds.numPointClouds;

  const getBlockColour = useCallback(
    (point: ExplicitPoint & Partial<SimilarityPoint>) => {
      const selectedType = Object.keys(params.selectedAnalysisTypes)[
        Object.values(params.selectedAnalysisTypes).indexOf(true)
      ] as AnalysisType3D;
      const {min, max} = params.analysisTypeSizes![selectedType];
      const limit = Math.max(Math.abs(min ?? 0), Math.abs(max ?? 0));
      const isASIM = selectedType === AnalysisType3D.ASIM;
      const currentColourMap = isASIM ? turboColourMap : redBlueDivergentColourMap;
      if (point.similarityCoefficient) {
        let transformation, colour;
        if (params.comparisonScaling) {
          if (isASIM) {
            transformation = transformInterval([min!, max!], [0, 99]);
          } else {
            transformation = transformInterval([-limit, limit], [0, 199]);
          }
        } else {
          if (isASIM) {
            transformation = transformInterval([0, 100], [0, 99]);
          } else {
            transformation = transformInterval([-100, 100], [0, 199]);
          }
        }
        colour = currentColourMap[Math.floor(transformation(point.similarityCoefficient))];

        return new Color(colour[0] / 255, colour[1] / 255, colour[2] / 255);
      }

      // Default to 0 on Colourmap if no Similarity Coefficient found

      const colour = redBlueDivergentColourMap[100];
      return new Color(colour[0] / 255, colour[1] / 255, colour[2] / 255);
    },
    [params.analysisTypeSizes, params.comparisonScaling, params.selectedAnalysisTypes]
  );

  const shouldDisplayfn = useCallback(
    (point: ExplicitPoint & Partial<SimilarityPoint>) => {
      const {min: minPointSize, max: maxPointSize} = params.pointSizeCutoff || {};
      const {min: minAnalysisAmount, max: maxAnalysisAmount} = params.analysisAmountCutoff || {};
      const {min: minDefectArea, max: maxDefectArea} = params.defectAreaFilter || {};

      if (minPointSize && point.scale <= minPointSize) return false;
      if (maxPointSize && point.scale >= maxPointSize) return false;
      if (minDefectArea && point.defectArea && point.defectArea <= minDefectArea) return false;
      if (maxDefectArea && point.defectArea && point.defectArea >= maxDefectArea) return false;

      if (point.similarityCoefficient !== undefined) {
        if (minAnalysisAmount && point.similarityCoefficient <= minAnalysisAmount) return false;
        if (maxAnalysisAmount && point.similarityCoefficient >= maxAnalysisAmount) return false;
      }

      return true;
    },
    [params.pointSizeCutoff, params.analysisAmountCutoff, params.defectAreaFilter]
  );

  useEffect(() => {
    toggleUse3DPoints(params, pointClouds, renderScene, geometry);
    // eslint wants the dependency `params`. We don't need the models to reload
    // when any params change, so ignore.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.use3DPoints, renderScene]);

  useEffect(() => {
    toggleSimilarityTransparency(params, pointClouds, renderScene);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.isTransparent, renderScene]);

  useEffect(() => {
    updateNumPointsDisplayed(params.pointLimit, pointClouds, numPointCloudsLoaded, renderScene);
  }, [params.pointLimit, pointClouds, numPointCloudsLoaded, renderScene]);

  useEffect(() => {
    updatePointSize(
      params.pointSize,
      pointClouds,
      renderScene,
      shouldDisplayfn,
      params.analysisTypeSizes ? getBlockColour : undefined
    );
  }, [
    params.pointSize,
    params.analysisTypeSizes,
    params.defectAreaFilter,
    pointClouds,
    getBlockColour,
    shouldDisplayfn,
    renderScene,
    pointClouds.numPointClouds,
  ]);

  useEffect(() => {
    updateModelOpacity(params.modelOpacity, pointClouds, renderScene);
  }, [params.modelOpacity, pointClouds, renderScene]);

  useEffect(() => {
    updatePointColourOverride(
      params.overridePointColour,
      params.overrideColour,
      pointClouds,
      params.use3DPoints,
      renderScene
    );
  }, [params.overrideColour, params.overridePointColour, pointClouds, params.use3DPoints, renderScene]);

  useEffect(() => {
    updatePointCloudHighlight(
      params.hoveredPartUuid,
      params.overridePointColour,
      params.overrideColour,
      pointClouds,
      params.use3DPoints,
      renderScene
    );
  }, [
    params.hoveredPartUuid,
    params.overrideColour,
    params.overridePointColour,
    pointClouds,
    params.use3DPoints,
    renderScene,
  ]);

  useEffect(() => {
    toggleSimilarityGeometry(params, pointClouds, renderScene);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sourceGeometryEnabled, params.targetGeometryEnabled, pointClouds, renderScene]);

  useEffect(() => {
    if (viewportState === 'viewing' && params.rotation && Object.keys(params.rotation).length !== 0) {
      rotatePointCloud(params, pointClouds, renderScene);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.rotation, params.selectedParts, viewportState, pointClouds, pointClouds.numPointClouds, renderScene]);

  useEffect(() => {
    if (params.centerAllParts && viewportState === 'viewing') {
      toggleSimilarityRotationColours(params, pointClouds, renderScene);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportState, params.centerAllParts, params.selectedParts, pointClouds, renderScene]);
}
