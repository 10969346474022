import React, {forwardRef, useState, useRef, useEffect, Ref, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {Grid, TablePagination, Paper} from '@material-ui/core';
import styled from 'styled-components';
import {humanize, pluralize, underscore} from 'inflection';
import MaterialTable from 'material-table';
import {useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';
import {RootState} from '../../../store/reducers';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import {materialTableIcons} from '../../../components/material-table-icons';
import tableSchemas from './tableSchemas';
import {MaterialTableProps} from 'material-table';
import {ResourceTypes, FiltersState, UseDataFn, SortDirection} from './IndexPage';

export const BASE_TABLE_OPTIONS: {[key: string]: any} = {
  emptyRowsWhenPaging: false,
  toolbar: false,
  pageSize: 10,
  pageSizeOptions: [5, 10, 15, 25, 50, 100],
  search: false,
  showTitle: false,
  tableLayout: 'fixed',
  actionsCellStyle: {padding: '0px'},
  headerStyle: {
    backgroundColor: '#ECF6FF',
  },
};
interface IndexTableProps {
  resourceType: ResourceTypes;
  filters?: FiltersState;
  useData: UseDataFn;
  handlePageChange: (newPageNumber: number) => void;
  handleRowsPerPageChange: (rowsPerPage: number) => void;
  handleSortOrderChange: (field: string, sortDirection: SortDirection | null) => void;
  tableOptions?: MaterialTableProps<any>['options'];
  tableActions?: MaterialTableProps<any>['actions'];
  onRowClick?: (
    event: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: {[key: string]: any; onClickUrl: string} | undefined
  ) => void;
  minTableWidth?: string;
  tableProps?: Partial<MaterialTableProps<any>>;
  ignoreColumns?: string[];
}

const defaultColumns = (schema: any, ignoreColumns: string[]) => {
  const newColumns: any[] = cloneDeep(schema);

  newColumns.forEach((column: any, index: number) => {
    const {headerStyle = {}, cellStyle = {}} = column;

    newColumns[index].cellStyle = {padding: '12px', ...cellStyle};
    newColumns[index].headerStyle = {padding: '12px', ...headerStyle};
  });

  return newColumns.filter((column) => !ignoreColumns.includes(column.field));
};

function IndexTable({
  resourceType,
  filters,
  useData,
  handlePageChange,
  handleRowsPerPageChange,
  handleSortOrderChange,
  onRowClick,
  tableOptions,
  tableActions,
  minTableWidth,
  tableProps = {},
  ignoreColumns = [],
}: IndexTableProps) {
  const history = useHistory();
  const [columns, setColumns] = useState(defaultColumns(tableSchemas[`${resourceType}TableSchema`], ignoreColumns));
  const tableRef = useRef<any>();
  const listFetched = useSelector((state: RootState) => {
    const store = state[`${resourceType}Store` as keyof RootState] as any;
    return store.listFetched;
  });
  const listTotal = useSelector((state: RootState) => {
    const store = state[`${resourceType}Store` as keyof RootState] as any;
    return store.listTotal;
  });
  const tableData = useData();

  const isLoading = [FetchingState.Fetching, FetchingState.None].includes(listFetched);
  const hasClickableRows = tableData.some((data) => !!data.onClickUrl);

  // Set initial sort based of URL params
  useEffect(() => {
    const sort = Object.entries(filters?.sortBy || {});

    const newColumns = columns.map((column: any) => {
      delete column.defaultSort;
      return column;
    });

    if (sort.length) {
      const [field, direction] = sort[0];
      const columnIndex = newColumns.findIndex((column: any) => column.sortField === field || column.field === field);
      newColumns[columnIndex].defaultSort = direction?.toLowerCase();
      setColumns(columns);
    }

    setColumns(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onSortChange = (columnIndex: number, direction: 'asc' | 'desc') => {
    const SortDirection = direction === 'asc' ? 'ASC' : 'DESC';

    if (columnIndex < 0) {
      const field = columns.find((column: any) => !!column.defaultSort);
      handleSortOrderChange(field, null);
    } else {
      const field = columns[columnIndex].sortField || columns[columnIndex].field;
      handleSortOrderChange(field, SortDirection);
    }
  };

  const ContainerOverride = useCallback(
    (props) => <TableContainer {...props} $minTableWidth={minTableWidth || `${columns.length * 124}px`} />,
    [minTableWidth, columns.length]
  );

  const handleRowUrlClick = (
    event: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: {[key: string]: any; onClickUrl: string} | undefined
  ) => {
    if (rowData && rowData.onClickUrl) {
      if (event?.ctrlKey) {
        window.open(rowData.onClickUrl, '_blank');
      } else {
        history.push(rowData.onClickUrl);
      }
    }
  };

  return (
    <TableGrid item xs={12}>
      <MaterialTable
        tableRef={tableRef}
        columns={columns.map((column: any) => ({
          ...column,
          customSort: () => {},
          defaultColumns,
        }))}
        data={tableData as any}
        icons={materialTableIcons}
        onRowClick={onRowClick || (hasClickableRows ? handleRowUrlClick : undefined)}
        onOrderChange={onSortChange}
        isLoading={isLoading}
        components={{
          Container: ContainerOverride,
          Pagination: forwardRef((props, ref: Ref<HTMLAnchorElement>) => (
            <TablePagination
              {...props}
              ref={ref}
              rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
              rowsPerPage={filters?.take || 10}
              count={listTotal || 0}
              page={filters?.page || 0}
              onChangePage={(event, newPageNumber) => {
                handlePageChange(newPageNumber);
                tableRef?.current?.onChangePage(event, newPageNumber);
              }}
              onChangeRowsPerPage={(event) => {
                handleRowsPerPageChange(parseInt(event.target.value));
                tableRef?.current?.onChangeRowsPerPage(event);
              }}
              labelRowsPerPage="Rows per page:"
            />
          )),
        }}
        actions={tableActions}
        options={{
          ...BASE_TABLE_OPTIONS,
          pageSize: filters?.take || 10,
          actionsColumnIndex: tableActions ? -1 : undefined,
          ...tableOptions,
        }}
        localization={{
          body: {
            // We must underscore before humanize to convert multiple word camel case into humanized form
            emptyDataSourceMessage: isLoading
              ? ''
              : `No ${pluralize(humanize(underscore(resourceType), true))} to display`,
          },
        }}
        {...tableProps}
      />
    </TableGrid>
  );
}

export default IndexTable;

export const TableContainer = styled(Paper)<{$minTableWidth?: string}>`
  div:nth-of-type(1) {
    table {
      min-width: ${({$minTableWidth}) => $minTableWidth};
    }
  }
  div:nth-of-type(0) {
    .MuiTableRow-root:hover {
      background: #eaf2fa !important;
    }
  }

  .MuiTableRow-root {
    button {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .MuiTablePagination-toolbar {
    button {
      padding: 0px !important;
    }
    min-height: 42px;
  }

  .MuiTablePagination-root {
    padding: 8px 0px;
  }

  .MuiCheckbox-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media (max-width: 600px) {
    .MuiTableCell-root {
      padding: 8px;
    }
    td {
    }
  }
`;

const TableGrid = styled(Grid)`
  margin-top: 24px;
  overflow: hidden;

  .MuiPaper-root {
    height: 100%;
  }

  .MuiPaper-root > div:first-child {
    height: calc(100% - 40px);
  }
`;
