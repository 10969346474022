import React, {useEffect, useState} from 'react';
import {Card, CardContent, CardHeader, Collapse, Grid, IconButton, Typography} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';

import {IBuild} from '@common/api/models/builds/IBuild';
import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {
  IMachineParameter,
  MACHINE_PARAMETER_DEFAULT_FIELDS,
  MachineParameterDefaultKeys,
} from '@common/api/models/devices/machines/machineParameters/IMachineParameter';

import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {Link} from 'react-router-dom';
import {
  useBatchStoreActions,
  useDeviceStoreActions,
  useMachineParameterStoreActions,
  useMachineStoreActions,
} from '../../../store/actions';

import {useSmallScreenSize, usePermissionsForBuild} from '../../../utils/utilHooks';

interface BuildMetaCardProps {
  build: IBuild;
}

export function BuildMetaCard({build}: BuildMetaCardProps) {
  const isSmall = useSmallScreenSize();
  /* Machine */
  const machineStoreActions = useMachineStoreActions();
  const machineStore = useSelector((state: RootState) => state.machineStore);
  const machine = machineStore.byId[build.machineUuid!];

  /* Device */
  const deviceStoreActions = useDeviceStoreActions();
  const deviceStore = useSelector((state: RootState) => state.deviceStore);
  const device = deviceStore.byId[build.deviceSerial!];

  /* Batch */
  const batchStoreActions = useBatchStoreActions();
  const batchStore = useSelector((state: RootState) => state.batchStore);
  const batch = batchStore.byId[build.batchUuid!];
  const currentOrSuccessorUuid = (batch: IBatch) => {
    return batch.successorAUuid || batch.successorBUuid || batch.uuid;
  };

  /* Machine parameters */
  const machineParametersStoreActions = useMachineParameterStoreActions();
  const machineParametersStore = useSelector((state: RootState) => state.machineParameterStore);
  const machineParameters = machineParametersStore.byId[build.machineParametersUuid!];

  const {machinePermission} = usePermissionsForBuild(build);

  useEffect(() => {
    if (build.machineUuid) {
      machineStoreActions.ensureConsistent({uuid: build.machineUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.machineUuid]);

  useEffect(() => {
    if (build.deviceSerial) {
      deviceStoreActions.ensureConsistent({serial: build.deviceSerial});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.deviceSerial]);

  useEffect(() => {
    if (build.batchUuid) {
      batchStoreActions.ensureConsistent({uuid: build.batchUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.batchUuid]);

  useEffect(() => {
    if (build.machineParametersUuid) {
      machineParametersStoreActions.ensureConsistent({
        uuid: build.machineParametersUuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.machineParametersUuid]);

  return (
    <Card style={{padding: isSmall ? '7px' : '15px'}}>
      <CardHeader title="Build Metadata" style={{paddingBottom: 0}} />

      <CardContent>
        <Grid container spacing={isSmall ? 1 : 2}>
          <FieldValue field="Completed Layers" value={`${build.lastPrintedLayerId}`} isSmall={isSmall} />
          <FieldValue field="Total Layers" value={`${build.numLayers}`} isSmall={isSmall} />
          <FieldValue
            field="Machine"
            value={!machinePermission ? '******' : machine && machine.name}
            isSmall={isSmall}
          />
          <FieldValue
            field="Device"
            value={!machinePermission ? '******' : device && device.deviceId}
            isSmall={isSmall}
          />
          <FieldValue
            field="Batch"
            value={
              batch && (
                <Link to={`/materials/batches/uuid/${currentOrSuccessorUuid(batch)}/timeline/`}>{batch.name}</Link>
              )
            }
            isSmall={isSmall}
          />
          <FieldValue
            field="Machine Parameters"
            value={
              machineParameters && <MachineParametersCollapse machineParameters={machineParameters} isSmall={isSmall} />
            }
            isLoadable={false}
            isSmall={isSmall}
          />

          <FieldValue
            field="Description"
            value={
              build.description && (
                <>
                  {build.description.split(/\\n|\\\\n/).map((line) => (
                    <Typography>{line.trim()}</Typography>
                  ))}
                </>
              )
            }
            isLoadable={false}
            isSmall={isSmall}
          />
        </Grid>
      </CardContent>
    </Card>
  );
}

function MachineParametersCollapse({
  machineParameters,
  isSmall,
}: {
  machineParameters: IMachineParameter;
  isSmall: boolean;
}) {
  const [parametersExpanded, setParametersExpanded] = useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {machineParameters!.name}
        <IconButton
          onClick={() => setParametersExpanded(!parametersExpanded)}
          aria-expanded={parametersExpanded}
          aria-label="show more"
          size="small"
          // CSS overrides are to align icon with text
          style={{height: 17, width: 17, marginLeft: '10px'}}
        >
          {parametersExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={parametersExpanded}>
          <Grid container spacing={1} style={{marginBottom: '12px'}}>
            {/* Parameter notes */}
            {machineParameters.genericNotes && (
              <>
                <Grid item xs={isSmall ? 6 : 3}>
                  <Typography variant="subtitle2">Notes</Typography>
                </Grid>
                <Grid item xs={isSmall ? 6 : 9}>
                  {machineParameters.genericNotes}
                </Grid>
              </>
            )}

            {/* Parameter values - built-in */}
            {MACHINE_PARAMETER_DEFAULT_FIELDS.map(
              ([key, name, unit]) =>
                machineParameters.data[key] && (
                  <>
                    <Grid item xs={isSmall ? 6 : 3}>
                      <Typography variant="subtitle2">{name}</Typography>
                    </Grid>
                    <Grid item xs={isSmall ? 6 : 9}>
                      {machineParameters.data[key]} {unit}
                    </Grid>
                  </>
                )
            )}

            {/* Parameter values - custom */}
            {Object.keys(machineParameters.data)
              // Get keys that are not default
              .filter((key) => !(Object.values(MachineParameterDefaultKeys) as string[]).includes(key))
              .map((key) => (
                <>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">{key}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    {machineParameters.data[key]}
                  </Grid>
                </>
              ))}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
}

function FieldValue({
  field,
  value,
  isLoadable = true,
  isSmall,
}: {
  field: string;
  value?: string | JSX.Element | false;
  isLoadable?: boolean;
  isSmall: boolean;
}) {
  return (
    <>
      <Grid item xs={isSmall ? 5 : 2}>
        <Typography variant="subtitle2">{field}</Typography>
      </Grid>
      <Grid item xs={isSmall ? 7 : 10}>
        {value ||
          (isLoadable ? (
            <Skeleton width="100%" />
          ) : (
            <Typography>
              <i>None</i>
            </Typography>
          ))}
      </Grid>
    </>
  );
}
