import React, {useEffect, useState} from 'react';
import {
  Box,
  Divider as MuiDivider,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  styled,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {spacing} from '@material-ui/system';
import {useSelector} from 'react-redux';
import {cloneDeep, set} from 'lodash';

import {BuildState} from '@common/api/models/builds/IBuild';

import {usePermissionsForBuild} from '../../../utils/utilHooks';
import NoPermissionForDevice from '../../../components/molecules/NoPermissionForDevice';
import {SensorProfileType} from '../../../store/model/sensorProfile';
import {useSensorProfileStoreActions} from '../../../store/actions/index';
import {RootState} from '../../../store/reducers/index';
import ProfileForm from '../../machines/sensorProfiles/ProfileEditor/ProfileForm';
import BuildProfileToolbar from './BuildProfileToolbar';
import {NavLink, useParams} from 'react-router-dom';
import {DeviceState} from '@common/api/models/devices/IDevice';

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export enum DefaultCameraMode {
  CALIBRATION = 'params_calibration',
  FOCUS = 'params_focus',
  MONITOR = 'params_monitor',
  PREVIEWING = 'params_preview',
}

interface IBuildProfileConfig {
  defaultCameraMode?: DefaultCameraMode;
}

function BuildProfileConfig({defaultCameraMode}: IBuildProfileConfig) {
  const {uuid} = useParams<{uuid: string}>();
  const build = useSelector((state: RootState) => state.buildStore.byId[uuid!]);
  const {machinePermission} = usePermissionsForBuild(build);

  if (!build) return <></>;

  return (
    <Card>
      <CardHeader title="Build Sensor Profile" style={{paddingBottom: '0px'}} />
      {build?.sensorProfileUuid && (
        <Breadcrumbs aria-label="Breadcrumb" mt={2} ml={4}>
          <Link
            exact
            component={NavLink}
            target="_blank"
            rel="noopener noreferrer"
            to={`/machines/sensorProfiles/${build.sensorProfileUuid}`}
          >
            View original profile
          </Link>
        </Breadcrumbs>
      )}
      <CardContent>
        {!machinePermission ? (
          <NoPermissionForDevice mt={0}>
            Viewing or modifying Camera Parameters requires permission to access this build's machine.
          </NoPermissionForDevice>
        ) : !build.sensorProfileUuid ? (
          <Alert severity="warning">
            Cannot access profile management for old builds which do not use a sensor profile
          </Alert>
        ) : (
          <EditorSection defaultCameraMode={defaultCameraMode} />
        )}
      </CardContent>
    </Card>
  );
}

export default React.memo(BuildProfileConfig);

function EditorSection({defaultCameraMode}: IBuildProfileConfig) {
  const {uuid} = useParams<{uuid: string}>();
  const build = useSelector((state: RootState) => state.buildStore.byId[uuid!]);
  const [errors, setErrors] = useState<{[uuid: string]: boolean}>({});

  const sensorProfileActions = useSensorProfileStoreActions();

  const profile = useSelector((state: RootState) => state.sensorProfileStore.byId[build.sensorProfileUuid!]);
  const profileJSON = useSelector((state: RootState) => state.sensorProfileStore.buildProfiles[build.uuid]);
  const configDefaults = useSelector(
    (state: RootState) => state.sensorProfileStore.configDefaults[profile?.deviceSerial]
  );
  const device = useSelector((state: RootState) => state.deviceStore.byId[profile?.deviceSerial]);

  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    sensorProfileActions.ensureConsistent({
      uuid: build.sensorProfileUuid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.sensorProfileUuid]);

  useEffect(() => {
    if (profile?.deviceSerial && profile?.uuid) {
      sensorProfileActions.fetchConfigDefaults(profile.deviceSerial);
      sensorProfileActions.loadBuildSensorProfile(build.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    build.uuid,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    profile?.deviceSerial,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    profile?.uuid,
    build.currentConfigUrl,
  ]);

  function updateProfile(fieldPaths: string[], newValue: any, updatingProfile?: SensorProfileType) {
    const newProfile = cloneDeep(updatingProfile || profileJSON || {});

    fieldPaths.forEach((path) => set(newProfile, path, newValue));

    sensorProfileActions.setSensorProfile(build.uuid, newProfile, {
      isBuild: true,
    });
  }

  const canEdit =
    [DeviceState.STAGING, DeviceState.IDLE].includes(device?.state) &&
    [BuildState.STAGING, BuildState.PAUSED].includes(build?.state);

  if (!profileJSON || !configDefaults)
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );

  return (
    <>
      {saved && (
        <Alert severity="success" style={{marginBottom: '16px'}}>
          Build profile has been updated. These changes will take affect when monitoring starts / resumes.
        </Alert>
      )}
      {savingError && (
        <Alert severity="error" style={{marginBottom: '16px'}}>
          Failed to save build profile. Please view the{' '}
          <Link to={`/machines/uuid/${build.machineUuid}/device_logs/`} component={NavLink}>
            device logs
          </Link>{' '}
          for more information.
        </Alert>
      )}
      {!canEdit && (
        <Alert severity="warning" style={{marginBottom: '16px'}}>
          Can only modify build sensor profile when the build is paused or staging and device is idle or staging.
        </Alert>
      )}
      {canEdit && !device.online && (
        <Alert severity="warning" style={{marginBottom: '16px'}}>
          Can only modify build sensor profile when device is online.
        </Alert>
      )}
      <ProfileForm
        disableSections={build.state === BuildState.STAGING ? [] : ['calibration']}
        configDefaults={configDefaults}
        profileJSON={profileJSON}
        errors={errors}
        setErrors={setErrors}
        updateProfile={updateProfile}
        readOnly={!canEdit || saving || !device.online}
        defaultCameraMode={defaultCameraMode}
        uuid={build.sensorProfileUuid!}
      />
      {canEdit && device.online && (
        <>
          <Divider my={4} />
          <BuildProfileToolbar
            build={build}
            hasError={Object.values(errors || {}).some((error) => !!error)}
            clearErrors={() => setErrors({})}
            saving={saving}
            setSaving={setSaving}
            setSavingError={setSavingError}
            setSaved={setSaved}
          />
        </>
      )}
    </>
  );
}
