import React from 'react';
import {Tooltip, makeStyles} from '@material-ui/core';

const useStyles = (width: number) =>
  makeStyles((_theme) => ({
    customWidth: {
      maxWidth: `min(90vw + 16px, ${width + 16}px)`,
    },
  }));

function BuildPhotoAvatar({
  signedUrl,
  pxSize = 30,
  tooltipPxSize = 500,
  imgStyle = {},
}: {
  pxSize?: number;
  signedUrl?: string;
  tooltipPxSize?: number;
  imgStyle?: React.CSSProperties;
}) {
  const styles = useStyles(tooltipPxSize)();
  if (!signedUrl) return <></>;

  return (
    <Tooltip
      classes={{tooltip: styles.customWidth}}
      title={<img src={signedUrl} alt="Build avatar" style={{width: `min(90vw, ${tooltipPxSize}px)`}} />}
    >
      <img
        src={signedUrl}
        alt="Build avatar"
        style={{height: pxSize, width: pxSize, borderRadius: pxSize, ...imgStyle}}
      />
    </Tooltip>
  );
}

export default BuildPhotoAvatar;
