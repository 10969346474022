import {
  AccountBox,
  Assessment,
  Gavel,
  Compare,
  Dashboard,
  Gradient,
  Router,
  Info,
  Group,
  SvgIconComponent,
} from '@material-ui/icons';
import {IUser, Role} from '@common/api/models/users/IUser';
import {OrganizationRoute} from './OrganizationRoute';
import {SupportPage} from '../pages/Support';
import {BatchesPage} from '../pages/materials/BatchesPage';
import {MaterialsPage} from '../pages/materials/MaterialsPage';
import {MachinesPage} from '../pages/machines/MachinesPage';
import {DevicesPage} from '../pages/machines/DevicesPage';
import {Redirect} from 'react-router-dom';
import * as React from 'react';
import SignIn from '../pages/auth/SignIn';
import {BuildsPage} from '../pages/builds/BuildsPage';
import {ViewBuild} from '../pages/builds/ViewBuild';
import CreateMachinePage from '../pages/machines/CreateMachine';
import ViewMachine from '../pages/machines/ViewMachine';
import {ViewMaterial} from '../pages/materials/ViewMaterial';
import UserPage from '../pages/organization/UserPage';
import CreateUserPage from '../pages/organization/CreateUserPage';
import {ViewBatch} from '../pages/materials/batchPages/ViewBatch';
import {CreateMaterialPage} from '../pages/materials/CreateMaterialPage';
import OrganizationPage from '../pages/organization/OrganizationPage';
import {OrganizationsPage} from '../pages/organization/OrganizationsPage';
import CreateOrganizationPage from '../pages/organization/CreateOrganizationPage';
import CreateDevice from '../pages/machines/CreateDevice';
import {EmailConfirmed} from '../pages/auth/EmailConfirmed';
import {ExpiredEmailConfirmation} from '../pages/auth/ExpiredEmailConfirmation';
import {InvalidEmailConfirmation} from '../pages/auth/InvalidEmailConfirmation';
import BuildSettings from '../pages/builds/BuildSettings';
import {SliceHistoryPage} from '../pages/builds/SliceHistoryPage';
import SimilarityListPage from '../pages/similarityReport/SimilarityListPage';
import SimilarityCreatePage from '../pages/similarityReport/SimilarityCreatePage';
import SimilarityResultsPage from '../pages/similarityReport/SimilarityResultsPage';
import BuildIntensityListPage from '../pages/buildIntensityReport/BuildIntensityPage';
import {isWidthDown} from '@material-ui/core';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';

import envConfig from '../envConfig';
import DashboardPage from '../pages/dashboard/DashboardPage';
import SensorProfilesPage from '../pages/machines/SensorProfilesPage';
import {UsersPage} from '../pages/organization/UsersPage';
import BuildReportListPage from '../pages/buildReport/BuildReportListPage';
import LocationBasedDefectsReportPage from '../pages/locationBasedDefectsReport/LocationBasedDefectsReportPage';

export enum Layout {
  DASHBOARD = 'DASHBOARD',
  AUTH = 'AUTH',
}

export interface ChildRoute {
  path: string;
  name: string;
  component: any;
  hideOnDesktopBar?: boolean;
  hideOnMobileBar?: boolean;
  pageHasBottomToolbar?: (params: any, width: Breakpoint) => boolean;
}

interface BaseRoute {
  path: string;
  paths?: string[]; // only used to check isActive.
  id: string;
  header?: string;
  icon: SvgIconComponent;
  layout: Layout;
  rtlName: string;
  hideOnDesktopBar?: boolean;
  hideOnMobileBar?: boolean;
  pageHasBottomToolbar?: (params: any, width: Breakpoint) => boolean;
}

export interface FamilyRoute extends BaseRoute {
  children: ChildRoute[];
}

export interface SingleRoute extends BaseRoute {
  component: any;
}

export type Route = FamilyRoute | SingleRoute;

function dashboardRoutes(loggedInUser: IUser): Route[] {
  const result: Route[] = [];
  const effectiveRule = loggedInUser?.role || Role.NONE;

  result.push({
    path: '/',
    id: '/',
    rtlName: '/',
    icon: Dashboard,
    component: () => <Redirect to="/dashboard/" />,
    layout: Layout.DASHBOARD,
    hideOnDesktopBar: true,
    hideOnMobileBar: true,
  });

  result.push(
    {
      path: '/emailConfirmed/',
      id: '/emailConfirmed/',
      rtlName: '/emailConfirmed/',
      icon: Dashboard,
      component: EmailConfirmed,
      layout: Layout.AUTH,
      hideOnDesktopBar: true,
      hideOnMobileBar: true,
    },
    {
      path: '/expiredEmailConfirmationToken/',
      id: '/expiredEmailConfirmationToken/',
      rtlName: '/expiredEmailConfirmationToken/',
      icon: Dashboard,
      component: ExpiredEmailConfirmation,
      layout: Layout.AUTH,
      hideOnDesktopBar: true,
      hideOnMobileBar: true,
    },
    {
      path: '/invalidEmailConfirmationToken/',
      id: '/invalidEmailConfirmationToken/',
      rtlName: '/invalidEmailConfirmationToken/',
      icon: Dashboard,
      component: InvalidEmailConfirmation,
      layout: Layout.AUTH,
      hideOnDesktopBar: true,
      hideOnMobileBar: true,
    }
  );

  result.push({
    path: '/dashboard/',
    id: 'Dashboard',
    header: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage,
    layout: Layout.DASHBOARD,
    rtlName: 'Dashboard',
  });

  if (effectiveRule === Role.MANAGER || effectiveRule === Role.ADMINISTRATOR) {
    result.push({
      path: '/users/',
      id: 'Organization',
      icon: Group,
      component: OrganizationRoute,
      layout: Layout.DASHBOARD,
      rtlName: 'Organization',
    });
  } else if (effectiveRule === Role.SUPERUSER) {
    result.push(
      {
        path: '/organizations/uuid/:uuid/',
        id: 'Organizations',
        rtlName: 'Organization',
        layout: Layout.DASHBOARD,
        icon: Group,
        component: OrganizationPage,
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
      },
      {
        path: '/organizations/createOrganization/',
        id: 'Organizations',
        rtlName: 'Organization',
        layout: Layout.DASHBOARD,
        icon: Group,
        component: CreateOrganizationPage,
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
      }
    );

    result.push({
      path: '/organization',
      paths: ['/organizations', '/users'],
      id: 'Organization',
      icon: Group,
      component: OrganizationRoute,
      layout: Layout.DASHBOARD,
      rtlName: 'Organization',
      children: [
        {
          path: '/users/',
          name: 'Users',
          component: UsersPage,
        },
        {
          path: '/organizations/',
          name: 'Organizations',
          component: OrganizationsPage,
        },
      ],
    });
  }

  if (effectiveRule >= Role.MANAGER) {
    result.push({
      path: '/users/createUser',
      id: 'Create User',
      icon: Group,
      component: CreateUserPage,
      layout: Layout.DASHBOARD,
      rtlName: 'Organization',
      hideOnDesktopBar: true,
      hideOnMobileBar: true,
    });
  }

  if (effectiveRule >= Role.TECHNICIAN) {
    if (effectiveRule < Role.MANAGER) {
      result.push({
        path: '/users/',
        id: 'Users',
        icon: Dashboard,
        component: UsersPage,
        layout: Layout.DASHBOARD,
        rtlName: 'Users',
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
      });
    }

    result.push(
      {
        path: '/users/uuid/:uuid',
        id: 'User',
        icon: Dashboard,
        component: UserPage,
        layout: Layout.DASHBOARD,
        rtlName: 'User',
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
      },
      envConfig.showSliceHistory
        ? {
            header: 'Operations',
            path: '/builds/',
            id: 'Builds',
            icon: Gavel,
            children: [
              {
                path: '/builds/',
                name: 'Builds',
                component: BuildsPage,
              },

              {
                path: '/sliceHistory/',
                name: 'Slice History',
                component: SliceHistoryPage,
              },
            ],
            layout: Layout.DASHBOARD,
            rtlName: 'Builds',
          }
        : {
            header: 'Operations',
            path: '/builds/',
            id: 'Builds',
            icon: Gavel,
            component: BuildsPage,
            layout: Layout.DASHBOARD,
            rtlName: 'Builds',
          },
      {
        path: '/builds/uuid/:uuid',
        id: 'Build',
        icon: Gavel,
        component: ViewBuild,
        layout: Layout.DASHBOARD,
        rtlName: 'Builds',
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
      },

      {
        path: '/builds/uuid/:uuid/draft/:draftType?/:draftStep?',
        id: 'Build Draft',
        icon: Gavel,
        component: ViewBuild,
        layout: Layout.DASHBOARD,
        rtlName: 'Build Draft',
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
        pageHasBottomToolbar: (params: any, width: Breakpoint) =>
          params.draftType === 'advanced' && isWidthDown('sm', width),
      },

      {
        path: '/builds/uuid/:uuid/live/:liveStep',
        id: 'Build Draft',
        icon: Gavel,
        component: ViewBuild,
        layout: Layout.DASHBOARD,
        rtlName: 'Build Draft',
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
        pageHasBottomToolbar: (params: any, width: Breakpoint) => {
          return (
            [
              'monitor',
              'calibrate',
              'focus',
              'defects',
              'overview',
              'viewports',
              'general',
              'alerts',
              'reports',
            ].includes(params.liveStep) && isWidthDown('sm', width)
          );
        },
      },

      {
        path: '/builds/uuid/:uuid/settings',
        id: 'Build Draft',
        icon: Gavel,
        component: BuildSettings,
        layout: Layout.DASHBOARD,
        rtlName: 'Build Draft',
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
        pageHasBottomToolbar: (_params: any, width: Breakpoint) => isWidthDown('sm', width),
      },

      {
        path: '/machines/',
        id: 'Machines',
        icon: Router,
        children: [
          {
            path: '/machines/',
            name: 'Machines',
            component: MachinesPage,
          },

          {
            path: '/machines/devices/',
            name: 'Devices',
            component: DevicesPage,
          },
          {
            path: '/machines/sensorProfiles/',
            name: 'Sensor Profiles',
            component: SensorProfilesPage,
            pageHasBottomToolbar: (_params: any, width: Breakpoint) => isWidthDown('sm', width),
          },
          {
            path: '/machines/sensorProfiles/:uuid/',
            name: 'Sensor Profiles',
            component: SensorProfilesPage,
            hideOnDesktopBar: true,
            hideOnMobileBar: true,
            pageHasBottomToolbar: (_params: any, width: Breakpoint) => isWidthDown('sm', width),
          },
          {
            path: '/machines/sensorProfiles/:uuid/:deviceSerial/',
            name: 'Sensor Profiles',
            component: SensorProfilesPage,
            hideOnDesktopBar: true,
            hideOnMobileBar: true,
            pageHasBottomToolbar: (_params: any, width: Breakpoint) => isWidthDown('sm', width),
          },
        ],
        layout: Layout.DASHBOARD,
        rtlName: 'Machines',
      },

      {
        path: '/machines/devices/createDevice/',
        id: 'Create Device',
        icon: Router,
        component: CreateDevice,
        layout: Layout.DASHBOARD,
        rtlName: 'Create Device',
        hideOnDesktopBar: true,
        hideOnMobileBar: true,
      },

      {
        path: '/machines/createMachine/',
        id: 'Machines Create',
        icon: Router,
        component: CreateMachinePage,
        layout: Layout.DASHBOARD,
        rtlName: 'Machines Create',
        hideOnDesktopBar: true,
        hideOnMobileBar: true,
      },

      {
        path: '/machines/uuid/:uuid/',
        id: 'Machines View',
        icon: Router,
        component: ViewMachine,
        layout: Layout.DASHBOARD,
        rtlName: 'Machines View',
        hideOnDesktopBar: true,
        hideOnMobileBar: true,
      },

      {
        path: '/machines/uuid/:uuid/:page/',
        id: 'Machines View',
        icon: Router,
        component: ViewMachine,
        layout: Layout.DASHBOARD,
        rtlName: 'Machines View',
        hideOnDesktopBar: true,
        hideOnMobileBar: true,
      },

      {
        path: '/materials/create/',
        id: 'Materials Create',
        icon: Gradient,
        component: CreateMaterialPage,
        layout: Layout.DASHBOARD,
        rtlName: 'Materials Create',
        hideOnDesktopBar: true,
        hideOnMobileBar: true,
      },

      {
        path: '/materials/',
        id: 'Materials',
        icon: Gradient,
        layout: Layout.DASHBOARD,
        rtlName: 'Materials',
        children: [
          {
            path: '/materials/',
            name: 'Materials',
            component: MaterialsPage,
          },
          {
            path: '/materials/batches/',
            name: 'Batches',
            component: BatchesPage,
          },

          {
            path: '/materials/batches/uuid/:uuid/',
            name: 'Batches',
            component: ViewBatch,
            hideOnDesktopBar: true,
            hideOnMobileBar: true,
          },

          {
            path: '/materials/batches/uuid/:uuid/:page/',
            name: 'Batches',
            component: ViewBatch,
            hideOnDesktopBar: true,
            hideOnMobileBar: true,
          },
        ],
      },

      {
        path: '/materials/uuid/:uuid/',
        id: 'Materials View',
        icon: Gradient,
        component: ViewMaterial,
        layout: Layout.DASHBOARD,
        rtlName: 'Materials View',
        hideOnDesktopBar: true,
        hideOnMobileBar: true,
      },

      {
        path: '/reports/',
        id: 'Reports',
        icon: Assessment,
        layout: Layout.DASHBOARD,
        rtlName: 'Reports',
        children: [
          {
            path: '/reports/buildReport/',
            component: BuildReportListPage,
            name: 'Build Reports',
          },
          {
            path: '/reports/similarity/',
            component: SimilarityListPage,
            name: 'Similarity Reports',
          },
          {
            path: '/reports/intensity/',
            component: BuildIntensityListPage,
            name: 'Intensity Reports',
          },
          {
            path: '/reports/locationBasedDefects/',
            component: LocationBasedDefectsReportPage,
            name: 'Location Based Defects',
            pageHasBottomToolbar: (_params: any, width: Breakpoint) => isWidthDown('sm', width),
          },
          {
            path: '/reports/locationBasedDefects/:uuid?',
            component: LocationBasedDefectsReportPage,
            hideOnDesktopBar: true,
            hideOnMobileBar: true,
            name: 'Location Based Defects',
            pageHasBottomToolbar: (_params: any, width: Breakpoint) => isWidthDown('sm', width),
          },
        ],
      },

      {
        path: '/reports/similarity/create/:step/:sourcePartUuids?/:rotations?',
        id: 'Create Similarity Report',
        icon: Compare,
        component: SimilarityCreatePage,
        layout: Layout.DASHBOARD,
        rtlName: 'Create Similarity Report',
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
        pageHasBottomToolbar: (_params: any, width: Breakpoint) => isWidthDown('sm', width),
      },
      {
        path: '/reports/similarity/uuid/:uuid',
        id: 'Similarity Report',
        icon: Compare,
        component: SimilarityResultsPage,
        layout: Layout.DASHBOARD,
        rtlName: 'Similarity Report Results',
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
        pageHasBottomToolbar: (_params: any, width: Breakpoint) => isWidthDown('sm', width),
      },
      {
        path: '/reports/similarity/uuid/:uuid/:step',
        id: 'Similarity Report',
        icon: Compare,
        component: SimilarityResultsPage,
        layout: Layout.DASHBOARD,
        rtlName: 'Similarity Report Results',
        hideOnMobileBar: true,
        hideOnDesktopBar: true,
        pageHasBottomToolbar: (_params: any, width: Breakpoint) => isWidthDown('sm', width),
      },

      {
        path: '/support/',
        id: 'Support',
        header: 'Help',
        icon: Info,
        component: SupportPage,
        layout: Layout.DASHBOARD,
        rtlName: 'Support',
      }
    );
  }

  if (envConfig.isOnPremise) {
    result.push({
      path: '/login',
      id: 'Login',
      icon: AccountBox,
      component: SignIn,
      layout: Layout.AUTH,
      rtlName: 'Login',
      hideOnDesktopBar: true,
      hideOnMobileBar: true,
    });
  }

  return result;
}

export default dashboardRoutes;
