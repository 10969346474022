import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Button, CircularProgress, Grid, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Add} from '@material-ui/icons';

import {ResourcePermissionType} from '@common/api/models/users/IResourcePermission';
import {IUser, Role} from '@common/api/models/users/IUser';
import {IMachine} from '@common/api/models/devices/machines/IMachine';
import {IBuild} from '@common/api/models/builds/IBuild';
import {IResourcePermission} from '@common/api/models/users/IResourcePermission';

import {useSmallScreenSize} from '../../../utils/utilHooks';
import {RootState} from '../../../store/reducers';
import {userSearchGET} from '../../../api/ajax/users';
import GenericTable from './GenericTable';
import SearchAndSelectUser, {SelectedUserType} from '../Selector/SearchAndSelectUser';
import SearchAndSelect from '../Selector/SearchAndSelect';
import {resourcePermissionDELETE, resourcePermissionPOST} from '../../../api/ajax/resourcePermissions';
import {GenericDialog} from '../DialogButton';
import {buildsAllGET} from '../../../api/ajax/builds';
import {machinesAllGET} from '../../../api/ajax/machines';
import {capitalise} from '../../../utils/string';

const getUserInfo = async (permissionList: IResourcePermission[], setUserDetails: (users: IUser[]) => void) => {
  if (permissionList.length === 0) return;

  const userInfoResponse = await userSearchGET('', {
    uuid: permissionList.map((permission) => permission.userUuid),
  });
  if (userInfoResponse.success) {
    setUserDetails(userInfoResponse.data);
  }
};

const getResourceInfo = async (
  permissionList: IResourcePermission[],
  resourceType: ResourcePermissionType,
  setResourceDetails: (resources: Array<IMachine | IBuild>) => void
) => {
  const permissionsForResourceType = permissionList.filter((permission) => permission.resourceType === resourceType);

  if (permissionsForResourceType.length === 0) return;

  const fetchFunction = resourceType === ResourcePermissionType.BUILD ? buildsAllGET : machinesAllGET;

  const resourceInfoResponse = await fetchFunction({
    uuid: {
      in: permissionsForResourceType.map((permission) => permission.resourceUuid),
    },
  });
  if (resourceInfoResponse.success) {
    setResourceDetails(resourceInfoResponse.data);
  }
};

interface IResourcePermissionTable {
  resourceType: ResourcePermissionType;
}

interface IUserPermissionTable extends IResourcePermissionTable {
  userUuid: string;
  resource?: never;
}

interface ISingleResourcePermissionTable extends IResourcePermissionTable {
  resource: IBuild | IMachine;
  userUuid?: never;
}

/**
 * Renders the permissions for a user, for a single resource type. Or for all users, for a single resource.
 *
 * @param resourceType Build or Machine.
 * @param user The user to render permissions for
 * @param resource The resource to render permissions for

 */
const ResourcePermissionsTable = ({
  resourceType,
  userUuid,
  resource,
}: IUserPermissionTable | ISingleResourcePermissionTable) => {
  const isSmallScreen = useSmallScreenSize();
  const [forcedUpdate, forceUpdate] = useReducer((x) => x + 1, 0);

  const [deletingPermission, setDeletingPermission] = useState<IResourcePermission | null>(null);
  const [deletingName, setDeletingName] = useState<string | null>(null);
  const [deleting, setDeleting] = useState<boolean>(false);

  const [userDetails, setUserDetails] = useState<IUser[]>();
  const [resourceDetails, setResourceDetails] = useState<Array<IBuild | IMachine>>();

  const currentUser = useSelector((state: RootState) => state.auth.user!);
  const permissionList = useSelector((state: RootState) => state.resourcePermissionStore.list);

  const filters = useMemo(() => {
    if (userUuid) return {userUuid: userUuid};
    if (resource) return {resourceUuid: resource.uuid, resourceType};
  }, [resource, resourceType, userUuid]);

  useEffect(() => {
    if (!userUuid) {
      getUserInfo(permissionList, setUserDetails);
    }
    if (!resource) {
      getResourceInfo(permissionList, resourceType, setResourceDetails);
    }
  }, [userUuid, resourceType, permissionList, resource]);

  const useResourcePermissions = useCallback(() => {
    // When displaying permissions on a user page, we split the permissions up by resource type.
    // The API call fetches and stores all permissions for the user, but this component only cares
    // about a single resource type, hence we filter based on that.
    const permissions = !!userUuid
      ? permissionList.filter((permission) => permission.resourceType === resourceType)
      : permissionList;

    return permissions.map((permission) => {
      const canDelete = canDeletePermission(currentUser!, resource, resourceType);

      const permissionUser = userDetails?.find((user) => user.uuid === permission.userUuid);

      const permissionResource = resourceDetails?.find((r) => r.uuid === permission.resourceUuid);

      const isProvisionerPermission =
        resourceType === ResourcePermissionType.BUILD &&
        ((!!resource ? resource : permissionResource) as IBuild)?.provisionerUuid === permission.userUuid;

      const permissionName = permissionUser
        ? `${permissionUser.firstName} ${permissionUser.lastName}`
        : permissionResource?.name;

      return {
        name: permissionName,
        isProvisionerPermission,
        onDelete:
          canDelete && permissionName
            ? () => {
                setDeletingPermission(permission);
                setDeletingName(permissionName);
              }
            : undefined,
      };
    });
  }, [permissionList, userUuid, currentUser, userDetails, resourceDetails, resource, resourceType]);

  const onDeletePermission = async () => {
    setDeleting(true);

    const res = await resourcePermissionDELETE(deletingPermission!.userUuid, deletingPermission!.uuid);

    setDeleting(false);

    if (res.success) {
      setDeletingName(null);
      setDeletingPermission(null);
      forceUpdate();
    }
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container item xs={12} justifyContent="space-between" alignItems="center">
          <Box pt={2} pr={2}>
            <Typography variant={isSmallScreen ? 'subtitle2' : 'h5'}>
              {!!resource ? 'Technician Access' : `${capitalise(resourceType)} Access`}
            </Typography>
          </Box>
          {!!userUuid && userUuid !== currentUser.uuid && (
            <AddResource resourceType={resourceType} userUuid={userUuid} forceUpdate={forceUpdate} />
          )}
          {!!resource && (
            <AddPermission resourceType={resourceType} resourceUuid={resource.uuid} forceUpdate={forceUpdate} />
          )}
        </Grid>
        <Alert severity="info" style={{marginTop: '12px', width: '100%'}}>
          These access permissions are for authorizing{' '}
          {userUuid ? `this user to view ${resourceType}s` : `technician users to view this ${resourceType}`}
        </Alert>
        {!!resource && (
          <Alert severity="info" style={{marginTop: '12px', width: '100%'}}>
            Admins and managers will already have access to this {resourceType}
          </Alert>
        )}
      </Grid>
      <GenericTable
        filteringEnabled={false}
        resourceType="resourcePermission"
        useData={useResourcePermissions}
        permanentFilters={filters}
        forcedRefresh={forcedUpdate}
        tableOptions={{paging: false}}
        defaultFilters={{take: undefined}}
        minTableWidth="300px"
      />
      <GenericDialog
        closeDialog={() => setDeletingPermission(null)}
        isOpen={!!deletingPermission}
        title={`Revoke access for ${deletingName}?`}
        content={`Are you sure you want to revoke ${resource ? `${deletingName}'s` : 'this users'} access to ${
          userUuid ? `${resourceType}: ${deletingName}` : `this ${resourceType}`
        }?`}
        onSuccess={onDeletePermission}
        requestInProgress={deleting}
      />
    </Grid>
  );
};

export default ResourcePermissionsTable;

const canDeletePermission = (
  currentUser: IUser,
  resource: IBuild | IMachine | undefined,
  resourceType: ResourcePermissionType
) => {
  if (resourceType === ResourcePermissionType.BUILD && !!resource) {
    const build = resource as IBuild;

    if (build.provisionerUuid === currentUser!.uuid) {
      return true;
    }
  }

  return currentUser!.role >= Role.MANAGER;
};

const AddPermission = ({
  resourceType,
  resourceUuid,
  forceUpdate,
}: {
  resourceType: ResourcePermissionType;
  resourceUuid: string;
  forceUpdate: () => void;
}) => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SelectedUserType>();
  const permissionList = useSelector((s: RootState) => s.resourcePermissionStore.list);

  const onAddUser = async () => {
    if (!selectedUser) return;

    setRequestInProgress(true);
    const res = await resourcePermissionPOST(selectedUser.uuid, {
      resourceUuid: resourceUuid,
      resourceType: resourceType,
    });
    setRequestInProgress(false);
    if (res.success) {
      setSelectedUser(undefined);
      forceUpdate();
    }
  };

  return (
    <Box display="flex" flex="1" pt={2} justifyContent="flex-end">
      <Box pr={2} flex={1} maxWidth="300px" minWidth="164px">
        <SearchAndSelectUser
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          filterOutUuids={permissionList.map((permission) => permission.userUuid)}
          queryFilters={{role: Role.TECHNICIAN}}
        />
      </Box>

      <Box minWidth="115px">
        <Button
          variant="contained"
          color="primary"
          onClick={onAddUser}
          startIcon={requestInProgress ? <CircularProgress size={20} /> : <Add />}
          disabled={!selectedUser || requestInProgress}
        >
          Add User
        </Button>
      </Box>
    </Box>
  );
};

const AddResource = ({
  resourceType,
  userUuid,
  forceUpdate,
}: {
  resourceType: ResourcePermissionType;
  userUuid: string;
  forceUpdate: () => void;
}) => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [selectedResource, setSelectedResource] = useState<IBuild | IMachine>();
  const permissionList = useSelector((s: RootState) => s.resourcePermissionStore.list);

  const onAddResource = async () => {
    if (!selectedResource) return;

    setRequestInProgress(true);
    const res = await resourcePermissionPOST(userUuid, {
      resourceUuid: selectedResource.uuid,
      resourceType: resourceType,
    });
    setRequestInProgress(false);
    if (res.success) {
      setSelectedResource(undefined);
      forceUpdate();
    }
  };

  const fetchSearch = async (search: string): Promise<IBuild[] | IMachine[]> => {
    const fetchFn = resourceType === ResourcePermissionType.BUILD ? buildsAllGET : machinesAllGET;

    const alreadyAssigned = permissionList
      .filter((permission) => permission.resourceType === resourceType)
      .map((permission) => permission.resourceUuid);

    const res = await fetchFn({
      uuid: {notIn: alreadyAssigned},
      name: {like: search},
      take: 5,
    });

    if (res.success) return res.data;
    else return [];
  };

  return (
    <Box display="flex" flex="1" pt={2} justifyContent="flex-end">
      <Box pr={2} flex={1} maxWidth="300px" minWidth="164px">
        <SearchAndSelect
          selected={selectedResource}
          setSelected={setSelectedResource}
          getSuggestionValue={(resource) => resource.name}
          isSelected={(resource) => resource.uuid === selectedResource?.uuid}
          fetchFunction={fetchSearch}
          label={capitalise(resourceType)}
        />
      </Box>

      <Box minWidth="115px">
        <Button
          variant="contained"
          color="primary"
          onClick={onAddResource}
          startIcon={requestInProgress ? <CircularProgress size={20} /> : <Add />}
          disabled={!selectedResource || requestInProgress}
        >
          Add {resourceType === ResourcePermissionType.BUILD ? 'Build' : 'Machine'}
        </Button>
      </Box>
    </Box>
  );
};
