import React, {useEffect, useState} from 'react';
import {TextField, Grid, InputAdornment, Typography} from '@material-ui/core';
import {humanize} from 'inflection';
import {DIFFERENT_VALUES_TEXT} from './ProfileForm';
import AntSwitch from '../../../../components/atoms/Switches/AntSwitch';

const POSITIVE_NUMBER_OR_EMPTY = /^(0|[1-9]\d*)(\.+\d*)?$/;
const INTEGER_FIELDS = ['gain', 'laser_off_timeout', 'layer_timeout'];

function parseValue(value: string) {
  return value.split('.')[1]?.length > 5 ? Math.floor(parseFloat(value) * 1e5) / 1e5 : parseFloat(value);
}

function NumberField({
  field,
  value,
  onChange,
  min,
  max,
  unit,
  error,
  setError,
  disabled,
  allValues,
  defaultValue,
}: {
  field: string;
  value: number | string;
  onChange: (newValue: number | null) => void;
  min: number;
  max: number;
  unit: string;
  error: boolean;
  setError: (error: boolean) => void;
  disabled: boolean;
  allValues: Array<string>;
  defaultValue: number | string;
}) {
  const isTimeoutOn = value !== -1;

  const [internalValue, setInternalValue] = useState(`${parseValue(`${value}`)}`);

  const [originalValue, setOriginalValue] = useState(value);

  useEffect(() => {
    if (value !== undefined && `${value}` !== internalValue) {
      setInternalValue(`${parseValue(`${value}`)}`);
    }
    setOriginalValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(newValue: string) {
    if (newValue === '') {
      setInternalValue('');
      onChange(null);
      return;
    }

    let value = parseValue(newValue);
    if (INTEGER_FIELDS.includes(field)) value = parseInt(`${value}`);
    if (!newValue.match(POSITIVE_NUMBER_OR_EMPTY)) return;

    const stringValue = INTEGER_FIELDS.includes(field) ? value.toFixed(0) : newValue;
    setInternalValue(isNaN(value) ? '' : stringValue);
    setOriginalValue(value);
    onChange(isNaN(value) ? null : value);
    setError(false);
  }

  function toggleTimeout() {
    let value = parseInt(`${originalValue === -1 ? defaultValue : originalValue}`);
    if (isTimeoutOn) {
      value = -1;
    }
    setInternalValue(`${value}`);
    setOriginalValue(value);
    onChange(value);
    setError(false);
  }

  // Handle min/max restrictions on blur
  function onBlur() {
    const floatValue = Number(value);

    if (
      value === null ||
      value === undefined ||
      value === '' ||
      isNaN(floatValue) ||
      floatValue < min ||
      floatValue > max
    ) {
      if (value === DIFFERENT_VALUES_TEXT) return;
      setError(true);
    } else {
      setError(false);
    }
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      {field === 'laser_off_timeout' && (
        <Grid container direction="row">
          <Grid item>
            <Typography>Pause build after timeout</Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item>
            <AntSwitch checked={isTimeoutOn} onChange={toggleTimeout} size="small" disabled={disabled} />
          </Grid>
        </Grid>
      )}
      {isTimeoutOn && (
        <TextField
          fullWidth
          type={value === DIFFERENT_VALUES_TEXT ? undefined : 'number'}
          disabled={disabled}
          size="small"
          error={error}
          variant="outlined"
          onBlur={onBlur}
          name={field}
          placeholder={humanize(field)}
          label={humanize(field)}
          style={{
            marginTop: field === 'laser_off_timeout' ? '20px' : 'unset',
          }}
          helperText={
            <>
              {`A number between ${min}-${max}`}
              {value === DIFFERENT_VALUES_TEXT && (
                <>
                  {allValues.map((value) => (
                    <>
                      <br />
                      {value}
                    </>
                  ))}
                </>
              )}
            </>
          }
          value={value === DIFFERENT_VALUES_TEXT ? DIFFERENT_VALUES_TEXT : internalValue}
          onChange={(e) => {
            // "." passes the regex but get's converted to NaN. If "." is entered, send "0." instead
            const newValue = e.target.value;
            if (value === DIFFERENT_VALUES_TEXT && newValue !== '.' && isNaN(newValue as any)) {
              handleChange('');
            }

            newValue === '.' ? handleChange('0.') : handleChange(newValue);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          FormHelperTextProps={{
            style: {
              marginLeft: 0,
            },
          }}
          InputProps={{
            inputProps: {
              step: INTEGER_FIELDS.includes(field) ? (field === 'laser_off_timeout' ? 10 : 1) : 0.1,
            },
            endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
          }}
        />
      )}
    </Grid>
  );
}

export default NumberField;
