import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Card,
  Box,
  Icon,
  CircularProgress,
} from '@material-ui/core';
import {FileCopy} from '@material-ui/icons';
import {isEqual, uniq} from 'lodash';
import {inflect} from 'inflection';
import {ChevronUp, ChevronRight} from 'react-feather';

import {IDevice} from '@common/api/models/devices/IDevice';
import {ISensorProfileGETResponse} from '@common/api/models/devices/ISensorProfile';

import {useSmallScreenSize} from '../../../utils/utilHooks';
import {RootState} from '../../../store/reducers/index';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import {useDeviceStoreActions} from '../../../store/actions/index';
import {useAsyncDispatch} from '../../../ReduxRoot';
import sensorProfileActions from '../../../store/actions/sensorProfileActions';
import NewProfileButton from './NewProfileButton';
import ValidationStatusChip from './ProfileEditor/ValidationStatusChip';

function SensorProfileList({setIsDrawerOpen}: {setIsDrawerOpen?: (isOpen: boolean) => void}) {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const profiles = useSelector((state: RootState) => Object.values(state.sensorProfileStore.byId));
  const deviceStoreActions = useDeviceStoreActions();

  const profileDeviceSerials = uniq(profiles?.map((profile) => profile.deviceSerial) || []);
  const deviceSerialsStringified = JSON.stringify(profileDeviceSerials);

  useEffect(() => {
    if (profileDeviceSerials.length) {
      deviceStoreActions.ensureConsistent({serial: {in: profileDeviceSerials}});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceSerialsStringified]);

  return (
    <Card style={{height: '100%', overflow: 'auto'}}>
      {profiles.length === 0 ? (
        <NoProfiles />
      ) : (
        <List style={{padding: 0}}>
          {profiles.map((profile) => (
            <ProfileListItem
              key={profile.uuid}
              selected={profile.uuid === selectedUuid}
              setIsDrawerOpen={setIsDrawerOpen}
              {...profile}
            />
          ))}
        </List>
      )}
    </Card>
  );
}

export default SensorProfileList;

function NoProfiles() {
  const failedToFetch = useSelector((state: RootState) => state.sensorProfileStore.fetched === FetchingState.Error);

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h3" style={{marginBottom: '32px'}}>
        {failedToFetch ? 'Failed to fetch profiles.' : 'No profiles have been created.'}
      </Typography>
      {!failedToFetch && <NewProfileButton />}
    </Box>
  );
}

interface ProfileListItemProps extends ISensorProfileGETResponse {
  selected?: boolean;
  setIsDrawerOpen?: (isOpen: boolean) => void;
}

function ProfileListItem({
  uuid,
  name,
  selected,
  buildsCount,
  state,
  deviceSerial,
  setIsDrawerOpen,
}: ProfileListItemProps) {
  const history = useHistory();
  const dispatch = useAsyncDispatch();
  const isSmallScreen = useSmallScreenSize();

  const [cloning, setCloning] = useState(false);

  const hasUnsavedChanges = useSelector(
    (state: RootState) =>
      !isEqual(state.sensorProfileStore.originalProfiles[uuid], state.sensorProfileStore.profiles[uuid])
  );
  const device = useSelector((state: RootState) => state.deviceStore.byId[deviceSerial]);

  const secondaryText = secondaryListItemText(buildsCount, device, device?.sensorProfileUuid === uuid);

  function onListItemClicked() {
    history.replace(`/machines/sensorProfiles/${uuid}/`);
    setIsDrawerOpen && setIsDrawerOpen(false);
  }

  async function onCloneClicked(event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) {
    event.stopPropagation();
    setCloning(true);
    const success = await dispatch(sensorProfileActions.cloneSensorProfile(uuid));

    if (success) {
      history.replace(`/machines/sensorProfiles/new/`);
    }
    setCloning(false);
  }

  return (
    <ListItem divider selected={selected} onClick={onListItemClicked} style={{cursor: 'pointer'}}>
      <ListItemText
        primary={
          <Box display="flex" justifyContent="space-between">
            <Typography style={{paddingRight: '12px', wordBreak: 'break-word'}}>{name}</Typography>
            <ValidationStatusChip state={state} hasUnsavedChanges={hasUnsavedChanges} isNew={uuid === 'new'} />
          </Box>
        }
        secondary={secondaryText}
        style={{paddingRight: '48px'}}
      />
      <ListItemSecondaryAction style={{display: 'flex'}}>
        {uuid !== 'new' && (
          <Tooltip title={<Typography>Clone Profile</Typography>}>
            <span>
              <IconButton edge="end" onClick={onCloneClicked} disabled={cloning}>
                {cloning ? <CircularProgress size={20} /> : <FileCopy />}
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Icon
          component="div"
          color={selected ? 'secondary' : undefined}
          style={{padding: '12px 0px 12px 12px', height: '36px', width: '36px'}}
        >
          {isSmallScreen ? <ChevronUp /> : <ChevronRight />}
        </Icon>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function secondaryListItemText(buildsCount: number, device: IDevice, isDefault: boolean) {
  return (
    <>
      {device && (
        <>
          {device.deviceId}
          {isDefault ? ' (default)' : ''}
          <br />
        </>
      )}
      Used on {buildsCount || 0} {inflect('Build', buildsCount || 0)}
    </>
  );
}
