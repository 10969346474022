import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {Box, CircularProgress, Grid, IconButton, TextField, Tooltip, Typography} from '@material-ui/core';
import {Clear, Edit, Save} from '@material-ui/icons';
import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import LaserState from '../liveBuild/shared/LaserState';
import CameraState from '../liveBuild/shared/CameraState';
import StatusChip from '../../../components/atoms/Status/StatusChip';
import SaveStateChip from '../draftBuild/SaveStateChip';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {buildsByUuidPATCH} from '../../../api/ajax/builds';
import {RootState} from '../../../store/reducers';
import {DeviceState} from '@common/api/models/devices/IDevice';

interface BuildHeaderProps {
  build: IBuild;
  buildNameSuffix?: string;
}

function BuildHeader({build, buildNameSuffix}: BuildHeaderProps) {
  const [isRenaming, setIsRenaming] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const onRename = async (newName: string) => {
    setIsRequesting(true);
    const success = await buildsByUuidPATCH(build.uuid, {name: newName});
    setIsRequesting(false);
    if (success) setIsRenaming(false);
  };

  return (
    <>
      {isRenaming ? (
        <EditMode
          buildName={build.name}
          onSave={({buildName}) => onRename(buildName)}
          onCancel={() => setIsRenaming(false)}
          isRequesting={isRequesting}
        />
      ) : (
        <ViewMode build={build} buildNameSuffix={buildNameSuffix} setIsRenaming={() => setIsRenaming(true)} />
      )}
    </>
  );
}

export default BuildHeader;

const ViewMode = ({build, buildNameSuffix, setIsRenaming}: BuildHeaderProps & {setIsRenaming: () => void}) => {
  const device = useSelector((state: RootState) =>
    build.deviceSerial ? state.deviceStore.byId[build.deviceSerial] : undefined
  );
  const isSmallScreen = useSmallScreenSize();
  const isDraft = build.state === BuildState.DRAFT;
  const isEngaged = build.state === BuildState.ACTIVE || device?.state === DeviceState.CALIBRATING;
  const isStaging = build.state === BuildState.PAUSED || build.state === BuildState.STAGING;

  return (
    <Box display="flex" alignItems="center" maxWidth="100%">
      <Typography variant="h4" noWrap>
        {build.name}
        {buildNameSuffix ? ` ${buildNameSuffix}` : ''}
      </Typography>
      <Tooltip title="Edit name">
        <IconButton size="small" onClick={setIsRenaming} style={{margin: '0px 6px'}}>
          <Edit />
        </IconButton>
      </Tooltip>
      {isDraft && !isSmallScreen ? <SaveStateChip /> : <StatusChip state={build.state} size="small" />}
      {(isEngaged || isStaging) && <CameraState deviceSerial={build.deviceSerial} />}
      {(isEngaged || isStaging) && <LaserState deviceSerial={build.deviceSerial} />}
    </Box>
  );
};

interface EditModeProps {
  buildName: string;
  onSave: (data: {buildName: string}) => void;
  onCancel: () => void;
  isRequesting: boolean;
}

const EditMode = ({buildName, onSave, onCancel, isRequesting}: EditModeProps) => {
  const {
    errors,
    control,
    handleSubmit: onSubmit,
  } = useForm<{buildName: string}>({
    mode: 'all',
    defaultValues: {buildName},
  });

  return (
    <Box display="flex" alignItems="center" maxWidth="100%">
      <Grid item style={{width: 'calc(100% - 76px)', maxWidth: '360px'}}>
        <form id="updateBuildName" onSubmit={onSubmit(onSave)}>
          <Controller
            name="buildName"
            control={control}
            render={({onChange, value, ref}) => {
              return (
                <TextField
                  fullWidth
                  autoFocus
                  variant="outlined"
                  size="small"
                  label="Report Name"
                  onKeyDown={(event) => event.key === 'Escape' && onCancel()}
                  inputRef={ref}
                  value={value}
                  error={!!errors.buildName}
                  onChange={(event) => onChange(event.target.value)}
                />
              );
            }}
          />
        </form>
      </Grid>
      <Grid item>
        {isRequesting ? (
          <CircularProgress size={20} style={{marginLeft: '6px'}} />
        ) : (
          <>
            <Tooltip title="Save">
              <IconButton size="small" onClick={onSubmit(onSave)} style={{marginBottom: '0px', marginTop: '0px'}}>
                <Save />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton size="small" onClick={onCancel} style={{marginBottom: '0px', marginTop: '0px'}}>
                <Clear />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Grid>
    </Box>
  );
};
