import axios from 'axios';
import * as qs from 'qs';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {
  BuildAttachmentsGETRequest,
  IBuildAttachmentsGETResponse,
} from '@common/api/models/attachments/IBuildAttachment';

export async function buildAttachmentsAllGET(
  filter: QueryFilter<BuildAttachmentsGETRequest>
): AjaxApiResult<IBuildAttachmentsGETResponse[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/buildAttachments/' + (query && '?' + query));
  }, 'Could not fetch buildAttachments');
}

export async function buildMasterPhotoPATCH(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.patch('/api/buildAttachments/' + uuid + '/masterPhoto');
  }, 'Could not update build photo');
}

export async function buildAttachmentByUuidDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/buildAttachments/${uuid}`);
  }, 'Could not delete build attachment');
}
