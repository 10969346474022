import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Chip, Tooltip, Typography} from '@material-ui/core';
import {clamp} from 'lodash';
import {AnalysisType3D, getPrettyDefectType} from '@common/api/models/builds/data/defects/IDefect';
import {SimilarityComparisonType} from '@common/api/models/builds/data/ISimilarity';

import {RootState} from '../../../store/reducers/index';
import SelectedDetailsTable, {TableRowType} from '../shared/SelectedDetailsTable';
import {turboColourMap, redBlueDivergentColourMap} from '../../../components/molecules/Viewport/3D/pointCloudLoader';
import AnalysisTypeHelper from '../../../components/molecules/Viewport/3D/AnalysisTypeHelper';
import {objMapValues} from '../../../utils/objectFunctions';
import {AnalysisTypeMap} from '../../../components/molecules/Viewport/3D/types';

const ComparisonDefectScores = () => {
  const defectScores = useSelector(
    (state: RootState) => state.similarityReportStore.currentlyViewingComparison?.defectScores
  );

  const rows: Array<TableRowType> | undefined = !!defectScores
    ? Object.entries(defectScores).map(([comparisonType, score]) =>
        result2Row(comparisonType as SimilarityComparisonType, score)
      )
    : undefined;

  return <SelectedDetailsTable rows={rows} tableType="comparison" minRowWidth="50%" />;
};

export default ComparisonDefectScores;

const result2Row = (comparisonType: SimilarityComparisonType, score: number | null) => {
  let value = score;
  const prettyName = getPrettyDefectType(comparisonType);
  const [backgroundColor, color] = getResultColor(comparisonType, score);

  if (!!value) {
    if (comparisonType === SimilarityComparisonType.intensity) {
      value = (1 - value) * 100;
    } else {
      value = value * 100;
    }
  }

  let stringValue = 'N/A';
  if (!!value) {
    stringValue = `${value.toFixed(2)}%`;
  }

  return {
    field: prettyName,
    value: !!value ? (
      <Tooltip title={<RowTooltip comparisonType={comparisonType} score={value} />}>
        <Chip
          label={stringValue}
          color="primary"
          style={{
            backgroundColor: backgroundColor,
            color: color,
            lineHeight: '24px',
          }}
          size="small"
        />
      </Tooltip>
    ) : (
      stringValue
    ),
  };
};

const getTextColour = (rgb: Array<number>) => {
  const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
  return brightness > 125 ? 'black' : 'white';
};

const getResultColor = (comparisonType: SimilarityComparisonType, score: number | null) => {
  if (!score) return ['', ''];

  if (comparisonType === SimilarityComparisonType.intensity) {
    const metric = clamp((1 - score) * 100, 0, 99);
    const rgb = turboColourMap[Math.floor(metric)];
    return [`rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`, getTextColour(rgb)];
  }

  const metric = clamp((score + 1) * 100, 0, 199);
  const rgb = redBlueDivergentColourMap[Math.floor(metric)];
  return [`rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`, getTextColour(rgb)];
};

const RowTooltip = ({comparisonType, score}: {comparisonType: SimilarityComparisonType; score: number}) => {
  let direction;
  if (score > 0) direction = 'more';
  if (score < 0) direction = 'less';

  const stringValue = Math.abs(score).toFixed(2);
  const prettyName = getPrettyDefectType(comparisonType);
  const selectedAnalyisTypes = objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>;

  let text = `Target part has ${stringValue}% ${direction} ${prettyName} than the source part`;
  if (score === 0) text = `Target part has the same amount of ${prettyName} as the source part`;

  if (comparisonType === SimilarityComparisonType.intensity) {
    text = `Additive Similarity Intensity Metric (ASIM) of ${score.toFixed(2)}`;
  }

  return (
    <Box display="flex" flexDirection="column" position="relative" alignItems="center">
      <Typography style={{width: '100%'}}>{text}</Typography>
      {comparisonType === SimilarityComparisonType.intensity && (
        <>
          <br />
          <Typography>
            The Additive Similarity Intensity Metric (ASIM) is a comparison of luminescence and contrast components of
            meltpool data. A value of 0% is the most similar, and 100% is the least similar.
          </Typography>
        </>
      )}
      <br />
      <AnalysisTypeHelper
        selectedAnalysisTypes={{
          ...selectedAnalyisTypes,
          [(AnalysisType3D as any)[comparisonType]]: true,
        }}
        backgroundColour="transparent"
        position="relative"
      />
    </Box>
  );
};
