import styled from 'styled-components';

export const SIDEBAR_WIDTH = 270;

type SidebarContainerProps = {stageHeight: number; sidebarOpen: boolean; sidebarOpenDelayed?: boolean};

export const SidebarContainer = styled.div<SidebarContainerProps>`
  position: relative;
  background-color: #0007;
  float: left;
  width: ${SIDEBAR_WIDTH}px;
  height: ${({stageHeight}) => stageHeight}px;
  margin-left: ${({sidebarOpen}) => (sidebarOpen ? 0 : -SIDEBAR_WIDTH)}px;
  transition: margin-left 0.2s;
  color: white;
  pointer-events: ${({sidebarOpen}) => (sidebarOpen ? 'all' : 'none')};
  z-index: ${({sidebarOpen, sidebarOpenDelayed = false}) => (sidebarOpen ? 1 : sidebarOpenDelayed ? 1 : -1)};
  overflow-y: auto;
`;
