import React, {useEffect, useRef, useState} from 'react';
import {Grid} from '@material-ui/core';
import {Alert, Skeleton} from '@material-ui/lab';
import useImage from 'use-image';
import {ICalibration} from '@common/api/models/devices/ICalibration';
import {calibrationPreviewUrlGET} from '../../../../../api/ajax/calibrations';
import {SelfManagedSingleImageViewport} from '../../../../../components/molecules/Viewport/2D/SelfManagedSingleImageViewport';
import BrightnessSlider from '../../../../../components/molecules/Viewport/2D/controls/BrightnessSlider';
import {BUTTON_HEIGHT, BUTTON_MARGIN} from '../../../../../components/molecules/Viewport/2D/utils';

const CalibrationViewport = ({latestCalibration, height}: {latestCalibration: ICalibration; height: number}) => {
  const [calibrationImageUrl, setCalibrationImageUrl] = useState<string>('');
  const viewportRef = useRef<HTMLDivElement | null>(null);
  const [loadingImageUrl, setLoadingImageUrl] = useState(false);
  const [calibrationImage, loaded] = useImage(calibrationImageUrl, 'anonymous');
  const [brightness, setBrightness] = useState(0);

  useEffect(() => {
    if (!latestCalibration) return;
    setLoadingImageUrl(true);

    calibrationPreviewUrlGET(latestCalibration.uuid).then((resp) => {
      if (resp.success && resp.data?.url) {
        setCalibrationImageUrl(resp.data.url);
      }
      setLoadingImageUrl(false);
    });
  }, [latestCalibration]);

  useEffect(() => {
    if (calibrationImage && loaded === 'loaded') {
      viewportRef?.current?.scrollIntoView();
    }
  }, [calibrationImage, loaded]);

  if ((!!calibrationImageUrl && loaded === 'loading') || loadingImageUrl) {
    return (
      <>
        <Grid item xs={12}>
          <Alert severity="info">Downloading calibration image...</Alert>
        </Grid>
        <Skeleton variant="rect" height={height - 80} style={{marginTop: '1rem'}} />
      </>
    );
  }

  if (!calibrationImageUrl || !calibrationImage) {
    return (
      <Grid item xs={12}>
        <Alert severity="warning">No calibration preview available for this build.</Alert>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} ref={viewportRef} style={{maxWidth: '100%', width: '100%'}}>
      <SelfManagedSingleImageViewport
        image={calibrationImage}
        mmPerPixel={latestCalibration.scale}
        imageWidthPixels={calibrationImage.width}
        imageHeightPixels={calibrationImage.height}
        height={height}
        // Convert -4-0 to 0-1 and 0-4 to 1-5
        brightness={brightness >= 0 ? brightness + 1 : (brightness + 4) / 4}
      >
        <BrightnessSlider
          brightness={brightness}
          onChange={(val: number) => setBrightness(val)}
          divPositionStyle={{
            left: BUTTON_MARGIN * 2,
            top: height - BUTTON_HEIGHT * 2,
            position: 'absolute',
          }}
        />
      </SelfManagedSingleImageViewport>
    </Grid>
  );
};

export default CalibrationViewport;
