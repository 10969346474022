import {Dispatch, SetStateAction} from 'react';
import {DeviceState} from '@common/api/models/devices/IDevice';
import {IBuild} from '@common/api/models/builds/IBuild';
import {ICalibration} from '@common/api/models/devices/ICalibration';
import {DefaultCameraMode} from '../buildSettings/BuildProfileConfig';
import {ViewType} from './ActiveBuild';
import {LayerImages} from './activeBuildPages/ViewportsPage';

export const BUILD_LONG_TRANSITION_TIMEOUT = 200_000;
export const BUILD_SHORT_TRANSITION_TIMEOUT = 80_000;
export interface LiveBuildProps {
  build: IBuild;
}

export interface LiveBuildDataProps extends LiveBuildProps {
  layerImages: LayerImages;
  cmLoadedImages: any;
  setCmLoadedImage: (newValue: {}) => void;
  totalLayers?: number;
  calibrationScale: number;
  fetchLayerData: (currentLayer: number) => void;
  viewType: ViewType;
  partialLayerNumbers: Set<number>;
}

export interface StagingBuildDataProps extends LiveBuildProps {
  hasCalibrated: boolean;
  latestCalibration?: ICalibration;
  waitingForCalibration: boolean;
  waitingForCalibrationToStop: boolean;
  setWaitingForCalibration: Dispatch<SetStateAction<boolean>>;
  setWaitingForCalibrationToStop: Dispatch<SetStateAction<boolean>>;
  isCurrentlyCalibrating: boolean;
  waitingForFocus: boolean;
  setWaitingForFocus: Dispatch<SetStateAction<boolean>>;
  waitingForPreview: boolean;
  setWaitingForPreview: Dispatch<SetStateAction<boolean>>;
  isCurrentlyFocusing: boolean;
  previewError: boolean;
  setPreviewError: React.Dispatch<React.SetStateAction<boolean>>;
  focusingError: boolean;
  setFocusingError: React.Dispatch<React.SetStateAction<boolean>>;
  calibrationError: boolean | undefined;
  setCalibrationError: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export type StagingStepComponent = (props: StagingBuildDataProps) => JSX.Element;

export type ActiveTabComponent = (props: LiveBuildDataProps) => JSX.Element;

export enum LiveStepState {
  FOCUS = 'focus',
  CALIBRATE = 'calibrate',
  OVERVIEW = 'overview',
  ALERTS = 'alerts',
}

export function getSettingsQueryString(state: LiveStepState, deviceState: DeviceState) {
  if (deviceState === DeviceState.PREVIEWING) return DefaultCameraMode.PREVIEWING;

  switch (state) {
    case LiveStepState.FOCUS:
      return DefaultCameraMode.FOCUS;
    case LiveStepState.CALIBRATE:
      return DefaultCameraMode.CALIBRATION;
    case LiveStepState.OVERVIEW:
      return DefaultCameraMode.MONITOR;
  }
}
