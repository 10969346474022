import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {RotationMap} from '@common/api/models/builds/data/ISimilarity';

import {RootState} from '../../../store/reducers/index';
import PartPreview3DViewport from '../../../components/molecules/Viewport/3D/PartPreview3DViewport';
import {useContainerHeight} from '../../../utils/utilHooks';
import {SimilarityStep} from '../SimilarityCreatePage';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';

function viewingParts(
  type: SimilarityStep,
  currentlyViewingPart: IPartGETResponse | undefined,
  sourcePartUuids: string[],
  sourcePartRotations: RotationMap,
  onRotatePart?: (partUuid: string, rotation: number) => void
) {
  if (currentlyViewingPart) {
    // Only show selected part if no rotation function provided. It means the part hasn't been selected.
    if (!onRotatePart) return [currentlyViewingPart.uuid];

    let source: string | undefined = undefined;

    // Source is the first one in the list, but in case of ordering not being accurate
    // It is also the one with a 0 rotation
    if (sourcePartUuids.length) {
      source = sourcePartUuids[0];
      if (sourcePartRotations[source] !== 0) {
        source = sourcePartUuids.find((uuid) => sourcePartRotations[uuid] === 0)!;
      }
    }

    if (!source) return [currentlyViewingPart.uuid];
    // Show one source (to rotate against) and the current part.
    if (type === SimilarityStep.SOURCE) {
      if (
        // Multiple source parts selected
        sourcePartUuids.length > 1 &&
        // Current part is selected
        sourcePartUuids.includes(currentlyViewingPart.uuid) &&
        // Current isn't the main source (with 0 rotation)
        source !== currentlyViewingPart.uuid
      ) {
        return [source, currentlyViewingPart.uuid];
      } else {
        return [currentlyViewingPart.uuid];
      }
    }

    return [source, currentlyViewingPart.uuid];
  }
}

const SelectedPart3DViewport = ({
  isViewportFullScreen,
  sourcePartUuids,
  sourcePartRotations,
  targetPartRotations,
  onRotatePart,
  type,
}: {
  isViewportFullScreen: boolean;
  sourcePartUuids: string[];
  sourcePartRotations: RotationMap;
  targetPartRotations: RotationMap;
  type: SimilarityStep;
  onRotatePart?: (partUuid: string, rotation: number) => void;
}) => {
  const {height, setContainerRef} = useContainerHeight();
  const currentlyViewingPart = useSelector((state: RootState) => state.similarityReportStore.currentlyViewingPart);
  const parts = viewingParts(type, currentlyViewingPart, sourcePartUuids, sourcePartRotations, onRotatePart);

  return (
    <Container ref={setContainerRef} isViewportFullScreen={isViewportFullScreen}>
      <PartPreview3DViewport
        currentPartUuid={currentlyViewingPart?.uuid}
        partUuids={parts}
        height={height ? height - 6 : height}
        partRotations={{
          ...sourcePartRotations,
          ...targetPartRotations,
        }}
        onRotatePart={!!parts && parts.length > 1 ? onRotatePart : undefined}
      />
    </Container>
  );
};

export default SelectedPart3DViewport;

const Container = styled.div<{isViewportFullScreen: boolean}>`
  height: 100%;
  border-radius: ${({isViewportFullScreen}) => (isViewportFullScreen ? '0px' : '4px')};
  box-shadow: 0 0 14px 0 rgb(53 64 82 / 5%);
  overflow: hidden;
`;
