import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {BuildState, IBuild} from '@common/api/models/builds/IBuild';

import {DialogButton} from '../../../../components/molecules/DialogButton';
import {provisionBuildGET} from '../../../../api/ajax/builds';
import {usePermissionsForBuild} from '../../../../utils/utilHooks';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';
import {useBuildStateTransition} from '../../shared/useBuildStateTransition';
import {BUILD_SHORT_TRANSITION_TIMEOUT} from '../index';

interface ResumeBuildButtonProps {
  build: IBuild;
  fullWidthButton?: boolean;
}

let timeout: null | ReturnType<typeof setTimeout> = null;

export function ResumeBuildButton({build, fullWidthButton}: ResumeBuildButtonProps) {
  const [requesting, setRequesting] = useState(false);
  const {machinePermission} = usePermissionsForBuild(build);

  useEffect(() => {
    return () => clearTimeout(timeout!);
  }, []);

  useBuildStateTransition(() => {
    clearTimeout(timeout!);
    setRequesting(false);
  }, [build, [BuildState.PAUSED, BuildState.STAGING]]);

  const handleResumeBuildButton = async () => {
    setRequesting(true);
    const res = await provisionBuildGET(build.uuid);
    if (!res.success) {
      setRequesting(false);
      return;
    }

    timeout = setTimeout(() => {
      setRequesting(false);
      toast('Resume build timed out', {type: 'error'});
    }, BUILD_SHORT_TRANSITION_TIMEOUT);
  };

  const lastPrintedLayerId = (build.lastPrintedLayerId || 0) + 1;

  return (
    <ConditionalTooltip
      tooltip="Resuming the build requires permission to access this build's machine."
      hideTooltip={!!machinePermission}
    >
      <DialogButton
        text="Resume Build"
        dialog={{
          title: 'Resume Build',
          content: `Are you sure you want to resume this build?
          The build will transition into the paused state, and if monitoring is started again,
          will resume from layer ${lastPrintedLayerId}.`,
          confirmText: 'Resume',
        }}
        variant="contained"
        style={{
          fontWeight: 400,
        }}
        handleConfirm={handleResumeBuildButton}
        fullWidth={fullWidthButton}
        disabled={!machinePermission}
        loading={requesting}
      />
    </ConditionalTooltip>
  );
}
