import axios from 'axios';
import * as qs from 'qs';
import {IMaterialAttachment} from '@common/api/models/attachments/IAttachmentBase';

import {uploadParamType} from '../../pages/builds/shared/SliceAttachmentMultipartUpload';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';

export async function materialAttachmentsAllGET(
  filter: QueryFilter<IMaterialAttachment>
): AjaxApiResult<IMaterialAttachment[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/materialAttachments/' + (query && '?' + query));
  }, 'Could not fetch materialAttachments');
}

export async function materialAttachmentByUuidDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete('/api/materialAttachments/' + uuid);
  }, 'Could not delete slice attachment');
}

/** Download any file that has been uploaded (not just slice files) */
export async function materialAttachmentDownloadUrlGET(uuid: string): AjaxApiResult<string> {
  return ajaxWrapper(() => {
    return axios.get('/api/materialAttachments/downloadUrl/', {params: {uuid}});
  }, 'Could not download file');
}

// upload:

export async function materialAttachmentMultipartUploadInitiatePOST(
  materialUuid: string,
  filename: string,
  filesize: number // bytes
): AjaxApiResult<uploadParamType> {
  return ajaxWrapper(() => {
    return axios.post('/api/materialAttachments/multipart/initiate', {
      materialUuid,
      filename,
      filesize,
    });
  }, 'Could not upload file');
}

export async function materialAttachmentMultipartUploadCompletePOST(
  uploadId: string,
  materialUuid: string,
  key: string,
  parts: {ETag: string; PartNumber: number}[]
): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.post('/api/materialAttachments/multipart/complete/', {
      uploadId,
      materialUuid,
      key,
      parts,
    });
  });
}

export async function materialAttachmentMultipartUploadAbortPOST(
  uploadId: string,
  materialUuid: string,
  key: string
): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.post('/api/materialAttachments/multipart/abort/', {
      uploadId,
      materialUuid,
      key,
    });
  }, 'Could not abort upload file');
}
